import { put, all, takeLeading } from 'redux-saga/effects';
import { FETCH_PERSON_DATA_START, FETCH_PERSON_DATA_SUCCESS, FETCH_PERSON_DATA_FAIL } from '../Actions';

import { fetchPersonChartsDataV2 } from '../Api/HttpCalls/PersonChartsData';

const processHourlyData = (d) =>
  d.map((e) => ({
    val: e.covered ? 1 : 0,
    // ts: e.win_start,
    ts: new Date(e.win_start).toString(),
  }));

export function* fetchDataV2(action) {
  const { sid } = action;
  try {
    const res = yield fetchPersonChartsDataV2(sid);
    if (res[0]) {
      const d = res[0];
      yield put({
        type: FETCH_PERSON_DATA_SUCCESS,
        sid,
        boxByHour: processHourlyData(d.boxByHour),
        lineByDay: d.lineByDay,
        bbAvail: d.bbAvail,
        mTempAvail: d.mTempAvail,
      });
    } else {
      throw res[1];
    }
  } catch (error) {
    yield put({
      type: FETCH_PERSON_DATA_FAIL,
      error,
    });
  }
}

export function* fetchDataSaga() {
  yield takeLeading(FETCH_PERSON_DATA_START, fetchDataV2);
}

export function* personDataSagas() {
  yield all([fetchDataSaga()]);
}
