import { firestore } from '../../firebase';

async function updateDashboardId(uid, obj) {
  try {
    const ref = firestore.collection('dashboard_id').doc(uid);
    await ref.set(obj, { merge: true });
  } catch (e) {
    console.log(e);
  }
}

export { updateDashboardId };
