import { firestore } from '../../firebase';

async function fetchProjectCodesList() {
  try {
    const docs = await firestore
      .collection('project_codes')
      .get()
      .then((querySnapshot) => {
        const res = [];
        querySnapshot.forEach((doc) => {
          res.push(doc.data());
        });
        return res;
      });

    return [docs, null];
  } catch (error) {
    return [false, error];
  }
}

export default fetchProjectCodesList;
