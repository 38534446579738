import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import PrivateRoute from './Routes/PrivateRoute';
import Login from './Routes/Login';
import ResetPassword from './Routes/ResetPassword';
// import Signup from './Routes/Signup';
import Main from './Routes/Main';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          {/* <Route exact path="/SignUp" component={Signup} /> */}
          <PrivateRoute path="/" component={Main} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
