import { firestore } from '../../firebase';

async function createGroupInfo(groupVal, groupInfoObj) {
  try {
    const groupDoc = firestore.collection('study_group_info').doc(groupVal);
    const groupDocExists = await groupDoc.get().then((doc) => doc.exists);

    if (groupDocExists) {
      return [false, 'Group already exists'];
    }
    await groupDoc.set(groupInfoObj);
    return [true, 'Group created'];
  } catch (e) {
    return [false, 'Error creating group'];
  }
}

async function updateGroupInfo(groupVal) {
  console.log(groupVal);
  console.log('Place holder');
}

async function deleteGroupInfo(groupVal) {
  console.log(groupVal);
  console.log('Place holder');
}

export { createGroupInfo, updateGroupInfo, deleteGroupInfo };
