import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import useFirebaseAuthInfo from '../../Api/Hooks/useFirebaseAuthInfo';

function AccountSecurityCard() {
  const {
    email,
    emailVerified,
    enrolledMFANumber,
    disableCurrentMFA,
    requestSMSCode,
    vCodeSent,
    verifyCode,
    onCancel,
    sendVerifyEmailToUser,
  } = useFirebaseAuthInfo();

  const [phoneVal, setPhoneVal] = useState('');
  const [codeVal, setCodeVal] = useState('');

  useEffect(() => {
    if (enrolledMFANumber) {
      setPhoneVal(enrolledMFANumber);
    } else {
      setPhoneVal('');
    }
  }, [enrolledMFANumber]);

  // console.log(enrolledMFANumber);

  const cancelMFASetup = () => {
    onCancel();
    setPhoneVal('');
    setCodeVal('');
  };

  return (
    <div className="max-w-md px-3 py-2 pb-4 mb-2 bg-white rounded-lg">
      <h6 className="font-bold">Security</h6>
      <div className="form-control">
        <label className="p-1" htmlFor="form-control">
          <span className="text-sm font-medium text-gray-400">Account email</span>
        </label>
        <div className="flex flex-row w-full">
          <div type="text" className="bg-white input input-sm basis-2/3">
            {email}
          </div>
          <div className="flex items-center justify-center basis-1/3 group">
            <div className="flex items-center gap-1 group-hover:hidden">
              <BadgeCheckIcon className={classNames('w-5 h-5 text-gray-300', { 'text-green-500': emailVerified })} />
              <p className="font-semibold text-gray-400">{emailVerified ? 'Verified' : 'Unverified'}</p>
            </div>
            <button
              type="button"
              disabled={emailVerified}
              className={classNames('hidden text-xs btn btn-sm btn-primary group-hover:flex')}
              onClick={sendVerifyEmailToUser}
            >
              {emailVerified ? 'verified' : 'Verify Email'}
            </button>
          </div>
        </div>
      </div>
      <div className="pt-2 form-control">
        <label className="p-1" htmlFor="form-control">
          <span className="text-sm font-medium text-gray-400">Mutifactor authentication</span>
        </label>
        <div className="flex flex-row w-full">
          <input
            type="text"
            value={phoneVal}
            onChange={(e) => {
              setPhoneVal(e.target.value);
            }}
            onFocus={() => {
              if (phoneVal.length === 0) setPhoneVal('+1');
            }}
            className="input input-sm basis-2/3 disabled:bg-white input-bordered"
            placeholder="Enter phone number"
            disabled={enrolledMFANumber}
          />

          <div className="flex items-center justify-center basis-1/3 group">
            {enrolledMFANumber ? (
              <>
                <div className="flex items-center gap-1 group-hover:hidden">
                  <BadgeCheckIcon className="w-5 h-5 text-green-500" />
                  <p className="font-semibold text-gray-400">Enabled</p>
                </div>
                <button
                  type="button"
                  className="hidden text-xs btn btn-sm btn-warning group-hover:flex"
                  onClick={disableCurrentMFA}
                >
                  Disable
                </button>
              </>
            ) : (
              <button
                type="button"
                className="text-xs btn btn-sm btn-primary"
                disabled={phoneVal.length < 10}
                onClick={() => requestSMSCode(phoneVal)}
              >
                Add Phone
              </button>
            )}
          </div>
        </div>
        {enrolledMFANumber === null ? (
          <label className="p-1" htmlFor="form-control">
            <span className="text-sm text-gray-400 label-text">i.e +14150000000</span>
          </label>
        ) : null}
        <div id="recaptcha-container-2" className="flex justify-center mt-2 rounded-lg" />
        {vCodeSent ? (
          <div className="flex flex-col w-full pt-2">
            <input
              type="text"
              value={codeVal}
              onChange={(e) => {
                setCodeVal(e.target.value);
              }}
              placeholder="Enter verification code we just sent you"
              className="input input-bordered input-sm disabled:bg-white"
            />
            <div className="flex flex-row gap-1 pt-2">
              <button type="button" className="text-xs btn btn-sm basis-1/3" onClick={cancelMFASetup}>
                Cancel
              </button>
              <button
                type="button"
                className="flex-grow text-xs btn btn-sm btn-primary"
                onClick={() => verifyCode(codeVal)}
              >
                Verify CODE
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AccountSecurityCard;
