import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import BasestationTable from './BasestationTable';
import EachBasestation from './EachBasestation';

function Basestation() {
  const { path } = useRouteMatch();

  return (
    <div className="w-full">
      <Switch>
        <Route exact path={path}>
          <BasestationTable />
        </Route>
        <Route path={`${path}/:basestation_imei`}>
          <EachBasestation />
        </Route>
      </Switch>
    </div>
  );
}

export default Basestation;
