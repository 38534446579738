import { firestore, auth } from '../../firebase';

async function addNoteToStudyId(sid, note) {
  try {
    const user = auth.currentUser;
    const { displayName, email, uid } = user;
    await firestore
      .collection('dashboard_notes')
      .doc()
      .set({
        sid,
        note,
        created_at: new Date(),
        created_by: displayName ?? email ?? uid ?? 'Unkown-User',
        edited_at: null,
        edited_by: null,
        create_by_user: {
          uid,
          email,
          displayName,
        },
      });
    return [true, null];
  } catch (e) {
    return [false, e];
  }
}

async function delNoteToStudyId() {}

async function editNoteToStudyId() {}

async function batchAddNotes(notes) {
  try {
    const user = auth.currentUser;
    const batch = firestore.batch();

    notes.forEach((e) => {
      const { sid, note } = e;
      const { displayName, email, uid } = user;
      const doc = firestore.collection('dashboard_notes').doc();
      batch.set(doc, {
        sid,
        note,
        created_at: new Date(),
        created_by: displayName ?? email ?? uid ?? 'Unkown-User',
        edited_at: null,
        edited_by: null,
        create_by_user: {
          uid,
          email,
          displayName,
        },
      });
    });

    await batch.commit();

    return [true, null];
  } catch (e) {
    return [false, e];
  }
}

export { addNoteToStudyId, delNoteToStudyId, editNoteToStudyId, batchAddNotes };
