import produce from 'immer';
import {
  FETCH_TABLE_DATA_REQUEST,
  FETCH_TABLE_DATA_SUCCESS,
  FETCH_TABLE_DATA_FAILURE,
  OFFBOARD_SID_START,
  OFFBOARD_SID_ERROR,
  OFFBOARD_SID_SUCCESS,
  LOG_OUT,
} from '../Actions';

export const initialState = {
  home_table_data: [],
  fetching: false,
  error: null,
  offboard_status: {
    sid: null,
    loading: false,
    error: null,
  },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TABLE_DATA_REQUEST:
        draft.fetching = true;
        draft.error = null;
        draft.offboard_status = initialState.offboard_status;
        break;
      case FETCH_TABLE_DATA_SUCCESS:
        draft.fetching = false;
        draft.home_table_data = Object.values(action.data).map((e) => {
          e.activation_date = new Date(e.activation_date);
          if (e.bb_ts_latest != null) {
            e.bb_ts_latest = new Date(e.bb_ts_latest);
          }
          if (e.mtemp_ts_latest != null) {
            e.mtemp_ts_latest = new Date(e.mtemp_ts_latest);
          }
          return e;
        });
        break;
      case FETCH_TABLE_DATA_FAILURE:
        draft.fetching = false;
        draft.patients = [];
        draft.error = action.error;
        break;
      case OFFBOARD_SID_START:
        draft.offboard_status.sid = action.sid;
        draft.offboard_status.loading = true;
        break;
      case OFFBOARD_SID_SUCCESS:
        draft.offboard_status.loading = false;
        const index = draft.home_table_data.findIndex(
          (each) => each.sid === action.sid
        );
        draft.home_table_data.splice(index, 1);
        break;
      case OFFBOARD_SID_ERROR:
        draft.offboard_status.loading = false;
        draft.offboard_status.error = action.error;
        break;
      case LOG_OUT:
        return initialState;
      default:
        break;
    }
  });
