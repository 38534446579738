import { firestore, auth } from '../../firebase';

async function updateProjectCode(projectCodeObj) {
  const newObj = {
    ...projectCodeObj,
    modifiedDate: new Date(),
    modifiedBy: auth.currentUser.uid,
  };

  const ref = firestore.collection('project_codes').doc(projectCodeObj.projectCode);
  await ref.set(newObj, { merge: true });
}

async function createProjectCode(projectCodeObj) {
  const newObj = {
    ...projectCodeObj,
    createdDate: new Date(),
    createdBy: auth.currentUser.uid,
  };

  const ref = firestore.collection('project_codes').doc(projectCodeObj.projectCode);
  await ref.set(newObj);
}

export { updateProjectCode, createProjectCode };
