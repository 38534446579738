import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class AppSubmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: null };
  }

  onMenuItemClick(event, item, index) {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item,
      });
    }
  }

  renderLinkContent(item) {
    const submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />;
    const badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    return (
      <>
        <i className={item.icon} />
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </>
    );
  }

  renderLink(item, i) {
    const content = this.renderLinkContent(item);

    if (item.hidden) {
      return null;
    }

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-route"
          to={item.to}
          onClick={(e) => this.onMenuItemClick(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      );
    }

    return (
      <a href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}>
        {content}
      </a>
    );
  }

  render() {
    const items =
      this.props.items &&
      this.props.items.map((item, i) => {
        const active = this.state.activeIndex === i;
        const styleClass = classNames(item.badgeStyleClass, {
          'active-menuitem': active && !item.to,
        });

        return (
          <li className={styleClass} key={i}>
            {item.items && this.props.root === true && <div className="arrow" />}
            {this.renderLink(item, i)}
            <AppSubmenu items={item.items} onMenuItemClick={this.props.onMenuItemClick} />
          </li>
        );
      });

    return items ? <ul className={this.props.className}>{items}</ul> : null;
  }
}

AppSubmenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  root: PropTypes.bool,
};

AppSubmenu.defaultProps = {
  className: null,
  items: null,
  onMenuItemClick: null,
  root: false,
};

export default AppSubmenu;
