import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useEachBasestation from '../../Api/Hooks/useEachBasestation';
import { setBasestationProjectCode, setBasestationStatus, removeBasestationLog } from '../../Api/Set/BasestationSets';
import TableCellDropdown from '../UI/TableCellDropdown';
import StatusTag from '../UI/StatusTag';
import RowItem from '../UI/RowItem';
import BaseTable from '../UI/BaseTable';

function EachBasestation() {
  const { basestation_imei } = useParams();
  const { basestationDoc, basestationLogs, requesting } = useEachBasestation(basestation_imei);
  const isAdmin = useSelector((state) => state.auth.roles.admin);
  const idProjectAccess = useSelector((state) => state.auth.projectAccess);

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        id: 'type',
        accessor: 'type',
      },
      {
        Header: 'Date',
        id: 'ts',
        accessor: (d) => {
          if (d.ts) {
            return moment(d.ts.toDate()).format('ll');
          }
          return '';
        },
      },
      { Header: 'Desc', id: 'desc', accessor: 'desc' },
      {
        Header: '',
        id: 'delete',
        accessor: null,
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <button
            className="btn btn-xs btn-square btn-ghost"
            type="button"
            onClick={() => {
              removeBasestationLog(basestation_imei, original);
            }}
          >
            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ),
      },
    ],
    [basestation_imei]
  );

  // convert data size bytes to KB, MB, GB
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <div>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/basestation">Basestation</Link>
          </li>
          <li>{basestationDoc.basestation_id}</li>
        </ul>
      </div>
      <div className="z-10 grid w-full grid-cols-1 gap-2 lg:grid-cols-3 md:grid-cols-2">
        <div className="flex flex-col w-full max-w-xl col-span-1 gap-1 px-2 py-2 bg-white rounded">
          <RowItem title="Basestation ID" value={basestationDoc.basestation_id} odd />
          <RowItem title="Owner" value={basestationDoc.owner_sid} />
          <RowItem title="Esper ID" value={basestationDoc.esper_id} odd />
          <RowItem title="IMEI" value={basestationDoc.imei} />
          <RowItem title="Serial Number" value={basestationDoc.serial_number} odd />
          <RowItem
            title="Project code"
            rightElement={() => (
              <TableCellDropdown
                editable={isAdmin}
                value={basestationDoc.projectCode}
                options={idProjectAccess}
                onChange={(e) => setBasestationProjectCode(basestationDoc.imei, e, basestationDoc.projectCode)}
              />
            )}
          />
          <RowItem
            title="Status"
            odd
            rightElement={() => (
              <TableCellDropdown
                value={basestationDoc.basestation_status}
                options={['ready', 'inactive', 'broken']}
                editable={isAdmin}
                customItem={StatusTag}
                onChange={(e) => setBasestationStatus(basestationDoc.imei, e, basestationDoc.basestation_status)}
              />
            )}
          />
        </div>
        <div className="flex flex-col w-full max-w-xl col-span-1 gap-1 px-2 py-2 bg-white rounded">
          <RowItem title="Hologram ID" value={basestationDoc?.hologram?.device_id} odd />
          <RowItem title="Link ID" value={basestationDoc?.hologram?.link_id} />
          <RowItem
            title="Last connect"
            value={
              basestationDoc?.hologram?.last_connect_time
                ? moment(basestationDoc?.hologram?.last_connect_time).format('ll')
                : null
            }
            odd
          />
          <RowItem title="Data Plan" value={basestationDoc?.hologram?.plan} />
          <RowItem title="Data included" value={formatBytes(basestationDoc?.hologram?.plan_data ?? 0)} odd />
          <RowItem
            title="Data used (current)"
            value={formatBytes(basestationDoc?.hologram?.cur_billing_data_used ?? 0)}
          />
          <RowItem
            title="Data used (last)"
            value={formatBytes(basestationDoc?.hologram?.last_billing_data_used ?? 0)}
            odd
          />
        </div>
        <div className="z-0 sm:col-span-1 md:col-span-2 lg:col-span-3">
          <BaseTable columns={columns} data={basestationLogs} loading={requesting} />
        </div>
      </div>
    </div>
  );
}

export default EachBasestation;
