export const FETCH_TABLE_DATA_REQUEST = 'FETCH_TABLE_DATA_REQUEST';
export const FETCH_TABLE_DATA_SUCCESS = 'FETCH_TABLE_DATA_SUCCESS';
export const FETCH_TABLE_DATA_FAILURE = 'FETCH_TABLE_DATA_FAILURE';
export const OFFBOARD_SID_START = 'OFFBOARD_SID_START';
export const OFFBOARD_SID_ERROR = 'OFFBOARD_SID_ERROR';
export const OFFBOARD_SID_SUCCESS = 'OFFBOARD_SID_SUCCESS';

export function fetchTableData() {
  return { type: FETCH_TABLE_DATA_REQUEST };
}

export function fetchTableDataSuccess(data) {
  return { type: FETCH_TABLE_DATA_SUCCESS, data };
}

export function fetchTableDataFailure(error) {
  return { type: FETCH_TABLE_DATA_FAILURE, error };
}

export function offboardSidStart(sid) {
  return { type: OFFBOARD_SID_START, sid };
}

export function offboardSidError(error) {
  return { type: OFFBOARD_SID_ERROR, error };
}

export function offboardSidSuccess(sid) {
  return { type: OFFBOARD_SID_SUCCESS, sid };
}
