import produce from 'immer';
import {
  FETCH_PERSON_DATA_START,
  FETCH_PERSON_DATA_SUCCESS,
  FETCH_PERSON_DATA_FAIL,
  CLEAR_PERSON_DATA,
  UPDATE_SID_DOC_DATA,
} from '../Actions';

export const FETCH_STATUS = {
  IDLE: 'IDLE',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const initialState = {
  status: FETCH_STATUS.IDLE,
  fetching: false,
  sid: '',
  error: '',
  fetchingSidDoc: false,
  fetchingSidDocError: '',
  sidDoc: {
    platform: '-',
    timezone: '-',
    appVersion: '-',
    lastActive: '-',
    extended_study_days: null,
    basestation: '-',
  },
  boxByHour: [],
  lineByDay: [],
  mTempAvail: {
    temp_sample_count: 0,
    temp_sample_expected: 1,
  },
  bbAvail: {
    bb_sample_count: 0,
    bb_sample_expected: 1,
  },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_PERSON_DATA_START:
        draft.status = FETCH_STATUS.STARTED;
        draft.sid = action.sid;
        draft.fetching = true;
        break;
      case FETCH_PERSON_DATA_SUCCESS:
        draft.fetching = false;
        draft.status = FETCH_STATUS.SUCCESS;
        // console.log(action);
        draft.boxByHour = action.boxByHour;
        draft.lineByDay = action.lineByDay;
        if (action.bbAvail) {
          draft.bbAvail = action.bbAvail;
        }
        if (action.mTempAvail) {
          draft.mTempAvail = {
            sid: action.mTempAvail.sid,
            temp_sample_count: Number(action.mTempAvail.temp_sample_count),
            temp_sample_expected: Number(action.mTempAvail.temp_sample_expected),
          };
        }
        break;
      case FETCH_PERSON_DATA_FAIL:
        draft.status = FETCH_STATUS.FAIL;
        draft.error = action.error;
        draft.fetching = false;
        break;
      case UPDATE_SID_DOC_DATA:
        draft.sidDoc.platform = action.data.usrPlatform;
        draft.sidDoc.timezone = action.data.usrTimezone;
        draft.sidDoc.appVersion = action.data.usrAppVersion;
        draft.sidDoc.lastActive = action.data.usrLastActive;
        draft.sidDoc.extended_study_days = action.data.extended_study_days;
        draft.sidDoc.basestation = action.data.basestation_id;
        break;
      default:
        break;
      case CLEAR_PERSON_DATA:
        return initialState;
    }
  });
