import { useEffect, useState, useCallback } from 'react';
import {
  RecaptchaVerifier,
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  sendEmailVerification,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import app from '../../firebase';

const errorCodeToMessage = (code, msg) => {
  console.log(code);
  switch (code) {
    case 'auth/requires-recent-login':
      return 'Recent authentication is required to perform security sensitive operations. Please sign in again.';
    default:
      if (msg) return msg;
      return 'Unknown error.';
  }
};

function validatePhoneForE164(phoneNumber) {
  const regEx = /^\+[1-9]\d{10,14}$/;
  return regEx.test(phoneNumber);
}

const useFirebaseAuthInfo = (onEnrollmentSuccess) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [enrolledMFANumber, setEnrolledMFANumber] = useState(null);
  const [vCodeSent, setVCodeSent] = useState(false);

  const [verificationId, setVerificationId] = useState(null);

  const auth = getAuth(app);

  const refresh = useCallback(async () => {
    setLoading(true);
    const user = auth.currentUser;
    setEmail(user.email);
    setEmailVerified(user.emailVerified);
    const mfaUser = multiFactor(user);
    const enrolledMfa = mfaUser.enrolledFactors;
    if (enrolledMfa.length > 0) setEnrolledMFANumber(enrolledMfa[0].phoneNumber);

    setLoading(false);
  }, [auth.currentUser]);

  const sendVerifyEmailToUser = () => {
    const user = auth.currentUser;
    sendEmailVerification(user)
      .then(() => {
        toast.success('Verification email sent.');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error sending verification email.');
      });
  };

  const disableCurrentMFA = () => {
    const user = auth.currentUser;
    const mfaUser = multiFactor(user);
    if (mfaUser.enrolledFactors[0]) {
      mfaUser
        .unenroll(mfaUser.enrolledFactors[0])
        .then(() => {
          setEnrolledMFANumber(null);
          toast.success('MFA disabled');
          refresh();
        })
        .catch((error) => {
          toast.error(errorCodeToMessage(error.code));
        });
    }
  };

  const requestSMSCode = (phone) => {
    if (!validatePhoneForE164(phone)) {
      toast.error('Phone number incorrectly formatted.');
    } else {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container-2',
        {
          size: 'normal',
          callback: () => {
            const user = auth.currentUser;
            const mfaUser = multiFactor(user);
            mfaUser.getSession().then((mfsession) => {
              const phoneInfoOptions = {
                phoneNumber: phone,
                session: mfsession,
              };
              const phoneAuthProvider = new PhoneAuthProvider(auth);
              phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
                .then((newVerificationId) => {
                  toast.success('SMS verification code sent to phone.');
                  setVCodeSent(true);
                  setVerificationId(newVerificationId);
                  window.recaptchaVerifier.clear();
                })
                .catch((e) => {
                  toast.error(errorCodeToMessage(e.code, 'Error sending SMS code, to phone number.'));
                  window.recaptchaVerifier.clear();
                });
            });
          },
          'expired-callback': () => {
            console.log('recaptchaVerifier expired-callback');
            window.recaptchaVerifier.clear();
          },
          'error-callback': () => {
            console.log('recaptchaVerifier error-callback');
            window.recaptchaVerifier.clear();
          },
        },
        auth
      );
      window.recaptchaVerifier.render();
    }
  };

  const verifyCode = async (code) => {
    try {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const user = auth.currentUser;
      const mfaUser = multiFactor(user);
      await mfaUser.enroll(multiFactorAssertion);
      refresh();
      setVCodeSent(false);
      if (onEnrollmentSuccess) onEnrollmentSuccess();
      toast.success('MFA enabled');
    } catch (e) {
      setVCodeSent(false);
      toast.error('Unable to verify code. Please try again.');
    }
  };

  const onCancel = () => {
    setVCodeSent(false);
    refresh();
  };

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    loading,
    email,
    emailVerified,
    enrolledMFANumber,
    disableCurrentMFA,
    requestSMSCode,
    vCodeSent,
    verifyCode,
    onCancel,
    sendVerifyEmailToUser,
  };
};

export default useFirebaseAuthInfo;
