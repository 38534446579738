import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import PrimeReact from 'primereact/api';
import { useDispatch, useSelector } from 'react-redux';

import Topbar from '../Components/home/Topbar';
import Footer from '../Components/home/Footer';
import Menu from '../Components/home/Menu';

import Dashboard from '../Components/dashboard/Dashboard';

import WatchId from '../Components/watchids/WatchId';
import Basestation from '../Components/basestation/Basestation';
import StudyIds from '../Components/studyIds/StudyIds';
import Followup from '../Components/followup/Followup';
import AccountSettings from '../Components/accountsettings/AccountSettings';
import CompletedStudyId from '../Components/completedstudyid/CompletedStudyId';
import GroupManagement from '../Components/groupmanagement/GroupManagement';
import DevScreen from '../Components/devscreen/DevScreen';

import { fetchTableData, logout } from '../Actions';

import AccountSettingsSubscription from '../Api/Hooks/AccountSettingsSubscription';
import GroupInfoSubscription from '../Api/Hooks/GroupInfoSubscription';

const App = () => {
  const dispatch = useDispatch();
  // const dashboardID = useSelector((state) => state.auth.id);
  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('dark');
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const isOwner = useSelector((state) => state.auth.roles.owner);

  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ` ${className}`;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
        ' '
      );
  };

  useEffect(() => {
    dispatch(fetchTableData());
  }, [dispatch]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    } else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const isDesktop = () => window.innerWidth >= 992;

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  // Items can be nested...
  const menu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-home',
      command: () => {
        window.location = '#/';
      },
      to: '/dashboard',
    },
    {
      label: 'Study Participants',
      icon: 'pi pi-fw pi-user-edit',
      to: '/studyids',
    },
    {
      label: 'Completed Participants',
      icon: 'pi pi-fw pi-user-minus',
      to: '/completedstudyid',
    },
    {
      label: 'Watches',
      icon: 'pi pi-fw pi-mobile',
      to: '/watches',
    },
    {
      label: 'Basestation',
      icon: 'pi pi-fw pi-mobile',
      to: '/basestation',
    },
    {
      label: 'Scheduled Follow Up',
      icon: 'pi pi-fw pi-calendar',
      to: '/followup',
    },
    {
      label: 'Group Management',
      icon: 'pi pi-fw pi-users',
      to: '/groupmanagement',
    },
    {
      label: 'Account Settings',
      icon: 'pi pi-fw pi-cog',
      to: '/accountsettings',
    },
    {
      label: 'Dev Screen',
      icon: 'pi pi-fw pi-cog',
      to: '/devscreen',
      hidden: !isOwner,
    },
    {
      label: 'Log Out',
      icon: 'pi pi-fw pi-sign-out',
      command: () => {
        dispatch(logout());
      },
    },
  ];

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <GroupInfoSubscription />
      <AccountSettingsSubscription />
      <Topbar
        onToggleMenu={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar layout-sidebar-dark" onClick={onSidebarClick}>
        <div className="logo-container">
          <img alt="Logo" src="assets/layout/images/WhiteLogo.svg" />
          <p style={{ color: '#fff', paddingTop: '16px' }}>v{global.appVersion}</p>
        </div>
        <Menu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>

      <div className="layout-main">
        <Switch>
          <Redirect exact from="/" to="dashboard" />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/studyids" component={StudyIds} />
          <Route path="/watches" component={WatchId} />
          <Route path="/basestation" component={Basestation} />
          <Route path="/followup" component={Followup} />
          <Route path="/completedstudyid" component={CompletedStudyId} />
          <Route path="/groupmanagement" component={GroupManagement} />
          <Route path="/accountsettings" component={AccountSettings} />
          <Route path="/devscreen" component={DevScreen} />
        </Switch>
      </div>

      <Footer layoutColorMode={layoutColorMode} />

      <div className="layout-mask" />
    </div>
  );
};

export default App;
