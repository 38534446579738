import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

function BarChart() {
  const barChartData = useSelector((state) => state.personData.lineByDay);
  const loading = useSelector((state) => state.personData.fetching);
  const [xData, setXData] = useState(new Array(30).fill('-'));
  const [yData, setYData] = useState(new Array(30).fill(0));

  useEffect(() => {
    if (barChartData.length > 1) {
      // moment(val).format('MMM DD')
      setXData(barChartData.map((e) => moment(new Date(e.win_day)).format('MMM DD')));
      setYData(barChartData.map((e) => e.sum_win_covered));
    }
  }, [barChartData]);

  useEffect(() => {
    if (loading) {
      setXData(new Array(30).fill('-'));
      setYData(new Array(30).fill(0));
    }
  }, [loading]);

  const opt = {
    chart: {
      animations: {
        enabled: false,
      },
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.8,
        opacityTo: 0.2,
      },
    },
    markers: {
      size: 1,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: xData,
      position: 'bottom',
      tickAmount: 8,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: 0,
        show: true,
        hideOverlappingLabels: true,
        // formatter: (val) => moment(val).format('MMM DD'),
      },
    },
    yaxis: {
      max: 25,
      min: 0,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const data = [
    {
      name: 'wear time',
      data: yData,
    },
  ];

  return <Chart type="area" options={opt} series={data} height={300} />;
}

export default BarChart;
