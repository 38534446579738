import React, { useState } from 'react';
import classNames from 'classnames';
import { Menu } from '@headlessui/react';
import { useSelector } from 'react-redux';
import GroupLabel from './GroupLabel';
import useGroupLabelStyle from '../hooks/useGroupLabelStyle';

function TextColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      className="max-w-xs rounded-md input input-xs"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder="find"
    />
  );
}

function TextColumnFilterSM({ column: { filterValue, setFilter } }) {
  return (
    <input
      className="w-24 min-w-full rounded-md input input-sm"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder="find"
    />
  );
}

function SelectColumnFilter({ column: { filterValue, render, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const set = new Set();
    preFilteredRows.forEach((row) => {
      set.add(row.values[id]);
    });
    return [...Array.from(set.values())];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      className="w-full rounded-md select select-xs"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function SortColumnIndicator({ column: { isSorted, isSortedDesc, disableSortBy } }) {
  if (disableSortBy) {
    return null;
  }

  if (!isSorted) {
    return (
      <span className="ml-1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
          <path
            fillRule="evenodd"
            d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  }
  return (
    <span className="self-center ml-1">
      {isSortedDesc ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
          <path
            fillRule="evenodd"
            d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
          <path
            fillRule="evenodd"
            d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </span>
  );
}

function LoadingRows({ numRows, numCells }) {
  const rows = new Array(numRows).fill(0);
  const cells = new Array(numCells).fill(0);
  return (
    <>
      {rows.map((row, i) => (
        <tr key={i} className="animate-pulse">
          {cells.map((cell, j) => (
            <td key={j}>
              <div className="h-6 bg-gray-100 rounded-md" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}

function NoRows({ numCells }) {
  return (
    <tr>
      <td colSpan={numCells} className="py-8 text-center text-gray-400">
        No records found
      </td>
    </tr>
  );
}

function EditableGroupLableCell({ currentValue, onChange }) {
  const groupValueList = useSelector((state) => state.groupInfo.groupValueList);
  const { textColor, bgColor, labelText } = useGroupLabelStyle(currentValue);

  const options = groupValueList;

  return (
    <div className="max-w-fit">
      <Menu as="div" className="">
        <>
          <div className="flex">
            <Menu.Button
              className="flex-grow rounded-full select select-sm"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              <p className="self-center px-2 text-xs">{labelText}</p>
            </Menu.Button>
          </div>
          <Menu.Items className="absolute z-50 p-2 mt-0.5 rounded-md shadow menu bg-base-100">
            <div className="flex flex-col gap-1">
              {options.map((option, i) => (
                <Menu.Item key={option} static>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames('flex flex-start rounded-md py-1 px-4', {
                        'bg-primary-500': active,
                        'bg-gray-200': option === currentValue && !active,
                      })}
                      onClick={() => {
                        onChange(option);
                      }}
                    >
                      <GroupLabel groupValue={option} />
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      </Menu>
    </div>
  );
}

function EditableDropDownCell({ currentValue, onChange, options = [] }) {
  const activeOption = options.find((option) => option.value === currentValue);

  return (
    <div className="max-w-fit">
      <Menu as="div" className="">
        <>
          <Menu.Button className="flex-grow text-gray-800 rounded-md select select-sm select-bordered ">
            <p className="self-center px-2 text-xs">{activeOption.label}</p>
          </Menu.Button>

          <Menu.Items className="absolute z-50 p-2 mt-0.5 rounded-md shadow menu bg-base-100">
            <div className="flex flex-col gap-1">
              {options.map((option, i) => (
                <Menu.Item key={option.label} static>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames('flex flex-start rounded-md py-1 px-4 btn btn-sm btn-ghost', {
                        'bg-primary-500': active,
                        'bg-gray-200': option === currentValue && !active,
                      })}
                      onClick={() => {
                        onChange(option);
                      }}
                    >
                      <p>{option.label}</p>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      </Menu>
    </div>
  );
}

function DropDownGropSelect({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const set = new Set();
    preFilteredRows.forEach((row) => {
      set.add(row.values[id]);
    });
    return [...Array.from(set.values())];
  }, [id, preFilteredRows]);

  const { textColor, bgColor, labelText } = useGroupLabelStyle(filterValue);

  return (
    <div className="w-24 min-w-full">
      <Menu as="div" className="">
        <>
          <div className="flex">
            <Menu.Button
              className="flex-grow rounded-md select select-sm"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              <p className="self-center text-xs">{filterValue ? labelText : 'All'}</p>
            </Menu.Button>
            <div className="">
              <button
                type="button"
                disabled={filterValue === undefined}
                className="ml-2 btn btn-sm btn-square btn-ghost disabled:opacity-0"
                onClick={() => {
                  setFilter(undefined);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Menu.Items className="absolute z-50 p-2 mt-0.5 rounded-md shadow menu bg-base-100">
            <div className="flex flex-col gap-1">
              {options.map((option, i) => (
                <Menu.Item key={option} static>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames('flex flex-start rounded-md py-1 px-4', {
                        'bg-primary-500': active,
                        'bg-gray-200': option === filterValue && !active,
                      })}
                      onClick={() => {
                        setFilter(option);
                      }}
                    >
                      <GroupLabel groupValue={option} />
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      </Menu>
    </div>
  );
}

function DropdownAvailSelect({ column: { filterValue = [], setFilter, id } }) {
  const options = [
    { label: 'LOW', value: [0, 24], color: '#ff5252' },
    { label: 'MEDIUM', value: [25, 75], color: '#ffb142' },
    { label: 'HIGH', value: [76, 100], color: '#78e08f' },
  ];

  const activeOption = options.find((option) => option.value[0] === filterValue[0]);

  return (
    <div className="w-24 min-w-full">
      <Menu as="div" className="">
        <>
          <div className="flex">
            <Menu.Button
              className="flex-grow rounded-md select select-sm"
              style={{
                backgroundColor: activeOption ? activeOption.color : '#fff',
                color: activeOption ? '#fff' : '#333333',
              }}
            >
              <p className="self-center text-xs">{activeOption?.label ?? ''}</p>
            </Menu.Button>
            <div className="">
              <button
                type="button"
                disabled={filterValue.length === 0}
                className="ml-2 btn btn-sm btn-square btn-ghost disabled:opacity-0"
                onClick={() => {
                  setFilter([]);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Menu.Items className="absolute z-50 p-2 mt-0.5 rounded-md shadow menu bg-base-100 w-32">
            <div className="flex flex-col gap-1">
              {options.map((option, i) => (
                <Menu.Item key={option.label} static>
                  {({ active }) => (
                    <button
                      type="button"
                      style={{ backgroundColor: option.color }}
                      className={classNames('flex flex-start px-4 rounded-md btn btn-sm border-0 text-white text-xs')}
                      onClick={() => {
                        setFilter(option.value);
                      }}
                    >
                      <p> {option.label} </p>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      </Menu>
    </div>
  );
}

export {
  TextColumnFilter,
  SelectColumnFilter,
  SortColumnIndicator,
  LoadingRows,
  NoRows,
  TextColumnFilterSM,
  DropDownGropSelect,
  DropdownAvailSelect,
  EditableGroupLableCell,
  EditableDropDownCell,
};
