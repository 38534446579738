import { arrayUnion, arrayRemove } from 'firebase/firestore';
import { firestore } from '../../firebase';

async function setWatchIdProjectCode(watchId, newProjectCode, previousProjectCode) {
  if (newProjectCode === previousProjectCode) return [true, null];
  const ref = firestore.collection('watch_id').doc(watchId);
  const newWatchLog = {
    ts: new Date(),
    type: 'watch-project-change',
    desc: `Project code changed from ${previousProjectCode} to ${newProjectCode}`,
  };
  try {
    await ref.update({
      projectCode: newProjectCode,
      watch_log: arrayUnion(newWatchLog),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

async function setWatchIdStatus(watchId, newStatus, previousStatus) {
  if (newStatus === previousStatus) return [true, null];
  const ref = firestore.collection('watch_id').doc(watchId);
  const newWatchLog = {
    ts: new Date(),
    type: 'watch-status-change',
    desc: `Status changed from ${previousStatus} to ${newStatus}`,
  };
  try {
    await ref.update({
      watch_status: newStatus,
      watch_log: arrayUnion(newWatchLog),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

async function removeWatchLog(watchId, log) {
  const ref = firestore.collection('watch_id').doc(watchId);
  try {
    await ref.update({
      watch_log: arrayRemove(log),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

async function convertLogToWatchLog(watchId) {
  const ref = firestore.collection('watch_id').doc(watchId);
  const currentLog = await ref.get().then((doc) => doc.data().log);
  const activateWatchLog = currentLog.map((log) =>
    log.activation_date
      ? {
          ts: log.activation_date.toDate(),
          type: 'watch-ownership-new',
          desc: `Watch activated by ${log.owner_sid}`,
        }
      : null
  );
  const deActivateWatchLog = currentLog.map((log) =>
    log.deactivation_date
      ? {
          ts: log.deactivation_date.toDate(),
          type: 'watch-ownership-remove',
          desc: `Watch deactivated by ${log.owner_sid}`,
        }
      : null
  );
  const newWatchLog = [...activateWatchLog, ...deActivateWatchLog].filter((log) => log).sort((a, b) => a.ts - b.ts);
  await ref.update({
    watch_log: arrayUnion(...newWatchLog),
    log: [],
    log_deprecated: arrayUnion(...currentLog),
  });
}

export { setWatchIdProjectCode, setWatchIdStatus, removeWatchLog, convertLogToWatchLog };
