import { put, takeLatest, all } from 'redux-saga/effects';
import {
  START_CREATE_IDS,
  createIdsSuccess,
  createIdsFailure,
} from '../Actions';
import { createStudyIds } from '../Api/FirebasePatients';

export function* createNewIds() {
  yield takeLatest(START_CREATE_IDS, _createNewIds);
}

export function* _createNewIds(action) {
  try {
    const response = yield createStudyIds(
      action.studyIdList,
      action.activationList
    );
    console.log(response);
    yield put(createIdsSuccess(action.idList, action.activationList));
  } catch (error) {
    yield put(createIdsFailure(error));
  }
}

export function* setupSaga() {
  yield all([createNewIds()]);
}
