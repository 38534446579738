export const UPDATE_WATCH_ID = 'UPDATE_WATCH_ID';
export const UPDATE_STUDY_ID = 'UPDATE_STUDY_ID';

export function updateWatchId(watch_id_data) {
  return { type: UPDATE_WATCH_ID, watch_id_data };
}

export function updateStudyId(study_id_data) {
  return { type: UPDATE_STUDY_ID, study_id_data };
}
