import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import EachCompletedSidSummary from './EachCompletedSidSummary';
import { fetchSelectedSidDataStart } from '../../Actions';
import EachCompletedSidHeatMap from './EachCompletedSidHeatMap';
import useEachCompletedStudyId from '../../Api/Hooks/useEachCompletedStudyId';
import DButton from '../UI/DButton';

function EachCompletedStudyId({ onBackButtonClicked }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [sidEntry, setSidEntry] = useState([]);
  const { loading, completedSidDoc } = useEachCompletedStudyId(id);

  useEffect(() => {
    if (id) {
      dispatch(fetchSelectedSidDataStart(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const d = completedSidDoc;
    if (d) {
      const processed = [
        { type: 'sid', display: 'Study Id', val: d.sid },
        { type: 'group', display: 'Group', val: d.group },
        {
          type: 'duration',
          display: 'Subject study duration',
          val: d.bb_study_duration_days,
        },
        {
          type: 'watch',
          display: 'Watch Avail. (Hours)',
          val: [d.bb_window_covered, d.bb_window_to_cover, d.bb_window_covered_per],
        },
        {
          type: 'mTemp',
          display: 'Temp Avail. (Times)',
          val: [d.mtemp_sample_count, d.bb_study_duration_days * 2, d.mtemp_covered_per],
        },
        {
          type: 'drop-out',
          display: 'Drop out',
          val: d.early_completion === undefined ? 'undefined' : d.early_completion,
        },
      ];
      setSidEntry(processed);
    }
  }, [completedSidDoc]);

  return (
    <div className="flex flex-col w-full">
      <div className="w-full mb-2">
        <DButton
          onClick={onBackButtonClicked}
          titile="Back to table"
          className="w-full btn-primary"
          leftIcon={<ChevronLeftIcon className="w-5 h-5 text-white" aria-hidden="true" />}
        />
      </div>
      <div className="w-full px-5 py-2 bg-white rounded-lg">
        <EachCompletedSidSummary loading={loading} entry={sidEntry} sid={id} />
      </div>
      <div className="px-3 py-2 mt-2 bg-white rounded-lg">
        <h6 className="pb-2 pl-3">Watch daily wear study duration</h6>
        <EachCompletedSidHeatMap />
      </div>
    </div>
  );
}

EachCompletedStudyId.propTypes = {
  onBackButtonClicked: PropTypes.func.isRequired,
};

export default EachCompletedStudyId;
