import produce from 'immer';
import {
  FETCH_GROUP_INFO_START,
  FETCH_GROUP_INFO_SUCCESS,
  FETCH_GROUP_INFO_FAIL,
} from '../Actions';

export const initialState = {
  groupLabelList: [],
  groupValueList: [],
  groupInfoObjects: [],
  fetching: false,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_GROUP_INFO_START:
        draft.fetching = true;
        break;
      case FETCH_GROUP_INFO_SUCCESS:
        draft.groupLabelList = action.obj.map((e) => e.label);
        draft.groupValueList = action.obj.map((e) => e.value);
        draft.groupInfoObjects = action.obj;
        draft.fetching = false;
        break;
      case FETCH_GROUP_INFO_FAIL:
        draft.fetching = false;
        break;
      default:
        break;
    }
  });
