import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

export async function fetchHomeTableData() {
  try {
    const { data } = await firebase
      .functions()
      .httpsCallable('getHomeTableData')({});
    if (data.error) {
      return { error: true, error_msg: data.error };
    }
    return { error: false, data: data.data };
  } catch (e) {
    return { error: true, error_msg: e };
  }
}
