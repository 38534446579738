import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

export default async function fetchSidDataCompleted(sid) {
  try {
    const { data } = await firebase
      .functions()
      .httpsCallable('fetchSidDataCompleted')({ sid });

    if (!data.success) {
      throw new Error(data.error);
    } else {
      return {
        success: true,
        data: data.data,
      };
    }
  } catch (e) {
    return { success: false, error: e.message };
  }
}
