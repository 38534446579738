import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import WatchesTable from './WatchesTable';
import EachWatchId from './EachWatchId';

function WatchId() {
  const { path } = useRouteMatch();

  return (
    <div className="w-full">
      <Switch>
        <Route exact path={path}>
          <WatchesTable />
        </Route>
        <Route path={`${path}/:watch_id`}>
          <EachWatchId />
        </Route>
      </Switch>
    </div>
  );
}

export default WatchId;
