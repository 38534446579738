import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { firestore } from '../../firebase';
import { updateAccountSettings } from '../../Actions';
import { updateGroup } from '../Set/AccountSettingsSets';

function AccountSettingsSubscription() {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.auth.id);

  const groupValueList = useSelector((state) => state.groupInfo.groupValueList);
  const subGroups = useSelector((state) => state.accountSettings.subGroups);
  const groupInfoFetching = useSelector((state) => state.groupInfo.fetching);

  useEffect(() => {
    const sub = uid
      ? firestore
          .collection('dashboard_id_settings')
          .doc(uid)
          .onSnapshot(
            (doc) => {
              if (doc.exists) {
                dispatch(updateAccountSettings(doc.data()));
              }
            },
            (error) => {
              console.log('error fetching did account settings', error);
            }
          )
      : null;
    return sub;
  }, [dispatch, uid]);

  useEffect(() => {
    if (groupValueList.length > 0 && subGroups.length > 0 && !groupInfoFetching) {
      const toDelete = subGroups.filter((e) => !groupValueList.includes(e));
      if (toDelete.length > 0) {
        const newSubGroups = subGroups.filter((x) => toDelete.indexOf(x) < 0);
        updateGroup(newSubGroups);
      }
    }
  }, [groupInfoFetching, groupValueList, subGroups]);

  return null;
}

export default AccountSettingsSubscription;
