import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function RowItem({ title, value, rightElement: RightElement, actionElement: ActionElement, odd }) {
  return (
    <div className={classNames('flex justify-between rounded py-1 px-2 h-10 items-center', { 'bg-gray-100': odd })}>
      <div className="w-1/2">
        <p className="text-sm font-medium text-gray-500">{title}</p>
      </div>
      <div className="flex justify-end w-1/2">
        {RightElement ? (
          <div className="">
            <RightElement />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <p className="text-sm">{value}</p>
            {ActionElement ? <ActionElement /> : null}
          </div>
        )}
      </div>
    </div>
  );
}

RowItem.prototype = {
  title: PropTypes.string,
  value: PropTypes.string,
  rightElement: PropTypes.element || null,
  actionElement: PropTypes.element || null,
  odd: PropTypes.bool,
};

RowItem.defaultProps = {
  title: 'title',
  value: 'value',
  rightElement: null,
  actionElement: null,
  odd: false,
};

export default RowItem;
