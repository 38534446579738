import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { LockClosedIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import useQuery from '../Components/hooks/useQuery';
import DButton from '../Components/UI/DButton';
import { authRestPasswordWithOobCode } from '../Api/Authentication/AuthenticationWithEmail';
import useNewPassword from '../Components/hooks/useNewPassword';

const RuleTag = ({ rule, meet }) => (
  <span className={classNames('flex items-center gap-1 text-xs', { 'text-success': meet, 'text-gray-300': !meet })}>
    <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
        clipRule="evenodd"
      />
    </svg>
    {rule}
  </span>
);

function ResetPassword() {
  const query = useQuery();
  const history = useHistory();
  const oobCode = query.get('oobCode');
  const [errorMsg, setErrorMsg] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [paswordUpdated, setPaswordUpdated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { password, setPassword, longerThan10, hasNumberLetter, hasUpperLower, passwordValid } = useNewPassword();

  if (!oobCode) {
    return <Redirect to="/login" />;
  }

  const onResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setRequesting(true);
    const [res, err] = await authRestPasswordWithOobCode(oobCode, password);
    if (err) {
      setErrorMsg(err.message);
    } else {
      setPaswordUpdated(true);
    }
    setRequesting(false);
  };

  return (
    <div className="flex flex-row justify-center h-full dark">
      <div className="pt-12 basis-11/12 md:pt-32 md:basis-96">
        <div className="grid gap-3 px-4 py-3 mt-2 bg-white divide-y rounded-xl">
          <div>
            <div className="flex flex-row items-center py-2">
              <div className="flex items-center justify-center w-8 h-8 text-white bg-yellow-400 rounded-full">
                <LockClosedIcon className="w-6 h-6" />
              </div>
              <h4 className="pl-4">Reset password</h4>
            </div>
            {!paswordUpdated ? (
              <form onSubmit={onResetPasswordSubmit} className="grid py-2">
                <div className="mt-4 mb-4">
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-800 ">
                    New password
                  </label>
                  <div className="w-full input-group group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:border-blue-500 block w-full p-2.5 border-r-0"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="border border-l-0 border-gray-300 btn btn-square btn-ghost no-animation"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOffIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                    </button>
                  </div>
                </div>
                <label htmlFor="password-rules" className="grid grid-cols-1 gap-2 label">
                  <RuleTag rule="At least 10 characters" meet={longerThan10} />
                  <RuleTag rule="Letters and numbers" meet={hasNumberLetter} />
                  <RuleTag rule="Upper and lower case" meet={hasUpperLower} />
                </label>
                <p className="py-4 font-semibold text-center text-error">{errorMsg}</p>
                <DButton
                  className="mt-4"
                  type="submit"
                  titile="Submit"
                  loading={requesting}
                  disabled={!passwordValid}
                />
                <div className="flex justify-center w-full pt-4">
                  <button
                    type="button"
                    className="text-sm font-semibold text-gray-500 hover:underline"
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    login instead
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid py-2">
                <p>🎉 Hooray</p>
                <p>Password updated !</p>
                <DButton
                  className="mt-4"
                  type="submit"
                  titile="To Login"
                  onClick={() => {
                    history.push('/login');
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ResetPassword);
