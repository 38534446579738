import { takeLatest, all, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  FETCH_TABLE_DATA_REQUEST,
  FETCH_TABLE_DATA_SUCCESS,
  FETCH_TABLE_DATA_FAILURE,
  OFFBOARD_SID_START,
  OFFBOARD_SID_SUCCESS,
  OFFBOARD_SID_ERROR,
} from '../Actions';
import { fetchHomeTableData } from '../Api/HttpCalls/TableAndEach';
import { completeStudyId } from '../Api/HttpCalls/OffboardSid';
import { getStudyGroupAccess, getGroupInfoObjects } from './selectors';

export function* fetchDataTable() {
  yield takeLatest(FETCH_TABLE_DATA_REQUEST, _fetchDataTable);
}

export function* _fetchDataTable() {
  const res = yield fetchHomeTableData();
  if (res.error) {
    yield put({ type: FETCH_TABLE_DATA_FAILURE, error: res.error_msg });
  } else {
    const groupAccess = yield select(getStudyGroupAccess);
    const groupInfoObjects = yield select(getGroupInfoObjects);
    const allData = res.data;
    const pickData = _.pickBy(allData, (value) => {
      if (groupAccess.includes(value.group)) {
        return true;
      }
      return false;
    });

    const dWithGroupInfo = _.map(pickData, (value) => {
      const groupInfo = groupInfoObjects.find((g) => g.value === value.group);
      return { ...value, subject_study_days: groupInfo.subject_study_days, projectCode: groupInfo.projectCode };
    });

    yield put({
      type: FETCH_TABLE_DATA_SUCCESS,
      data: dWithGroupInfo,
    });
  }
}

export function* offboardSid() {
  yield takeLatest(OFFBOARD_SID_START, _offboardSid);
}

export function* _offboardSid(action) {
  const { sid } = action;
  const res = yield completeStudyId(sid);
  if (res.success) {
    yield put({
      type: OFFBOARD_SID_SUCCESS,
      sid,
    });
  } else {
    yield put({
      type: OFFBOARD_SID_ERROR,
      error: res.error,
    });
  }
}

export function* tableDataSagas() {
  yield all([fetchDataTable(), offboardSid()]);
}
