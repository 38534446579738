import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '../../firebase';
import {
  fetchCompletedSidStart,
  fetchCompletedSidSuccess,
  fetchCompletedSidError,
} from '../../Actions';

function CompletedStudyIdSubscription() {
  const dispatch = useDispatch();
  const idGroupAccess = useSelector((state) => state.auth.studyGroupAccess);

  useEffect(() => {
    dispatch(fetchCompletedSidStart());
    const sub = firestore
      .collection('completed_study_id')
      .where('group', 'in', idGroupAccess)
      .limit(100)
      .onSnapshot(
        (snapshots) => {
          const arr = [];
          snapshots.forEach((snap) => {
            arr.push({
              ...snap.data(),
              early_completion: snap.data().early_completion ?? false,
            });
          });
          dispatch(fetchCompletedSidSuccess(arr));
        },
        (error) => {
          console.log(error);
          dispatch(fetchCompletedSidError(error));
        }
      );
    return sub;
  }, [dispatch, idGroupAccess]);

  return null;
}

export default CompletedStudyIdSubscription;
