import React, { useState } from 'react';
import { ViewGridAddIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import GroupInfoCard from './GroupInfoCard';
import CreateGroupDialog from './CreateGroupDialog';

function GroupManagement() {
  const [showAddGroupDialog, setShowAddGroupDialog] = useState(false);
  const groupInfoObjects = useSelector((state) => state.groupInfo.groupInfoObjects);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  const showError = (errorMsg) => {
    toast.error(errorMsg);
  };

  const showSuccess = (successMsg) => {
    toast.success(successMsg);
  };

  return (
    <div className="relative flex flex-col items-start w-full">
      <CreateGroupDialog
        isOpen={showAddGroupDialog}
        onClose={() => setShowAddGroupDialog(false)}
        onActionSuccess={(msg) => {
          showSuccess(msg);
        }}
        onActionError={(msg) => {
          showError(msg);
        }}
      />
      <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
        {groupInfoObjects.map((groupInfoObj) => (
          <React.Fragment key={groupInfoObj.value}>
            <GroupInfoCard groupInfoObject={groupInfoObj} />
          </React.Fragment>
        ))}
      </div>
      <button
        type="button"
        disabled={!isAdmin}
        className="group absolute w-14 h-14 bg-primary-500 hover:bg-primary-600 bottom-2.5 right-2.5 rounded-full shadow-md flex items-center justify-center disabled:bg-gray-300"
        onClick={() => setShowAddGroupDialog(!showAddGroupDialog)}
      >
        <ViewGridAddIcon className="text-white w-7 h-7 opacity-90 group-disabled:bg-gray-300" />
      </button>
    </div>
  );
}

export default GroupManagement;
