import { useState, useEffect } from 'react';

function useNewPassword() {
  const [password, setPassword] = useState('');
  const [longerThan10, setLongerThan10] = useState(false);
  const [hasNumberLetter, setHasNumberLetter] = useState(false);
  const [hasUpperLower, setHasUpperLower] = useState(false);

  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    if (password.length >= 10) {
      setLongerThan10(true);
    } else {
      setLongerThan10(false);
    }

    if (password.toLocaleLowerCase().match(/[a-z]/g) && password.match(/[0-9]/g)) {
      setHasNumberLetter(true);
    } else {
      setHasNumberLetter(false);
    }

    if (password.match(/[a-z]/g) && password.match(/[A-Z]/g)) {
      setHasUpperLower(true);
    } else {
      setHasUpperLower(false);
    }
  }, [password]);

  useEffect(() => {
    if (longerThan10 && hasNumberLetter && hasUpperLower) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [longerThan10, hasNumberLetter, hasUpperLower]);

  return {
    password,
    setPassword,
    longerThan10,
    hasNumberLetter,
    hasUpperLower,
    passwordValid,
  };
}

export default useNewPassword;
