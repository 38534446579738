// import firebase from "firebase/app";
// import "firebase/functions";
// import "firebase/firestore";

// const db = firebase.firestore();

export async function fetchTableData() {
  try {
    // const { data } = await firebase
    //   .functions()
    //   .httpsCallable("getDashboardData")({data: 'bean'});
    // if (data.error) {
    //   return { error: true, error_msg: data.data };
    // } else {
    //   return { error: false, data: data };
    // }
  } catch (e) {
    return { error: true, error_msg: e };
  }
}

export async function createStudyIds(idList, activationList) {
  try {
    console.log("create Study id is being used but deleted....");
    // let batch = db.batch();
    // let fullList = idList.map((id, i) => ({
    //   studyId: id,
    //   code: activationList[i],
    // }));
    // console.log("fullList", fullList);
    // const snap = await db.collection("study_id").get();
    // let currentIds = snap.docs.map((docSnap) => docSnap.id);
    // let setList = new Set(currentIds);
    // let conflict = idList.some((element) => setList.has(element));
    // console.log("conflict? ", conflict);
    // if (conflict) {
    //   throw new Error("Conflict with existing study IDs");
    // } else {
    //   for (let entry of fullList) {
    //     batch.set(db.collection("study_id").doc(entry.studyId), {
    //       code: entry.code,
    //     });
    //   }
    //   return batch.commit();
    // }
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function fetchIndividualData(sid) {
  // const tzOffset = new Date().getTimezoneOffset();
  // const { data } = await firebase
  //   .functions()
  //   .httpsCallable("dashboardStatsIndividual")({
  //   data: { sid: sid, tzOffset: tzOffset },
  // });
  // console.log("DATA RESPONSE", data);
  // if (data.error) {
  //   throw new Error(data.msg.toString());
  // }
  // return data.data;
}
