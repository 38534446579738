import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { UserCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import DButton from '../UI/DButton';

import app from '../../firebase';

function LoginForm({ changeDisplayForm }) {
  const [code, setCode] = useState('');

  const [vCodeSent, setVCodeSent] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [mfaRequiredError, setMfaRequiredError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const auth = getAuth(app);

  const login = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log('login success, without MFA');
      })
      .catch((error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          setMfaRequiredError(error);
          const resolver = getMultiFactorResolver(auth, error);
          if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            window.RecaptchaVerifier = new RecaptchaVerifier(
              'recaptcha-container',
              {
                size: 'small',
                callback: (response) => {
                  const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session,
                  };
                  const phoneAuthProvider = new PhoneAuthProvider(auth);
                  phoneAuthProvider
                    .verifyPhoneNumber(phoneInfoOptions, window.RecaptchaVerifier)
                    .then((newVerificationId) => {
                      setVCodeSent(true);
                      setVerificationId(newVerificationId);
                      toast.info('Multi-factor authentication code sent.');
                      window.RecaptchaVerifier.clear();
                    });
                },
                'expired-callback': () => {
                  console.log('recaptchaVerifier expired');
                  window.RecaptchaVerifier.clear();
                },
              },
              auth
            );
            window.RecaptchaVerifier.render();
          }
        } else if (error.code === 'auth/wrong-password') {
          toast.error('Incorrect email or password.');
        } else if (error.code === 'auth/too-many-requests') {
          toast.error('Too many failed attempts, reset password via email or try again later.');
        } else {
          console.log(error.code);
          toast.error('Something went wrong, please try again later, or contact patchd.');
        }
      });
  };

  const onSubmit = (data) => {
    login(data.email, data.password);
  };

  const resolveMFA = async () => {
    try {
      const resolver = getMultiFactorResolver(auth, mfaRequiredError);
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await resolver.resolveSignIn(multiFactorAssertion);
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        toast.error('Invalid verification code.');
      } else if (error.code === 'auth/code-expired') {
        toast.error('Code expired, please try again.');
      } else {
        toast.error('Unable to verify code, please try again later, or contact patchd.');
      }
    }
  };

  return (
    <div className="grid gap-3 px-4 py-3 mt-2 bg-white divide-y rounded-xl">
      <div>
        <div className="flex flex-row items-center py-2">
          <div
            className="flex items-center justify-center w-8 h-8 text-white bg-green-400 rounded-full"
            // onClick={() => {
            //   setVCodeSent(!vCodeSent);
            // }}
          >
            <UserCircleIcon className="w-6 h-6" />
          </div>

          <h4 className="pl-4">{!vCodeSent ? 'Login' : 'Multi factor login'}</h4>
        </div>
        <p className="text-gray-400">
          {!vCodeSent
            ? 'Authentication with email and password, for new account please contact patchd.'
            : 'Enter the verification code sent to your via SMS to complete the login process.'}
        </p>
      </div>

      {!vCodeSent ? (
        <form onSubmit={handleSubmit(onSubmit)} className="grid py-2">
          <div className="mt-4 mb-4">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-800 ">
              Email
            </label>
            <input
              type="text"
              id="email"
              className={classNames(
                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5',
                {
                  'bg-red-100': errors.email,
                  'border-red-300': errors.email,
                }
              )}
              {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-600">
              Password
            </label>
            <input
              type="password"
              id="password"
              className={classNames(
                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5',
                {
                  'bg-red-100': errors.password,
                  'border-red-300': errors.password,
                }
              )}
              {...register('password', { required: true, minLength: 10, maxLength: 30 })}
            />
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="mt-2 text-sm font-semibold text-primary-600 hover:underline"
                onClick={() => changeDisplayForm('reset-password')}
              >
                Forgot password?
              </button>
            </div>
          </div>

          <div id="recaptcha-container" className="flex justify-center pb-4 rounded-lg" />
          <DButton className="mt-2 btn-primary" type="submit" titile="Log in" />
        </form>
      ) : (
        <form className="grid py-2">
          <label htmlFor="verificationCode" className="block mb-2 text-sm font-medium text-gray-600">
            Verification code
          </label>
          <input
            id="verificationCode"
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <div className="flex justify-end w-full">
            <button
              type="button"
              className="mt-2 text-sm font-semibold text-primary-600 hover:underline"
              onClick={() => setVCodeSent(false)}
            >
              Back, and try again
            </button>
          </div>
          <DButton className="mt-4 btn-primary" type="button" titile="Submit" onClick={resolveMFA} />
        </form>
      )}

      <div>
        <p className="pt-0.5 text-xs text-right text-gray-300">version {global.appVersion}</p>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  changeDisplayForm: PropTypes.func.isRequired,
};

export default LoginForm;
