import { auth } from '../../firebase';

const ERROR_MSG = {
  'auth/invalid-email': 'Invalid email address.',
  'auth/user-disabled': 'Account disabled.',
  'auth/user-not-found': 'Account with email not found.',
  'auth/wrong-password': 'Incorrect password.',
  'auth/network-request-failed': 'No internet connection.',
  'auth/weak-password': 'Password is too weak.',
  'auth/expired-action-code': 'Link has expired, please request new link.',
  'auth/invalid-action-code': 'Link is invalid, please request new link.',
};

async function authWithEmailPassword(email, password) {
  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    return [res.user.uid, null];
  } catch (e) {
    return [null, { code: e.code ?? 'UNKNOWERROR', message: ERROR_MSG[e.code] ?? 'Unknow error.' }];
  }
}

async function authRestPasswordLink(email) {
  try {
    await auth.sendPasswordResetEmail(email);
    return ['success', null];
  } catch (e) {
    return [null, { code: e.code ?? 'UNKNOWERROR', message: ERROR_MSG[e.code] ?? 'Unknow error.' }];
  }
}

async function authRestPasswordWithOobCode(oobCode, newPassword) {
  try {
    await auth.confirmPasswordReset(oobCode, newPassword);
    return ['success', null];
  } catch (e) {
    return [null, { code: e.code ?? 'UNKNOWERROR', message: ERROR_MSG[e.code] ?? 'Unknow error.' }];
  }
}

export { authWithEmailPassword, authRestPasswordLink, authRestPasswordWithOobCode };
