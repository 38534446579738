import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GroupLabel from '../UI/GroupLabel';
import BaseTable from '../UI/BaseTable';

function FollowupTable({ tableData, loading }) {
  const columns = useMemo(
    () => [
      { Header: 'Study ID', id: 'sid', accessor: 'sid' },
      {
        Header: 'Group',
        id: 'group',
        accessor: 'group',
        Cell: ({ row: { original } }) => <GroupLabel groupValue={original.group} />,
      },
      {
        Header: 'Watch Avail. (72h)',
        id: 'bb_avail',
        accessor: (d) => `${d.bb_avail}%`,
        disableSortBy: true,
        smHidden: true,
      },
      { Header: 'Follow Up Type', id: 'follow_up_type', accessor: 'follow_up_type', disableSortBy: true },
      { Header: 'Day', id: 'type_day_off_set', accessor: 'type_day_off_set', disableSortBy: true },
    ],
    []
  );

  return (
    <div className="w-full">
      <BaseTable columns={columns} data={tableData} loading={loading} responsive />
    </div>
  );
}

FollowupTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.object),
};

FollowupTable.defaultProps = {
  title: '',
  loading: false,
  tableData: [],
};

export default FollowupTable;
