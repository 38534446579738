import React, { useEffect, useState, useCallback } from 'react';
import FollowupTable from './FollowupTable';
import { getFollowUpData } from '../../Api/HttpCalls/FollowUpData';

function Followup() {
  const [loading, setLoading] = useState(false);
  const [followUpData, setFollowUpData] = useState([]);

  const fetchAndSetData = useCallback(async () => {
    setLoading(true);
    const res = await getFollowUpData();
    if (res[0] !== null) {
      setFollowUpData(res[0]);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAndSetData();
    return () => {};
  }, [fetchAndSetData]);

  return (
    <div className="w-full">
      <FollowupTable tableData={followUpData} loading={loading} />
    </div>
  );
}

export default Followup;
