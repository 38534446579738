import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import CProgressbar from '../UI/CProgressbar';
import LoadingPlaceHolder from '../UI/LoadingPlaceHolder';
import updateSidEarlyCompletion from '../../Api/Set/CompletedSidEarlyCompletionSets';
import GroupLabel from '../UI/GroupLabel';

function EachCompletedSidSummary({ entry, loading, sid }) {
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  async function handleToggleDropoutValue(newVal) {
    await updateSidEarlyCompletion(sid, newVal);
  }

  const renderList = () =>
    entry.map((e) => {
      if (e.type === 'group') {
        return (
          <div className="grid items-center h-10 grid-cols-2 col-span-2" key={e.type}>
            <span className="">{e.display}</span>
            <div className="">
              <GroupLabel groupValue={e?.val ?? 'none'} />
            </div>
          </div>
        );
      }

      if (e.type === 'watch' || e.type === 'mTemp') {
        const secondaryText = `${e.val[0]}/${e.val[1]}`;
        const text = `${e.val[2]}%`;
        return (
          <div className="grid items-center h-10 grid-cols-2 col-span-2" key={e.type}>
            <div className="">{e.display}</div>
            <div className="" style={{ fontWeight: 600 }}>
              <CProgressbar progress={e.val[2]} displayText={text} displayText2nd={secondaryText} />
            </div>
          </div>
        );
      }

      if (e.type === 'drop-out') {
        return (
          <div className="grid items-center h-10 grid-cols-2 col-span-2" key={e.type}>
            <div className="">{e.display}</div>
            <div className="w-56">
              <Menu as="div" className="relative inline-block text-left">
                <div className="w-full">
                  <Menu.Button
                    disabled={!isAdmin}
                    className={classNames(
                      'inline-flex w-full px-3 py-1 text-sm font-bold text-gray-500 rounded-lg bg-opacity-100 hover:bg-opacity-80 group border-gray-300 hover:border-gray-400',
                      { 'border-0': !isAdmin, 'border-2': isAdmin }
                    )}
                  >
                    {e.val.toString()}
                    {isAdmin ? (
                      <ChevronDownIcon
                        className="w-5 h-5 ml-12 -mr-1 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : null}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-44 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              handleToggleDropoutValue(true);
                            }}
                            className={`${
                              active ? 'bg-yellow-400 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-3 py-1 text-sm font-medium`}
                          >
                            true
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              handleToggleDropoutValue(false);
                            }}
                            className={`${
                              active ? 'bg-yellow-400 text-white' : 'text-gray-900'
                            } group flex rounded-md items-center w-full px-3 py-1 text-sm font-medium`}
                          >
                            false
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        );
      }

      return (
        <div className="grid items-center h-10 grid-cols-2 col-span-2" key={e.type}>
          <span className="">{e.display}</span>
          <span className="" style={{ fontWeight: 600 }}>
            {String(e.val)}
          </span>
        </div>
      );
    });

  if (loading) {
    return <LoadingPlaceHolder />;
  }

  if (entry !== null) {
    return <div className="grid grid-cols-2 gap-1">{renderList()}</div>;
  }

  return null;
}

EachCompletedSidSummary.propTypes = {
  entry: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  sid: PropTypes.string.isRequired,
};

export default EachCompletedSidSummary;
