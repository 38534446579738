const ColorPresets = [
  { bg: '#333333', text: '#fff' },
  { bg: '#02343F', text: '#F0EDCC' },
  { bg: '#ACC7B4', text: '#331B3F' },
  { bg: '#0A174E', text: '#F5D042' },
  { bg: '#07553B', text: '#CED46A' },
  { bg: '#50586C', text: '#DCE2F0' },
  { bg: '#815854', text: '#F9EBDE' },
  { bg: '#1E4174', text: '#DDA94B' },
  { bg: '#A4193D', text: '#FFDFB9' },
  { bg: '#6A7BA2', text: '#FFDFDE' },
  { bg: '#ADEFD1', text: '#00203F' },
  { bg: '#606060', text: '#D6ED17' },
  { bg: '#00539C', text: '#EEA47F' },
  { bg: '#101820', text: '#FEE715' },
  { bg: '#7b9acc', text: '#FCF6F5' },
  { bg: '#101820', text: '#F2AA4C' },
  { bg: '#2BAE66', text: '#FCF6F5' },
  { bg: '#6E6E6D', text: '#FAD0C9' },
  { bg: '#333D79', text: '#FAEBEF' },
  { bg: '#101820', text: '#1a7a4c' },
  { bg: '#343148', text: '#D7C49E' },
  { bg: '#292826', text: '#F9D142' },
  { bg: '#3C1A5B', text: '#FFF548' },
  { bg: '#E2B143', text: '#4A171E' },
  { bg: '#DDC6B6', text: '#262223' },
];

function generateRandomColorHex() {
  const hex = Math.floor(Math.random() * 16777215).toString(16);
  return `#${`000000${hex}`.slice(-6)}`;
}

function getRandomColorPreset() {
  const randomIndex = Math.floor(Math.random() * ColorPresets.length);
  return ColorPresets[randomIndex];
}

export { generateRandomColorHex, getRandomColorPreset };
