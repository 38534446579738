export const TABLE_NAME = {
  HOME: 'tableHome',
  WATCH: 'tableWatch',
  COMPLETEDSID: 'tableCompletedSid',
};

export const SET_TABLE_FILTER = 'SET_TABLE_FILTER';
export const SET_TABLE_SORT = 'SET_TABLE_SORT';
export const SET_TABLE_GLOBAL_FILTER = 'SET_TABLE_GLOBAL_FILTER';

export function setTableFilter(tableName, filterObjects) {
  return {
    type: SET_TABLE_FILTER,
    tableName,
    filterObjects,
  };
}

export function setTableSort(tableName, sortObject) {
  return {
    type: SET_TABLE_SORT,
    tableName,
    sortObject,
  };
}

export function setTableGlobalFilter(tableName, value) {
  return {
    type: SET_TABLE_GLOBAL_FILTER,
    tableName,
    value,
  };
}
