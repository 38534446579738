import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { PlusCircleIcon, CheckCircleIcon, XCircleIcon, MinusCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import usePrevious from '../hooks/usePrevious';
import DButton from '../UI/DButton';
import { createGroupInfo } from '../../Api/Set/StudyGroupInfoSets';
import { getRandomColorPreset } from './GroupColorPresets';

function CreateGroupDialog({ isOpen, onClose, onActionSuccess, onActionError }) {
  const [showNewFollowUpRow, setShowNewFollowUpRow] = useState(false);
  const [followUpObjects, setFollowUpObjects] = useState([{ type: 'hours-24', inHours: 24 }]);
  const dashboardid = useSelector((state) => state.auth.id);
  const projectAccess = useSelector((state) => state.auth.projectAccess);
  const [displayColorBG, setDisplayColorBG] = useState('#333333');
  const [dispayColorText, setDispayColorText] = useState('#fff');
  const prevIsOpen = usePrevious(isOpen);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  // transform string to lowercase and remove spaces
  function groupLabelToValue(str) {
    return str.toLowerCase().replace(/\s/g, '-');
  }

  function isHashColor(str) {
    return /^#[0-9A-F]{6}$/i.test(str);
  }

  const onSubmit = async (data) => {
    const groupVal = groupLabelToValue(data.label);
    const combinedObject = {
      ...data,
      subject_study_days: Number(data.subject_study_days),
      value: groupVal,
      createdAt: new Date(),
      createdBy: dashboardid,
      follow_up_types: followUpObjects,
      group_active: true,
    };

    const [success, msg] = await createGroupInfo(groupVal, combinedObject);

    onClose();

    if (success) {
      onActionSuccess(msg);
      reset();
    } else {
      onActionError(msg);
    }
  };

  const onError = (e) => {
    console.log('error create group.', e);
  };

  const onCancelCreateGroup = () => {
    onClose();
    reset();
    setFollowUpObjects([{ type: 'hours-24', inHours: 24 }]);
  };

  const fields = watch();

  useEffect(() => {
    if (isHashColor(fields?.colorBackground)) {
      setDisplayColorBG(fields?.colorBackground);
    } else {
      setDisplayColorBG('#333333');
    }

    if (isHashColor(fields?.colorText)) {
      setDispayColorText(fields?.colorText);
    } else {
      setDispayColorText('#fff');
    }
  }, [fields]);

  useEffect(() => {
    if (isOpen && prevIsOpen !== isOpen) {
      const initialGroupColors = getRandomColorPreset();
      setValue('colorBackground', initialGroupColors.bg);
      setValue('colorText', initialGroupColors.text);
    }
  }, [isOpen, prevIsOpen, setValue]);

  const {
    register: registerFollowUp,
    // watch: watchFollowUp,
    handleSubmit: handleSubmitFollowUp,
    formState: { errors: errorsFollowUp },
    reset: resetFollowUp,
  } = useForm();

  const onFollowUpSumbmit = (data) => {
    const converted = { type: data.type, inHours: Number(data.inHours) };
    const duplicateHours = followUpObjects.find((obj) => obj.inHours === converted.inHours);
    if (!duplicateHours) setFollowUpObjects([...followUpObjects, converted]);
    setShowNewFollowUpRow(false);
    resetFollowUp();
  };

  const onFollowUpCancel = () => {
    setShowNewFollowUpRow(false);
    resetFollowUp();
  };

  const removeFollowUpObjByIndex = (index) => {
    const list = [...followUpObjects];
    list.splice(index, 1);
    setFollowUpObjects(list);
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog className="fixed inset-0 z-10 overflow-y-auto" open={isOpen} onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="relative w-auto px-3 py-2 mx-auto bg-white rounded">
            <Dialog.Title as="h5">Create a new group</Dialog.Title>
            <Dialog.Description>enter the following group info.</Dialog.Description>

            <div className="my-2" style={{ width: '360px' }}>
              <form onSubmit={handleSubmit(onSubmit, onError)} className="grid grid-cols-2 gap-1">
                {/* <input type="submit" /> */}
                <div className="flex items-center col-span-1 mx-1">Label</div>
                <div className="col-span-1">
                  <input
                    className={classNames('w-full py-1 px-2 rounded-lg border-2', {
                      'border-gray-100': !fields.label,
                      'border-red-200': errors.label,
                      'border-green-200': fields.label && !errors.label,
                    })}
                    autoComplete="off"
                    type="text"
                    name="label"
                    {...register('label', {
                      required: true,
                      minLength: 4,
                      maxLength: 8,
                    })}
                  />
                </div>
                <div className="flex items-center col-span-1 mx-1">Project code</div>
                <div className="col-span-1">
                  <select
                    className={classNames('w-full py-1 px-2 rounded-lg border-2', {
                      'border-gray-100': !fields.projectCode,
                      'border-red-200': errors.projectCode,
                      'border-green-200': fields.projectCode && !errors.projectCode,
                    })}
                    name="projectCode"
                    defaultValue=""
                    {...register('projectCode', {
                      required: true,
                    })}
                  >
                    <option value="" disabled>
                      select a project
                    </option>
                    {projectAccess.map((project) => (
                      <option key={project} value={project}>
                        {project}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center col-span-1 mx-1">Study duration</div>
                <div className="col-span-1">
                  <input
                    className={classNames('w-full py-1 px-2 rounded-lg border-2', {
                      'border-gray-100': !fields.subject_study_days,
                      'border-red-200': errors.subject_study_days,
                      'border-green-200': fields.subject_study_days && !errors.subject_study_days,
                    })}
                    autoComplete="off"
                    type="number"
                    name="subject_study_days"
                    {...register('subject_study_days', {
                      required: true,
                      min: 5,
                      max: 100,
                    })}
                  />
                </div>
                <div className="flex items-center col-span-1 mx-1">
                  <p>Color BG</p>
                  <div className="w-5 h-5 ml-4 rounded-full" style={{ backgroundColor: displayColorBG }} />
                </div>
                <div className="col-span-1">
                  <input
                    className={classNames('w-full py-1 px-2 rounded-lg border-2', {
                      'border-gray-100': !fields.colorBackground,
                      'border-red-200': errors.colorBackground,
                      'border-green-200': fields.colorBackground && !errors.colorBackground,
                    })}
                    autoComplete="off"
                    type="text"
                    name="colorBackground"
                    {...register('colorBackground', {
                      required: true,
                      min: 5,
                      max: 100,
                      validate: (value) => /^#[0-9A-F]{6}$/i.test(value),
                    })}
                  />
                </div>
                <div className="flex items-center col-span-1 mx-1">
                  <p>Color Text</p>
                  <div className="w-5 h-5 ml-4 rounded-full" style={{ backgroundColor: dispayColorText }} />
                  <div />
                </div>
                <div className="col-span-1">
                  <input
                    className={classNames('w-full py-1 px-2 rounded-lg border-2', {
                      'border-gray-100': !fields.colorText,
                      'border-red-200': errors.colorText,
                      'border-green-200': fields.colorText && !errors.colorText,
                    })}
                    autoComplete="off"
                    type="text"
                    name="colorText"
                    {...register('colorText', {
                      required: true,
                      min: 5,
                      max: 100,
                      validate: (value) => /^#[0-9A-F]{6}$/i.test(value),
                    })}
                  />
                </div>
              </form>
              <div className="relative flex items-center justify-center h-16 col-span-2 my-2 bg-gray-100">
                <span
                  className="px-4 py-1 rounded-md m-semiBold"
                  style={{
                    color: dispayColorText,
                    backgroundColor: displayColorBG,
                  }}
                >
                  {fields?.label?.length > 0 ? fields?.label : 'GROUP'}
                </span>
                <p className="absolute text-xs bottom-0.5 right-1 text-gray-300 rounded-sm">preview</p>
              </div>
              <div className="relative flex flex-row justify-center col-span-2 mt-6">
                <p className="font-medium text-center text-gray-400">Follow up schedule</p>
                <button
                  className="absolute right-2"
                  type="button"
                  onClick={() => {
                    setShowNewFollowUpRow(!showNewFollowUpRow);
                  }}
                >
                  <PlusCircleIcon className="w-6 text-primary-500" />
                </button>
              </div>
              <table className="w-full mb-4 table-auto">
                <thead>
                  <tr>
                    <th className="w-1/2">Type</th>
                    <th className="w-1/2">in Hours</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {followUpObjects.map((followUpObject, index) => (
                    <tr key={followUpObject.type}>
                      <td>{followUpObject.type}</td>
                      <td className="relative w-1/2">
                        {followUpObject.inHours}
                        <button type="button" className="absolute top-1 right-1">
                          <MinusCircleIcon
                            className="w-5 h-5 text-gray-400"
                            onClick={() => {
                              removeFollowUpObjByIndex(index);
                            }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                  {showNewFollowUpRow ? (
                    <>
                      <tr className="text-center">
                        <td className="w-1/2">
                          <input
                            className={classNames('w-full text-center rounded-lg bg-gray-50 p-1', {
                              'bg-red-100': errorsFollowUp.type,
                            })}
                            placeholder="new"
                            type="text"
                            name="type"
                            autoComplete="off"
                            {...registerFollowUp('type', {
                              required: true,
                              minLength: 4,
                              maxLength: 8,
                              validate: (value) => !/^\s*$/.test(value),
                            })}
                          />
                        </td>
                        <td className="w-1/2">
                          <input
                            className={classNames('w-full text-center rounded-lg bg-gray-50 p-1', {
                              'bg-red-100': errorsFollowUp.inHours,
                            })}
                            placeholder="new"
                            type="number"
                            name="inHours"
                            autoComplete="off"
                            {...registerFollowUp('inHours', {
                              required: true,
                              min: 24,
                            })}
                          />
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="" />
                        <td className="pt-2 text-right">
                          <button type="button" className="pr-2 text-center" onClick={() => onFollowUpCancel()}>
                            <XCircleIcon className="w-8 h-8 text-primary-500" />
                          </button>
                          <button
                            type="button"
                            className="pr-1 text-center"
                            onClick={handleSubmitFollowUp(onFollowUpSumbmit)}
                          >
                            <CheckCircleIcon className="w-8 h-8 text-primary-500" />
                          </button>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end w-full gap-2 mt-12">
              <DButton titile="Create" className="w-1/2 btn-primary" onClick={handleSubmit(onSubmit, onError)} />
              <DButton
                titile="Cancel"
                className="flex-grow"
                onClick={() => {
                  onCancelCreateGroup();
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

CreateGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onActionSuccess: PropTypes.func,
  onActionError: PropTypes.func,
};

CreateGroupDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onActionSuccess: () => {},
  onActionError: () => {},
};

export default CreateGroupDialog;
