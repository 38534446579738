import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

export default async function deleteStudyId(sid) {
  try {
    const response = await firebase.functions().httpsCallable('deleteStudyId')({
      sid,
    });
    return [true, null];
  } catch (e) {
    console.log(e);
    return [null, 'Error'];
  }
}
