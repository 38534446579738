import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useGroupLabelStyle from '../hooks/useGroupLabelStyle';

function GroupLabel({ groupValue, small }) {
  const { textColor, bgColor, labelText } = useGroupLabelStyle(groupValue);

  return (
    <span
      className={classNames('px-4 py-0.5 rounded-full m-semiBold', { 'py-0.5': small, 'px-3': small })}
      style={{
        color: textColor,
        backgroundColor: bgColor,
      }}
    >
      {labelText || groupValue}
    </span>
  );
}

GroupLabel.propTypes = {
  groupValue: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

GroupLabel.defaultProps = {
  small: false,
};

export default GroupLabel;
