import React from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import classNames from 'classnames';
import { SortColumnIndicator, LoadingRows, NoRows } from './TableParts';

function BaseTableV2({
  responsive = true,
  loading = false,
  tableData,
  columns,
  editableRowId = null,
  onDataUpdate = () => {
    console.log('no method is passed to onDataUpdate');
  },
  headerElement,
  compact = false,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      onDataUpdate,
      editableRowId,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
    },
    useFilters,
    useSortBy
  );

  return (
    <div
      className={classNames('w-full overflow-x-auto', {
        'pb-96': editableRowId !== null,
      })}
    >
      <table {...getTableProps()} className={classNames('table w-full mb-12', { 'table-compact': compact })}>
        {headerElement ? (
          <thead>
            <tr>
              <th colSpan={columns.length} className="py-2">
                {headerElement}
              </th>
            </tr>
          </thead>
        ) : null}
        <thead className={classNames({ 'hidden sm:table-header-group': responsive })}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classNames(`${column.ccn}`, { 'sm:table-cell hidden': column?.smHidden })}
                >
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      {column.render('Header')}
                      <SortColumnIndicator column={column} />
                    </div>
                    <div className="pt-1">{column.filter ? column.render('Filter') : null}</div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <LoadingRows numRows={4} numCells={columns.length} />
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={classNames('', {
                    'not-last:border-b-[5px] sm:not-last:border-b-[1px] border-gray-100 bg-white':
                      responsive && !row.column?.smHidden,
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={classNames(`${cell.column.ccn} py-2`, {
                        'sm:table-cell flex space-between border-b-0': responsive && !cell.column?.smHidden,
                        'sm:table-cell hidden': cell.column?.smHidden,
                        'flex justify-center': cell.column.Header === '',
                      })}
                      {...cell.getCellProps()}
                    >
                      <p
                        className={classNames('font-medium text-gray-400', {
                          'flex sm:hidden': responsive,
                          hidden: !responsive || cell.column.Header === '',
                        })}
                      >
                        {cell.column.Header}
                      </p>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
          {rows.length === 0 && !loading ? <NoRows numCells={columns.length} /> : null}
        </tbody>
      </table>
    </div>
  );
}

export default BaseTableV2;
