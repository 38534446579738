import produce from 'immer';
import { UPDATE_ACCOUNT_SETTINGS, LOG_OUT } from '../Actions';

export const INITIAL_STATE = {
  dailyEmailSub: false,
  email: '',
  subGroups: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_ACCOUNT_SETTINGS: {
        const { dailyEmailSub, email, subGroups } = action.obj;
        if (dailyEmailSub !== undefined) draft.dailyEmailSub = dailyEmailSub;
        if (email) draft.email = email;
        if (subGroups) draft.subGroups = subGroups;
        break;
      }
      case LOG_OUT:
        return INITIAL_STATE;
      default:
        break;
    }
  });
