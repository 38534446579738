import produce from 'immer';
import {
  REQUEST_TOKEN,
  REQUEST_TOKEN_SUCCESS,
  REQUEST_TOKEN_ERROR,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGIN_WITH_TOKEN_ERROR,
  LOG_OUT,
  LOGIN_SCREEN_MOUNTED,
  UPDATE_GROUP_ACCESS,
  RESET_PASSWORD_ERROR,
  // LOGIN_WITH_EMAIL_PASSWORD,
  // LOGIN_WITH_EMAIL_PASSWORD_SUCCESS,
  // LOGIN_WITH_EMAIL_PASSWORD_ERROR,
  FIREBASE_AUTH_STATE_CHANGED,
  FETCH_DASHBOARDIDINFO,
  FETCH_DASHBOARDIDINFO_SUCCESS,
  FETCH_DASHBOARDIDINFO_ERROR,
} from '../Actions';

export const INITIAL_STATE = {
  id: null,
  email: null,
  mfaEnrollmentRequired: false,
  roles: {
    admin: false,
    coordinator: false,
    owner: false,
  },
  projectAccess: ['none'],
  studyGroupAccess: ['none'],
  token_requesting: false,
  dashboard_id_info_requesting: false,
  auth_error: {
    code: null,
    message: null,
    ts: null,
  },
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_TOKEN:
        draft.id = null;
        draft.email = INITIAL_STATE.email;
        draft.roles = INITIAL_STATE.roles;
        draft.projectAccess = INITIAL_STATE.projectAccess;
        draft.studyGroupAccess = INITIAL_STATE.studyGroupAccess;
        draft.token_requesting = true;
        break;
      case REQUEST_TOKEN_SUCCESS:
        break;
      case REQUEST_TOKEN_ERROR:
        draft.token_requesting = false;
        draft.auth_error = {
          code: action.error.code ?? 'UNKNOWN',
          message: action.error.msg ?? 'Unknown Error',
          ts: new Date(),
        };
        break;
      case LOGIN_WITH_TOKEN_SUCCESS:
        draft.token_requesting = false;
        draft.id = action.id;
        draft.roles = action.roles;
        if (action.projectAccess.length !== 0) draft.projectAccess = action.projectAccess;
        if (action.studyGroupAccess.length !== 0) draft.studyGroupAccess = action.studyGroupAccess;
        break;
      case LOGIN_WITH_TOKEN_ERROR:
        draft.token_requesting = false;
        draft.auth_error = {
          code: 'TOKEN_ERROR',
          message:
            action.error.code === 'TIMEOUT'
              ? 'Request timedout please try again later.'
              : 'Authentication with token failed, please contact Patchd.',
          ts: new Date(),
        };
        break;
      case LOGIN_SCREEN_MOUNTED:
        // draft.token_requesting = INITIAL_STATE.token_requesting;
        // draft.dashboard_id_info_requesting = INITIAL_STATE.dashboard_id_info_requesting;
        // draft.auth_error = INITIAL_STATE.auth_error;
        // break;
        return INITIAL_STATE;
      case UPDATE_GROUP_ACCESS:
        draft.studyGroupAccess = action.groupList;
        break;
      case RESET_PASSWORD_ERROR:
        draft.auth_error = {
          code: 'RESET_PASSWORD_ERROR',
          message: action.error.message ?? 'Unknown Error',
          ts: new Date(),
        };
        break;
      // case LOGIN_WITH_EMAIL_PASSWORD:
      //   draft.id = null;
      //   draft.email = INITIAL_STATE.email;
      //   draft.roles = INITIAL_STATE.roles;
      //   draft.projectAccess = INITIAL_STATE.projectAccess;
      //   draft.studyGroupAccess = INITIAL_STATE.studyGroupAccess;
      //   break;
      // case LOGIN_WITH_EMAIL_PASSWORD_SUCCESS:
      //   break;
      // case LOGIN_WITH_EMAIL_PASSWORD_ERROR:
      //   draft.auth_error = {
      //     code: action.error.code ?? 'UNKNOWN',
      //     message: action.error.message ?? 'Unknown Error',
      //     ts: new Date(),
      //   };
      //   break;
      case FIREBASE_AUTH_STATE_CHANGED:
        // draft.id = null;
        // draft.email = INITIAL_STATE.email;
        // draft.roles = INITIAL_STATE.roles;
        // draft.projectAccess = INITIAL_STATE.projectAccess;
        // draft.studyGroupAccess = INITIAL_STATE.studyGroupAccess;
        break;
      case FETCH_DASHBOARDIDINFO:
        draft.dashboard_id_info_requesting = true;
        break;
      case FETCH_DASHBOARDIDINFO_SUCCESS:
        draft.dashboard_id_info_requesting = false;
        draft.id = action.id;
        draft.email = action.email;
        draft.mfaEnrollmentRequired = action.mfaEnrollmentRequired;
        draft.roles = action.roles;
        if (action.projectAccess.length !== 0) draft.projectAccess = action.projectAccess;
        if (action.studyGroupAccess.length !== 0) draft.studyGroupAccess = action.studyGroupAccess;
        break;
      case FETCH_DASHBOARDIDINFO_ERROR:
        draft.dashboard_id_info_requesting = false;
        draft.auth_error = {
          code: action.error.code ?? 'UNKNOWN',
          message: action.error.msg ?? 'Unknown Error',
          ts: new Date(),
        };
        break;
      case LOG_OUT:
        return INITIAL_STATE;
      default:
        break;
    }
  });
