import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import CProgressbar from '../UI/CProgressbar';
import OffboardSidDialog from './OffboardSidDialog';
import SwapWatchIdDialog from './SwapWatchIdDialog';
import GroupLabel from '../UI/GroupLabel';
import { updateExtendedStudyDays } from '../../Api/Set/ExtendedStudyDaysSets';
import { fetchTableData } from '../../Actions';

function SummaryCard(props) {
  const dispatch = useDispatch();
  const { sid } = props;
  const tableData = useSelector((state) => state.tableData.home_table_data);
  const personSidDocData = useSelector((state) => state.personData.sidDoc);
  const personDataFetching = useSelector((state) => state.personData.fetching);
  const personDataBb = useSelector((state) => state.personData.bbAvail);
  const personDataMTmep = useSelector((state) => state.personData.mTempAvail);
  const [data, setData] = useState(null);

  const updateSidExtendedStudyDays = async (study_id, extendedStudyDays) => {
    await updateExtendedStudyDays(study_id, extendedStudyDays);
    dispatch(fetchTableData());
  };

  useEffect(() => {
    const d = tableData.find((obj) => obj.sid === sid);
    const dt = new Date() - new Date(d?.activation_date);
    const enrolledDays = Math.floor(dt / (1000 * 60 * 60 * 24));

    if (d) {
      const sumD = [
        { type: 'Study id', value: d.sid },
        { type: 'Watch id', value: d.watch_id },
        { type: 'Group', value: d?.group ?? 'none' },
        { type: 'Enrolled', value: enrolledDays, ssd: d?.subject_study_days ?? 0 },
        { type: 'Study Days', value: d?.subject_study_days ?? '-' },
        { type: 'watch-72h-avail', value: d.bb_72h_size_avail },
        {
          type: 'temp-72-avail',
          value: `${d.mtemp_72h_size_avail}%`,
        },
      ];
      setData(sumD);
    }
  }, [sid, tableData]);

  const renderList = () =>
    data.map((e) => {
      if (e.type === 'Enrolled') {
        const { extended_study_days } = personSidDocData;
        const daysLeft = extended_study_days ? extended_study_days - e.value : e.ssd - e.value;
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <span>{e.type}</span>
            <div className="flex w-2/3 space-between sm:w-full place-self-end">
              <span className="font-semibold">{e.value < 365 ? `${e.value} days` : '-'}</span>
              <label
                htmlFor="offboard-modal"
                className={classNames('btn btn-xs modal-button', {
                  'btn-primary': daysLeft < 0,
                  'btn-error': daysLeft > 0,
                })}
              >
                <i className="pr-2 pi pi-user-minus" />
                Offboard
              </label>
            </div>
          </div>
        );
      }

      if (e.type === 'Study Days') {
        const { extended_study_days } = personSidDocData;
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <span>Study duration</span>
            <div className="flex w-2/3 space-between sm:w-full place-self-end">
              <p className="font-semibold">
                {extended_study_days ? `${extended_study_days} days (P2)` : `${e.value} days`}
              </p>
              {extended_study_days ? (
                <div className="tooltip tooltip-left" data-tip="Undo extended Phase-2 study duration.">
                  <button
                    type="button"
                    className="btn btn-xs"
                    onClick={() => {
                      updateSidExtendedStudyDays(sid, null);
                    }}
                  >
                    <p>Undo extend</p>
                  </button>
                </div>
              ) : (
                <div className="tooltip tooltip-left" data-tip="Extend study duration to 90 days Phase-2.">
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      updateSidExtendedStudyDays(sid, 90);
                    }}
                  >
                    <p>Extend</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      }

      if (e.type === 'Group') {
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <span>{e.type}</span>
            <div className="flex w-2/3 font-semibold place-self-end sm:w-full">
              <GroupLabel groupValue={e?.value ?? 'none'} small />
            </div>
          </div>
        );
      }

      if (e.type === 'watch-72h-avail') {
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <div className="">Watch data avail. (72h)</div>
            <div className="flex justify-end font-semibold sm:justify-start">
              <CProgressbar progress={e.value} displayText={`${e.value}%`} />
            </div>
          </div>
        );
      }

      if (e.type === 'temp-72-avail') {
        const progressVal = Number(e.value.replace('%', ''));

        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <div className="">Manual temp data avail. (72h)</div>
            <div className="flex justify-end font-semibold sm:justify-start">
              <CProgressbar progress={progressVal} displayText={e.value} />
            </div>
          </div>
        );
      }

      if (e.type === 'Study id') {
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <span className="">Study ID</span>
            <span className="font-semibold place-self-end sm:place-self-start">{String(e.value)}</span>
          </div>
        );
      }

      if (e.type === 'Watch id') {
        return (
          <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
            <span className="">Watch ID</span>
            <div className="flex justify-between w-2/3 sm:w-full place-self-end">
              <span className="font-semibold">{String(e.value)}</span>
              <label htmlFor="swap-watch-modal" className="btn btn-xs btn-warning modal-button">
                <i className="pr-2 pi pi-sync" />
                swap watch
              </label>
            </div>
          </div>
        );
      }

      return null;
    });

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const renderFullAvail = () => {
    let bbDisplay = 0;
    let mTempDisplay = 0;

    if (personDataBb.bb_sample_expected && personDataBb.bb_sample_expected !== 0) {
      bbDisplay = ((personDataBb.bb_sample_count * 100) / personDataBb.bb_sample_expected).toFixed(0) ?? 0;
    }

    if (personDataMTmep.temp_sample_expected && personDataMTmep.temp_sample_expected !== 0) {
      mTempDisplay = ((personDataMTmep.temp_sample_count * 100) / personDataMTmep.temp_sample_expected).toFixed(0) ?? 0;
    }

    const fullAvail = [
      { type: 'Watch data avail. (full duration)', value: bbDisplay ?? 0 },
      { type: 'Manual temp data avail. (full duration)', value: mTempDisplay ?? 0 },
    ];

    return fullAvail.map((e) => {
      const progressVal = Number(e.value) ?? 0;

      return (
        <div className="grid items-center col-span-2 sm:grid-cols-2" key={e.type}>
          <div className="">{e.type}</div>
          <div className="flex justify-end font-semibold sm:justify-start">
            <CProgressbar progress={progressVal} displayText={`${e?.value}%`} loading={personDataFetching} />
          </div>
        </div>
      );
    });
  };

  // console.log(personSidDocData);

  const renderSidDocList = () => {
    const displayObj = _.omit(personSidDocData, 'extended_study_days');
    const entries = Object.entries(displayObj);
    return entries.map((e) => (
      <div className="grid items-center col-span-2 sm:grid-cols-2" key={e[0]}>
        <span className="">{capitalize(e[0])}</span>
        <span className="flex justify-end font-semibold sm:justify-start">{e[1]}</span>
      </div>
    ));
  };

  return (
    <div className="grid grid-flow-row grid-cols-2 gap-3 py-2 auto-rows-fr">
      <OffboardSidDialog sid={sid} sidData={{ ...personSidDocData, ...tableData.find((obj) => obj.sid === sid) }} />
      <SwapWatchIdDialog sid={sid} currentWatchId={data?.find((e) => e?.type === 'Watch id')?.value} />
      {data ? renderList() : null}
      {renderFullAvail()}
      {renderSidDocList()}
    </div>
  );
}

SummaryCard.propTypes = {
  sid: PropTypes.string.isRequired,
};

export default SummaryCard;
