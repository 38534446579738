import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { earlyCompleteStudyId, completeStudyId } from '../../Api/HttpCalls/OffboardSid';
import { clearPersonData, fetchTableData } from '../../Actions';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function OffboardSidDialog({ sid, sidData }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { extended_study_days, subject_study_days, activation_date } = sidData;

  const enrolled_days = Math.floor((new Date() - new Date(activation_date)) / (1000 * 60 * 60 * 24));
  const study_duration = extended_study_days || subject_study_days;
  const study_finished = enrolled_days >= study_duration;

  const showSuccess = () => {
    toast.success(`Study Id ${sid} off-boarded successfully.`);
  };

  const showError = (error) => {
    toast.error(`error : ${error}`);
  };

  const offboardPrematurely = async () => {
    setLoading(true);
    const res = await earlyCompleteStudyId(sid);
    setLoading(false);
    if (res.success) {
      showSuccess(sid);
      history.goBack();
      dispatch(clearPersonData());
      dispatch(fetchTableData());
    } else {
      showError(res.error);
    }
  };

  const offboard = async () => {
    setLoading(true);
    const res = await completeStudyId(sid);
    setLoading(false);
    if (res.success) {
      showSuccess(sid);
      history.goBack();
      dispatch(clearPersonData());
      dispatch(fetchTableData());
    } else {
      showError(res.error);
    }
  };

  return (
    <>
      <input type="checkbox" id="offboard-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Offboard participant</h3>
          <p className="py-4">
            Mark this participant as completed. Once offboarded, information about this participant will be available on
            the Completed Participants page.
          </p>
          {!study_finished ? (
            <p className="font-semibold text-error">{`The expected monitoring duration for this participant is  ${study_duration} days, however they have only been enrolled for  ${enrolled_days} days. If the participant decides to opt out of study, you can offboard them prematurely.`}</p>
          ) : null}
          <div className="modal-action">
            {!study_finished ? (
              <button
                type="button"
                className="btn btn-error"
                onClick={
                  loading
                    ? () => {
                        console.log('loading');
                      }
                    : () => {
                        offboardPrematurely();
                      }
                }
              >
                {loading ? <LoadingSpiner className="mr-2" /> : null}
                offboard prematurely
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={
                  loading
                    ? () => {
                        console.log('loading');
                      }
                    : () => {
                        offboard();
                      }
                }
              >
                {loading ? <LoadingSpiner className="mr-2 text-white" /> : null}
                offboard
              </button>
            )}
            <label htmlFor="offboard-modal" className="btn">
              Dismiss
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

OffboardSidDialog.propTypes = {
  sid: PropTypes.string.isRequired,
  sidData: PropTypes.object,
};

OffboardSidDialog.defaultProps = {
  sidData: {
    extended_study_days: null,
    subject_study_days: null,
    activation_date: null,
  },
};

export default OffboardSidDialog;
