import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { TicketIcon } from '@heroicons/react/solid';
import { requestToken } from '../../Actions';
import DButton from '../UI/DButton';

function IDAndCode() {
  const dispatch = useDispatch();
  const tokenRequesting = useSelector((state) => state.auth.token_requesting);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      did: '',
      code: '',
    },
  });

  const onSubmit = (data) => {
    dispatch(requestToken(data.did, data.code));
  };

  return (
    <div className="grid gap-3 px-4 py-3 mt-2 bg-white divide-y rounded-xl">
      <div>
        <div className="flex flex-row items-center py-2">
          <div className="flex items-center justify-center w-8 h-8 text-white bg-orange-400 rounded-full">
            <TicketIcon className="w-5 h-5" />
          </div>
          <h4 className="pl-4">ID and code</h4>
        </div>
        <p className="text-gray-400">Login with provided authentication id and code.</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="grid py-2">
        <div className="mt-4 mb-4">
          <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-800 ">
            Dashboard ID
          </label>
          <input
            type="text"
            id="id"
            className={classNames(
              'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5',
              {
                'bg-red-100': errors.did,
                'border-red-300': errors.did,
              }
            )}
            {...register('did', { required: true, minLength: 6, maxLength: 20 })}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-600">
            Code
          </label>
          <input
            type="password"
            id="code"
            className={classNames(
              'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5',
              {
                'bg-red-100': errors.code,
                'border-red-300': errors.code,
              }
            )}
            {...register('code', { required: true, minLength: 6, maxLength: 20 })}
          />
        </div>
        <DButton className="mt-4 btn-primary" type="submit" titile="Request & login" loading={tokenRequesting} />
      </form>
      <div>
        <p className="pt-0.5 text-xs text-right text-gray-300">version {global.appVersion}</p>
      </div>
    </div>
  );
}

export default IDAndCode;
