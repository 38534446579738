import { functions } from '../../firebase';

export async function activateWatch(watchId, sid) {
  try {
    const res = await functions.httpsCallable('activateWatchId')({
      watchId,
      sid,
    });
    const d = res.data;
    if (d.success) {
      return [d.watchId, null];
    }
    return [null, d.error.msg];
  } catch (e) {
    return [null, e.message];
  }
}

export async function createWatch(newDeviceId) {
  try {
    const res = await functions.httpsCallable('createWatchId')({
      watchId: newDeviceId,
    });
    const d = res.data;
    if (d.success) {
      return [d.watchId, null];
    }
    return [null, d.error.msg];
  } catch (e) {
    return [null, e.message];
  }
}

export async function deactivateWatch(watchId) {
  try {
    const res = await functions.httpsCallable('deactivateWatchId')({ watchId });
    const d = res.data;
    if (d.success) {
      return [`Watch id ${watchId} deactivated`, null];
    }
    return [null, d.error.msg];
  } catch (e) {
    return [null, e.message];
  }
}

export async function swapWatch(sid, newWatchId) {
  try {
    const res = await functions.httpsCallable('swapWatchId')({
      sid,
      newWatchId,
    });
    const d = res.data;
    if (d.success) {
      return [d.newWatchId, null];
    }
    return [null, d.error.msg];
  } catch (e) {
    return [null, e.message];
  }
}
