import React from 'react';
import AccountSecurityCard from './AccountSecurityCard';
import DailyEmailCard from './DailyEmailCard';

function AccountSettings() {
  return (
    <div className="flex flex-col w-full">
      <DailyEmailCard />
      <AccountSecurityCard />
    </div>
  );
}

export default AccountSettings;
