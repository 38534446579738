export const FETCH_GROUP_INFO_START = 'FETCH_GROUP_INFO_START';
export const FETCH_GROUP_INFO_SUCCESS = 'FETCH_GROUP_INFO_SUCCESS';
export const FETCH_GROUP_INFO_FAIL = 'FETCH_GROUP_INFO_FAIL';

export function fetchGroupInfoStart() {
  return { type: FETCH_GROUP_INFO_START };
}
export function fetchGroupInfoSuccess(obj) {
  return { type: FETCH_GROUP_INFO_SUCCESS, obj };
}
export function fetchGroupInfoFail() {
  return { type: FETCH_GROUP_INFO_FAIL };
}
