import { firestore } from '../../firebase';

async function updateStudyIdDoc(sid, updateObj) {
  try {
    const code = updateObj?.code ?? null;

    if (code && code.length !== 6) {
      throw new Error('Code must be 6 characters long');
    }

    await firestore
      .collection('study_id')
      .doc(sid)
      .update({ ...updateObj });

    return [true, null];
  } catch (e) {
    console.log(e);
    return [null, e.message];
  }
}

export { updateStudyIdDoc };
