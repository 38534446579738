import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  SortColumnIndicator,
  LoadingRows,
  NoRows,
  TextColumnFilterSM,
  DropDownGropSelect,
  DropdownAvailSelect,
} from '../UI/TableParts';
import GroupLabel from '../UI/GroupLabel';
import DataAvailBar from '../UI/DataAvailBar';
import { AddNoteModal, AddBatchNotesModal } from './AddNoteModals';
import AggregateDataAvail from './AggregateDataAvail';

function DaysEnrolledCell({ rowData }) {
  const { subject_study_days, extended_study_days, activation_date } = rowData;
  const study_days = extended_study_days || subject_study_days;

  let enrolled_days = null;
  if (activation_date) {
    const dt = new Date() - new Date(activation_date);
    enrolled_days = Math.floor(dt / (1000 * 60 * 60 * 24));
  }

  let show_complete = false;
  if (enrolled_days && study_days) {
    if (enrolled_days - study_days >= 1) {
      show_complete = true;
    }
  }

  const display_text = `${enrolled_days !== null ? enrolled_days : '-'}/${study_days || '-'}`;

  let sid_new = false;
  if (enrolled_days !== null && enrolled_days <= 1) {
    sid_new = true;
  }

  return (
    <div className="flex flex-row">
      {show_complete ? (
        <div className="gap-1 font-semibold badge badge-warning badge-md">
          <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z"
              clipRule="evenodd"
            />
          </svg>
          {display_text}
        </div>
      ) : (
        <div className="flex flex-row items-center">
          {display_text}
          {sid_new ? <div className="p-2 ml-2 font-semibold badge badge-success">NEW</div> : null}
        </div>
      )}
    </div>
  );
}

function ActiveSidTable() {
  const tableData = useSelector((state) => state.tableData.home_table_data);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  const addNoteRef = useRef();

  const columns = useMemo(
    () => [
      {
        Header: 'Study ID',
        id: 'sid',
        accessor: 'sid',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilterSM,
        ccn: 'sm:w-min w-full',
      },
      {
        Header: 'Group',
        id: 'group',
        accessor: 'group',
        Cell: ({ row: { original } }) => <GroupLabel groupValue={original.group} />,
        Filter: DropDownGropSelect,
        filter: 'equals',
        ccn: 'sm:w-min w-full',
      },
      {
        Header: 'Watch',
        id: 'watch_id',
        accessor: 'watch_id',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilterSM,
        ccn: 'sm:w-min w-full',
      },
      {
        Header: 'days Enrolled',
        id: 'days_enrolled',
        accessor: 'activation_date',
        ccn: 'sm:w-min w-full',
        Cell: ({ row: { original } }) => <DaysEnrolledCell rowData={original} />,
      },
      {
        Header: 'Watch Avail.',
        id: 'watch_avail',
        accessor: 'bb_72h_size_avail',
        ccn: 'sm:w-min w-full',
        Cell: ({ row: { original } }) => <DataAvailBar percent={original.bb_72h_size_avail} />,
        filter: 'between',
        Filter: DropdownAvailSelect,
      },
      {
        Header: 'Temp Avail.',
        id: 'temp_avail',
        accessor: (d) => `${d.mtemp_72h_size_avail}%`,
        ccn: 'sm:w-8 w-full',
      },
      {
        Header: '',
        id: 'action',
        accessor: 'sid',
        disableSortBy: true,
        ccn: 'sm:w-8 w-full',
        Cell: ({ row: { original } }) => (
          <div className="flex w-full gap-1">
            <label
              htmlFor="add-note-modal"
              className="w-1/3 btn sm:btn-ghost btn-outline btn-sm sm:w-min"
              type="button"
              onClick={() => {
                addNoteRef.current.updateSid(original.sid);
              }}
            >
              <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                />
              </svg>
            </label>
            <Link to={`/dashboard/${original.sid}`} className="flex-grow">
              <button className="w-full btn sm:btn-ghost btn-outline btn-sm sm:w-min" type="button">
                <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  const responsive = true;
  const loading = false;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    { columns, data: tableData },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  // const onChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value || undefined);
  // }, 200);

  return (
    <div className="w-full overflow-x-auto">
      <AddNoteModal ref={addNoteRef} />
      <AddBatchNotesModal />
      <AggregateDataAvail />
      <table {...getTableProps()} className="table w-full mb-12">
        {isAdmin ? (
          <thead>
            <tr>
              <th colSpan={columns.length} className="text-base font-extrabold text-gray-500">
                <label htmlFor="add-batch-notes-modal" className="flex gap-2 btn btn-sm w-fit" type="button">
                  <div className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path
                        fillRule="evenodd"
                        d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path
                        fillRule="evenodd"
                        d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  batch add note
                </label>
              </th>
            </tr>
          </thead>
        ) : null}

        <thead className={classNames({ 'hidden sm:table-header-group': responsive })}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classNames(`${column.ccn}`, { 'sm:table-cell hidden': column?.smHidden })}
                >
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      {column.render('Header')}
                      <SortColumnIndicator column={column} />
                    </div>
                    <div className="pt-1">{column.filter ? column.render('Filter') : null}</div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <LoadingRows numRows={4} numCells={columns.length} />
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={classNames('', {
                    'not-last:border-b-[5px] sm:not-last:border-b-[1px] border-gray-100 bg-white':
                      responsive && !row.column?.smHidden,
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={classNames(`${cell.column.ccn}`, {
                        'sm:table-cell flex space-between border-b-0': responsive && !cell.column?.smHidden,
                        'sm:table-cell hidden': cell.column?.smHidden,
                        'flex justify-center': cell.column.Header === '',
                      })}
                      {...cell.getCellProps()}
                    >
                      <p
                        className={classNames('font-medium text-gray-400', {
                          'flex sm:hidden': responsive,
                          hidden: !responsive || cell.column.Header === '',
                        })}
                      >
                        {cell.column.Header}
                      </p>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
          {rows.length === 0 && !loading ? <NoRows numCells={columns.length} /> : null}
        </tbody>
      </table>
    </div>
  );
}

export default ActiveSidTable;
