import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import GroupLabel from '../UI/GroupLabel';
import updateRewardProjectCodeByGroup from '../../Api/HttpCalls/updateRewardProjectCodeByGroup';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function GroupInfoCard({ groupInfoObject }) {
  const [loading, setLoading] = useState(false);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  const showSuccess = (msg) => {
    toast.success(msg);
  };

  const showError = (errMsg) => {
    toast.error(errMsg);
  };

  const updateRewardProjectCode = async (group, setDefault) => {
    setLoading(true);
    const res = await updateRewardProjectCodeByGroup(group, setDefault);
    setLoading(false);
    if (res[0]) {
      const string = String(res[0]);
      showSuccess(`updated : ${string}`);
    } else {
      showError(res[1]);
    }
  };

  const renderSetProjectRewardRow = () => (
    <>
      <p className="justify-center col-span-1">Project reward</p>
      {loading ? (
        <div className="flex justify-center h-full col-span-1">
          <LoadingSpiner />
        </div>
      ) : (
        <div className="flex col-span-1 gap-1">
          <button
            type="button"
            className="btn btn-xs"
            disabled={loading}
            onClick={() => {
              updateRewardProjectCode(groupInfoObject.value, false);
            }}
          >
            <p className="text-sm">SET</p>
          </button>
          <button
            type="button"
            className="btn btn-xs"
            disabled={loading}
            onClick={() => {
              updateRewardProjectCode(groupInfoObject.value, true);
            }}
          >
            <p className="text-sm">default</p>
          </button>
        </div>
      )}
    </>
  );

  return (
    <div className="relative w-full col-span-1 py-1 bg-white border-2 border-white rounded-lg hover:border-0 group">
      <div className="grid w-full grid-cols-2 gap-2 px-3 pt-2 rounded-t-lg rounded-b-lg border-primary-400 group-hover:border-t-2 group-hover:border-l-2 group-hover:border-r-2 group-hover:rounded-b-none">
        <p className="col-span-1">Label</p>
        <div className="flex items-center col-span-1">
          <GroupLabel groupValue={groupInfoObject?.value} />
        </div>
        <p className="col-span-1">Project code</p>
        <p className="col-span-1 font-bold">{groupInfoObject?.projectCode ?? '-'}</p>
        <p className="col-span-1">Active</p>
        <p className="col-span-1 font-bold">{groupInfoObject?.group_active.toString() ?? '-'}</p>
        <p className="col-span-1">Study duration</p>
        <p className="col-span-1 font-bold">{groupInfoObject?.subject_study_days ?? '-'}</p>
        {isAdmin ? renderSetProjectRewardRow() : null}
        <div className="self-center w-12 h-1 col-span-2 my-1.5 rounded-lg bg-gray-200 group-hover:hidden" />
      </div>
      <div className="absolute z-50 hidden w-full grid-cols-2 px-3 pt-6 bg-white border-b-2 border-l-2 border-r-2 rounded-b-lg border-primary-400 group-hover:grid">
        <p className="col-span-2 pb-2 font-medium text-center text-gray-400">Follow up schedule</p>
        <table className="col-span-2 mb-4 table-auto">
          <thead>
            <tr>
              <th>Type</th>
              <th>in Hours</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {Array.isArray(groupInfoObject?.follow_up_types)
              ? groupInfoObject.follow_up_types.map((obj) => (
                  <tr key={obj.type}>
                    <td>{obj?.type}</td>
                    <td>{obj?.inHours}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

GroupInfoCard.propTypes = {
  groupInfoObject: PropTypes.object,
};

GroupInfoCard.defaultProps = {
  groupInfoObject: {},
};

export default GroupInfoCard;
