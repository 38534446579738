import firebase from 'firebase/compat/app';

export const LOG_OUT = 'LOG_OUT';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const REQUEST_TOKEN_SUCCESS = 'REQUEST_TOKEN_SUCCESS';
export const REQUEST_TOKEN_ERROR = 'REQUEST_TOKEN_ERROR';
export const LOGIN_WITH_TOKEN_SUCCESS = 'LOGIN_WITH_TOKEN_SUCCESS';
export const LOGIN_WITH_TOKEN_ERROR = 'LOGIN_WITH_TOKEN_ERROR';
export const LOGIN_SCREEN_MOUNTED = 'LOGIN_SCREEN_MOUNTED';
export const UPDATE_GROUP_ACCESS = 'UPDATE_GROUP_ACCESS';

export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
// export const LOGIN_WITH_EMAIL_PASSWORD = 'LOGIN_WITH_EMAIL_PASSWORD';
// export const LOGIN_WITH_EMAIL_PASSWORD_SUCCESS = 'LOGIN_WITH_EMAIL_PASSWORD_SUCCESS';
// export const LOGIN_WITH_EMAIL_PASSWORD_ERROR = 'LOGIN_WITH_EMAIL_PASSWORD_ERROR';
export const FIREBASE_AUTH_STATE_CHANGED = 'FIREBASE_AUTH_STATE_CHANGED';
export const REQUEST_DASHBOARDIDINFO = 'REQUEST_DASHBOARDIDINFO';
export const FETCH_DASHBOARDIDINFO = 'FETCH_DASHBOARDIDINFO';
export const FETCH_DASHBOARDIDINFO_SUCCESS = 'FETCH_DASHBOARDIDINFO_SUCCESS';
export const FETCH_DASHBOARDIDINFO_ERROR = 'FETCH_DASHBOARDIDINFO_ERROR';

export function logout() {
  firebase.auth().signOut();
  return { type: LOG_OUT };
}

export function requestToken(id, code) {
  return { type: REQUEST_TOKEN, id, code };
}

export function requestTokenSuccess(id, token, roles, projectAccess, studyGroupAccess) {
  return {
    type: REQUEST_TOKEN_SUCCESS,
    id,
    token,
    roles,
    projectAccess,
    studyGroupAccess,
  };
}

export function requestTokenError(error) {
  return { type: REQUEST_TOKEN_ERROR, error };
}

export function loginWithTokenSuccess(id, roles, projectAccess, studyGroupAccess) {
  return {
    type: LOGIN_WITH_TOKEN_SUCCESS,
    id,
    roles,
    projectAccess,
    studyGroupAccess,
  };
}

export function loginWithTokenError(error) {
  return { type: LOGIN_WITH_TOKEN_ERROR, error };
}

export function loginScreenMounted() {
  return { type: LOGIN_SCREEN_MOUNTED };
}

export function updateGroupAccess(groupList) {
  return { type: UPDATE_GROUP_ACCESS, groupList };
}

// export function loginWithEmailPassword(email, password) {
//   return { type: LOGIN_WITH_EMAIL_PASSWORD, email, password };
// }

// export function loginWithEmailPasswordSuccess(id, email) {
//   return { type: LOGIN_WITH_EMAIL_PASSWORD_SUCCESS, id, email };
// }

// export function loginWithEmailPasswordError(error) {
//   return { type: LOGIN_WITH_EMAIL_PASSWORD_ERROR, error };
// }

export function firebaseAuthStateChanged(uid, email) {
  return { type: FIREBASE_AUTH_STATE_CHANGED, uid, email };
}

export function requestDashboardIdInfo() {
  return { type: REQUEST_DASHBOARDIDINFO };
}

export function fetchDashboardIdInfo() {
  return { type: FETCH_DASHBOARDIDINFO };
}

export function fetchDashboardIdInfoSuccess(id, roles, projectAccess, studyGroupAccess) {
  return { type: FETCH_DASHBOARDIDINFO_SUCCESS, id, roles, projectAccess, studyGroupAccess };
}

export function fetchDashboardIdInfoError(error) {
  return { ext: FETCH_DASHBOARDIDINFO_ERROR, error };
}

export function resetPasswordError(error) {
  return { type: RESET_PASSWORD_ERROR, error };
}
