import React from 'react';
import PropTypes from 'prop-types';

function Topbar({ onToggleMenu }) {
  return (
    <div className="layout-topbar clearfix">
      <button type="button" className="btn btn-ghost btn-sm btn-square" onClick={onToggleMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z" />
        </svg>
      </button>
    </div>
  );
}

Topbar.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
};

export default Topbar;
