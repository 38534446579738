import React, { useContext, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router';
import { Tab } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmailAndPassword from '../Components/authentication/EmailAndPassword';
import IDAndCode from '../Components/authentication/IDAndCode';
import { loginScreenMounted } from '../Actions';
import { AuthContext } from '../AuthProvider';

function Login({ history }) {
  const dispatch = useDispatch();

  const authError = useSelector((state) => state.auth.auth_error);

  useEffect(() => {
    dispatch(loginScreenMounted());
  }, [dispatch]);

  const selectedTabNames = 'text-primary-500 bg-white py-2.5 rounded-lg';
  const unselectedTabNames = 'bg-transparent text-gray-500 py-2.5 rounded-lg hover:bg-white/[0.1] hover:text-white';

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const dt = new Date() - new Date(authError?.ts);
    if (dt < 1000 && authError.message) {
      toast.error(authError.message);
    }
  }, [authError]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex flex-row items-center justify-center h-full md:items-start dark">
      <div className="basis-11/12 md:pt-32 md:basis-96 h-5/6">
        <Tab.Group>
          <Tab.List className="grid w-full grid-cols-2 gap-1 p-1 pb-1 bg-white/[0.05] rounded-xl">
            <Tab className={({ selected }) => (selected ? selectedTabNames : unselectedTabNames)}>Email</Tab>
            <Tab className={({ selected }) => (selected ? selectedTabNames : unselectedTabNames)}>ID & Code</Tab>
          </Tab.List>
          <Tab.Panels className="">
            <Tab.Panel>
              <EmailAndPassword />
            </Tab.Panel>
            <Tab.Panel>
              <IDAndCode />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default withRouter(Login);
