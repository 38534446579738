import { all, put, takeLatest } from 'redux-saga/effects';
import {
  // LOGIN_WITH_EMAIL_PASSWORD,
  // LOGIN_WITH_EMAIL_PASSWORD_SUCCESS,
  // LOGIN_WITH_EMAIL_PASSWORD_ERROR,
  FIREBASE_AUTH_STATE_CHANGED,
  REQUEST_DASHBOARDIDINFO,
  FETCH_DASHBOARDIDINFO,
  FETCH_DASHBOARDIDINFO_SUCCESS,
  FETCH_DASHBOARDIDINFO_ERROR,
} from '../Actions';
// import { authWithEmailPassword } from '../Api/Authentication/AuthenticationWithEmail';
import requestDashboardIDInfo from '../Api/HttpCalls/requestDashboardIDInfo';

// export function* _loginWithEmailPassword(action) {
//   const res = yield authWithEmailPassword(action.email, action.password);
//   if (res[0]) {
//     yield put({
//       type: LOGIN_WITH_EMAIL_PASSWORD_SUCCESS,
//       id: res[0],
//       email: action.email,
//     });
//   } else {
//     yield put({
//       type: LOGIN_WITH_EMAIL_PASSWORD_ERROR,
//       error: res[1],
//     });
//   }
// }

// export function* loginWithEmailPassword() {
//   yield takeLatest(LOGIN_WITH_EMAIL_PASSWORD, _loginWithEmailPassword);
// }

export function* _fetchDashboardIdInfo(action) {
  yield put({ type: FETCH_DASHBOARDIDINFO });
  const res = yield requestDashboardIDInfo();
  if (res[1]) {
    yield put({ type: FETCH_DASHBOARDIDINFO_ERROR, error: res[1] });
  } else {
    const { roles, projectAccess, studyGroupAccess, mfaEnrollmentRequired } = res[0];
    yield put({
      type: FETCH_DASHBOARDIDINFO_SUCCESS,
      id: action.uid,
      email: action.email,
      roles,
      projectAccess,
      studyGroupAccess,
      mfaEnrollmentRequired,
    });
  }
}

export function* fetchDashboardIdInfo() {
  yield takeLatest(FIREBASE_AUTH_STATE_CHANGED, _fetchDashboardIdInfo);
  yield takeLatest(REQUEST_DASHBOARDIDINFO, _fetchDashboardIdInfo);
  // yield takeLatest(LOGIN_WITH_EMAIL_PASSWORD_SUCCESS, _fetchDashboardIdInfo);
}

export function* EmailAuthSagas() {
  yield all([
    // loginWithEmailPassword(),
    fetchDashboardIdInfo(),
  ]);
}
