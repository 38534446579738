import React, { useEffect } from 'react';
import { useHistory, Route, useRouteMatch, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PersonDashboard from './PersonDashboard';
import './dashboard.scss';
import TableLoadingIndicator from '../UI/TableLoadingIndicator';
import { clearPersonData } from '../../Actions';
import usePrevious from '../hooks/usePrevious';
import AggregateDataAvailSubscription from '../../Api/Hooks/AggregateDataAvailSubscription';
import ActiveSidTable from './ActiveSidTable';

function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const fetching = useSelector((state) => state.tableData.fetching);
  const { sid, loading, error } = useSelector((state) => state.tableData.offboard_status);
  const wasLoading = usePrevious(loading);

  const showSuccess = (displaySid) => {
    toast.success(`${displaySid} marked as completed!`);
  };

  const showError = (errorMsg) => {
    toast.error(errorMsg);
  };

  useEffect(() => {
    if (wasLoading && !loading) {
      if (error === null) {
        showSuccess(sid);
      } else {
        showError(error ?? 'unknown error');
      }
    }
  }, [loading, wasLoading, sid, error]);

  const handleReturnToDashboard = () => {
    history.goBack();
    dispatch(clearPersonData());
  };

  return (
    <div className="w-full">
      <AggregateDataAvailSubscription />
      {fetching ? (
        <TableLoadingIndicator />
      ) : (
        <Switch>
          <Route exact path={path}>
            <ActiveSidTable />
          </Route>
          <Route path={`${path}/:id`}>
            <PersonDashboard onBackButtonClicked={handleReturnToDashboard} />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default Dashboard;
