export const START_CREATE_IDS = 'START_CREATE_IDS';
export const CREATE_IDS_SUCCESS = 'CREATE_IDS_SUCCESS';
export const CREATE_IDS_FAIL = 'CREATE_IDS_FAIL';

export function createIds(studyIdList, activationList) {
  return {
    type: START_CREATE_IDS,
    studyIdList,
    activationList,
  };
}

export function createIdsSuccess(studyIdList, activationList) {
  return {
    type: CREATE_IDS_SUCCESS,
    studyIdList,
    activationList,
  };
}

export function createIdsFailure(error) {
  return {
    type: CREATE_IDS_FAIL,
    error,
  };
}
