import { put, all, takeLeading } from 'redux-saga/effects';
import {
  FETCH_SELECTED_SID_DATA_START,
  FETCH_SELECTED_SID_DATA_SUCCESS,
  FETCH_SELECTED_SID_DATA_ERROR,
} from '../Actions';
import processSidDataCompleted from '../Api/Helper/ProcessSidDataCompleted';
import fetchSidDataCompleted from '../Api/HttpCalls/FetchSidDataCompleted';

export function* fetchSelectedSidData(action) {
  const { sid } = action;
  const res = yield fetchSidDataCompleted(sid);

  if (!res.success) {
    yield put({ type: FETCH_SELECTED_SID_DATA_ERROR, error: res.error });
  } else {
    yield put({
      type: FETCH_SELECTED_SID_DATA_SUCCESS,
      data: processSidDataCompleted(res.data),
    });
  }
}

export function* fetchSelectedSidDataSaga() {
  yield takeLeading(FETCH_SELECTED_SID_DATA_START, fetchSelectedSidData);
}

export function* completedStudyIdSagas() {
  yield all([fetchSelectedSidDataSaga()]);
}
