import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '../../firebase';
import { fetchGroupInfoStart, fetchGroupInfoSuccess, fetchGroupInfoFail, updateGroupAccess } from '../../Actions';

const arraysEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

function GroupInfoSubscription() {
  const dispatch = useDispatch();
  const projectAccess = useSelector((state) => state.auth.projectAccess);
  const studyGroupAccess = useSelector((state) => state.auth.studyGroupAccess);

  useEffect(() => {
    dispatch(fetchGroupInfoStart());
    const sub = firestore
      .collection('study_group_info')
      .where('projectCode', 'in', projectAccess)
      .onSnapshot(
        (docs) => {
          const data = docs.docs.map((doc) => doc.data());
          if (data.length > 0) {
            dispatch(fetchGroupInfoSuccess(data));
            const groupValueList = data.map((e) => e.value);
            if (!arraysEqual(groupValueList, studyGroupAccess)) {
              dispatch(updateGroupAccess(groupValueList));
            }
          } else {
            dispatch(fetchGroupInfoSuccess([]));
          }
        },
        (error) => {
          console.log(error);
          dispatch(fetchGroupInfoFail());
        }
      );
    return sub;
  }, [dispatch, projectAccess, studyGroupAccess]);

  return null;
}

export default GroupInfoSubscription;
