import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 4,
  whitelist: ['auth'],
  // blacklist: [],
  storage,
};

export default (rootReducer, rootSaga) => {
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(sagaMiddleware);
  const store = createStore(persistedReducer, composeWithDevTools(middleware));
  const sagasManager = sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, sagasManager, sagaMiddleware, persistor };
};
