import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firestore } from '../../firebase';

const useWatchId = () => {
  const [watchIds, setWatchIds] = useState([]);
  const [loading, setLoading] = useState(true);

  const idProjectAccess = useSelector((state) => state.auth.projectAccess);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  useEffect(() => {
    let ref;

    if (isAdmin) {
      ref = firestore.collection('watch_id').orderBy('watch_id');
    } else {
      ref = firestore.collection('watch_id').where('projectCode', 'in', idProjectAccess).orderBy('watch_id');
    }

    const sub = ref.onSnapshot(
      (docs) => {
        if (!docs.empty) {
          const watchIdData = [];
          docs.forEach((e) => {
            const d = e.data();
            const obj = {
              watch_id: d.watch_id,
              owner_sid: d.owner_sid,
              activation_date: d.activation_date?.toDate(),
              projectCode: d.projectCode ?? null,
              watch_status: d.watch_status ?? 'unkown',
            };
            watchIdData.push(obj);
          });
          setWatchIds(watchIdData);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return sub;
  }, [idProjectAccess, isAdmin]);

  return { watchIds, loading, isAdmin, idProjectAccess };
};

export default useWatchId;
