/**
 * fetch study id data from firestore by group the dashboard id have access to.
 * @function StudyIdSubscription
 * @todo where in queery have limit of 10, if it breaks make it chunck;
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '../../firebase';
import { updateStudyId } from '../../Actions';

function StudyIdSubscription() {
  const dispatch = useDispatch();
  const idGroupAccess = useSelector((state) => state.auth.studyGroupAccess);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  useEffect(() => {
    let arrGroupsToFetch = [];
    if (isAdmin) {
      arrGroupsToFetch = ['none'].concat(idGroupAccess);
    } else {
      arrGroupsToFetch = idGroupAccess;
    }

    const sub = firestore
      .collection('study_id')
      .where('group', 'in', arrGroupsToFetch)
      .limit(500)
      .onSnapshot(
        (docs) => {
          const studyIdData = [];
          docs.forEach((doc) => {
            const activationDate = doc.data()?.activation_date?.toDate();
            studyIdData.push({
              ...doc.data(),
              activation_date: activationDate,
            });
          });
          dispatch(updateStudyId(studyIdData));
        },
        (error) => {
          console.log('error fetching watch id data', error);
        }
      );
    return sub;
  }, [dispatch, idGroupAccess, isAdmin]);

  return null;
}

export default StudyIdSubscription;
