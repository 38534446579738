import produce from 'immer';
import { UPDATE_WATCH_ID, UPDATE_STUDY_ID } from '../Actions';

export const INITIAL_STATE = {
  // watch id
  watch_id_data: [],
  // study id
  study_id_data: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_WATCH_ID:
        // console.log(action.watch_id_data);
        draft.watch_id_data = action.watch_id_data;
        break;
      case UPDATE_STUDY_ID:
        // console.log(action.study_id_data);
        draft.study_id_data = action.study_id_data;
        break;
      default:
        break;
    }
  });
