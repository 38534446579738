import React, { useEffect, useState, useCallback } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import produce from 'immer';
import useIsMobile from '../../hooks/useIsMobile';
import { deriveData, getxAxis } from './DeriveHeatMapData';

function HeatMapHourly() {
  const boxByHour = useSelector((state) => state.personData.boxByHour);
  const fetching = useSelector((state) => state.personData.fetching);
  const { isMobile } = useIsMobile();
  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        distributed: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              name: 'Missing',
              color: '#535c68',
            },
            {
              from: 1,
              to: 1,
              name: 'Has data',
              color: '#33d9b2',
            },
            {
              from: 3,
              to: 3,
              name: 'Now',
              color: '#ffdd59',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['-'],
    },
  });

  const [chartData, setChartData] = useImmer([
    { name: '-', data: new Array(24).fill(0) },
    { name: '-', data: new Array(24).fill(0) },
    { name: '-', data: new Array(24).fill(0) },
  ]);

  const updateX = useCallback((arr) => {
    setChartOptions(
      produce((draft) => {
        draft.xaxis.categories = arr;
      })
    );
  }, []);

  useEffect(() => {
    if (boxByHour.length > 10) {
      const xAxisArr = getxAxis(isMobile);
      updateX(xAxisArr);
      const d = deriveData(isMobile, boxByHour);
      setChartData(d);
    }
  }, [boxByHour, isMobile, setChartData, updateX]);

  useEffect(() => {
    if (fetching) {
      setChartData([
        { name: '-', data: new Array(24).fill(0) },
        { name: '-', data: new Array(24).fill(0) },
        { name: '-', data: new Array(24).fill(0) },
      ]);
    }
  }, [fetching, setChartData]);

  return <Chart type="heatmap" options={chartOptions} series={chartData} height={250} />;
}

export default HeatMapHourly;
