import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import BaseTableV2 from '../UI/BaseTableV2';
import StudyIdSubscription from '../../Api/Hooks/StudyIdSubscription';
import GroupLabel from '../UI/GroupLabel';
import { EditableGroupLableCell, EditableDropDownCell, TextColumnFilterSM, DropDownGropSelect } from '../UI/TableParts';
import { updateStudyIdDoc } from '../../Api/Set/StudyIdSets';
import CreateStudyId from './CreateStudyId';
import DeleteDialog from './DeleteDialog';

const EditableCell = ({
  value: initialValue,
  row: { index, id: rowId, original },
  column: { id },
  onDataUpdate,
  editableRowId,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    if (e.target.value.length > 6) return;
    setValue(e.target.value);
  };

  const onBlur = () => {
    onDataUpdate(index, id, value, original);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editableRowId === rowId) {
    return <input className="input input-bordered max-w-fit" value={value} onChange={onChange} onBlur={onBlur} />;
  }

  return initialValue;
};

const GroupCell = ({
  value: initialValue,
  row: { index, id: rowId, original },
  column: { id },
  onDataUpdate,
  editableRowId,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (val) => {
    setValue(val);
    onDataUpdate(index, id, val, original);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editableRowId === rowId) {
    return <EditableGroupLableCell currentValue={value} onChange={onChange} />;
  }

  return <GroupLabel groupValue={initialValue} />;
};

const ToggleCell = ({
  value: initialValue,
  row: { index, id: rowId, original },
  column: { id },
  onDataUpdate,
  editableRowId,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (option) => {
    setValue(option.value);
    onDataUpdate(index, id, option.value, original);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (editableRowId === rowId) {
    return (
      <EditableDropDownCell
        currentValue={value}
        onChange={onChange}
        options={[
          {
            label: 'TRUE',
            value: true,
          },
          {
            label: 'FALSE',
            value: false,
          },
        ]}
      />
    );
  }

  return initialValue === true ? <p>TRUE</p> : <p className="text-gray-200">FALSE</p>;
};

const EditOffButton = ({ editSuccess, onClick }) => {
  if (editSuccess) {
    return (
      <button className="basis-1/2 btn btn-success btn-sm sm:w-min" type="button" onClick={onClick}>
        <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    );
  }

  return (
    <button className="basis-1/2 btn sm:btn-ghost btn-sm sm:w-min" type="button" onClick={onClick}>
      <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
};

function StudyIds() {
  const data = useSelector((state) => state.sidwatchid.study_id_data);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  const [editingRowId, setEditingRowId] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);
  const [sidToDelete, setSidToDelete] = useState('');

  const columns = useMemo(
    () => [
      {
        Header: 'Study ID',
        accessor: 'sid',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilterSM,
      },
      {
        Header: 'Group',
        accessor: 'group',
        disableSortBy: true,
        Cell: GroupCell,
        Filter: DropDownGropSelect,
        filter: 'equals',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ToggleCell,
      },
      {
        Header: 'Date Activated',
        accessor: (d) => (d?.activation_date ? moment(d?.activation_date).format('ll') : ''),
      },
      {
        Header: 'Watch ID',
        accessor: (d) => {
          const str = d?.watch_id ?? '';
          return str.replace(/^0+/, '');
        },
      },
      {
        Header: 'Code',
        accessor: 'code',
        disableSortBy: true,
        Cell: EditableCell,
      },
      {
        Header: '',
        accessor: 'sid',
        id: 'actions',
        disableSortBy: true,
        ccn: 'sm:w-8 w-full',
        show: isAdmin,
        Cell: ({ row }) =>
          isAdmin ? (
            <div className="flex flex-row w-full gap-1 ">
              {editingRowId !== row.id ? (
                <button
                  className="basis-1/2 btn sm:btn-ghost btn-outline btn-sm sm:w-min"
                  type="button"
                  onClick={() => {
                    setEditingRowId(row.id);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
                  </svg>
                </button>
              ) : (
                <EditOffButton
                  editSuccess={editSuccess}
                  onClick={() => {
                    setEditSuccess(false);
                    setEditingRowId(null);
                  }}
                />
              )}
              <label
                htmlFor="del-sid-modal"
                className="basis-1/2 btn btn-warning btn-sm sm:w-min"
                type="button"
                onClick={() => {
                  setSidToDelete(row.original.sid);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </label>
            </div>
          ) : null,
      },
    ],
    [editSuccess, editingRowId, isAdmin]
  );

  const onEditableRowUpdate = async (rowIndex, columnId, value, original) => {
    const updateObj = {};
    if (columnId === 'group' && value !== original.group) updateObj.group = value;
    if (columnId === 'active' && value !== original.active) updateObj.active = value;
    if (columnId === 'code' && value !== original.code) updateObj.code = value;
    const res = await updateStudyIdDoc(original.sid, updateObj);
    if (res[0]) setEditSuccess(true);
    if (res[1]) toast.error(res[1]);
  };

  return (
    <div className="w-full">
      <StudyIdSubscription />
      <DeleteDialog sid={sidToDelete} />
      <BaseTableV2
        tableData={data}
        columns={columns}
        editableRowId={editingRowId}
        onDataUpdate={onEditableRowUpdate}
        headerElement={isAdmin ? <CreateStudyId /> : null}
      />
    </div>
  );
}

export default StudyIds;
