import { arrayUnion, arrayRemove } from 'firebase/firestore';
import { firestore } from '../../firebase';

async function setBasestationProjectCode(basestationId, newProjectCode, previousProjectCode) {
  if (newProjectCode === previousProjectCode) return [true, null];
  const ref = firestore.collection('basestation').doc(basestationId);
  const newBasestationLog = {
    ts: new Date(),
    type: 'basestation-project-change',
    desc: `Project code changed from ${previousProjectCode} to ${newProjectCode}`,
  };
  try {
    await ref.update({
      projectCode: newProjectCode,
      basestation_log: arrayUnion(newBasestationLog),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

async function setBasestationStatus(basestationId, newStatus, previousStatus) {
  if (newStatus === previousStatus) return [true, null];
  const ref = firestore.collection('basestation').doc(basestationId);
  const newBasestationLog = {
    ts: new Date(),
    type: 'basestation-status-change',
    desc: `Status changed from ${previousStatus} to ${newStatus}`,
  };
  try {
    await ref.update({
      basestation_status: newStatus,
      basestation_log: arrayUnion(newBasestationLog),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

async function removeBasestationLog(basestationId, log) {
  const ref = firestore.collection('basestation').doc(basestationId);
  try {
    await ref.update({
      basestation_log: arrayRemove(log),
    });
    return [true, null];
  } catch (error) {
    return [false, error.message];
  }
}

export { setBasestationProjectCode, setBasestationStatus, removeBasestationLog };
