import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import DashboardAccountCard from './DashboardAccountCard';
import { DashboardIdContext } from '../../Api/Context/DashboardIdProvider';

function DevManageDashboardAccount() {
  const { dashboardIds } = useContext(DashboardIdContext);
  const [showCreateCard, setShowCreateCard] = useState(false);

  const onAccountCreated = () => {
    setShowCreateCard(false);
  };

  const showError = (errorMsg) => {
    toast.error(errorMsg);
  };

  const showMsg = (msg) => {
    toast.success(msg);
  };

  return (
    <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-3 gap-2">
      {dashboardIds.map((e) => (
        <DashboardAccountCard
          key={e.uid}
          accountObject={e}
          onSuccessMsg={(msg) => showMsg(msg)}
          onErrorMsg={(msg) => showError(msg)}
        />
      ))}
      {showCreateCard ? (
        <DashboardAccountCard
          onSuccessMsg={(msg) => showMsg(msg)}
          onErrorMsg={(msg) => showError(msg)}
          createMode
          onCreateCancel={() => setShowCreateCard(false)}
          onCreateSuccess={onAccountCreated}
        />
      ) : (
        <div className="flex items-center justify-center col-span-1">
          <button
            className="w-16 h-16 my-16 text-center bg-indigo-500 rounded-lg "
            type="button"
            onClick={() => {
              setShowCreateCard(true);
            }}
          >
            NEW
          </button>
        </div>
      )}
    </div>
  );
}

export default DevManageDashboardAccount;
