import React, { useState } from 'react';
import JSONPretty from 'react-json-pretty';
import DButton from '../UI/DButton';
import { firestore } from '../../firebase';

function DevFirestoreRules() {
  const [requesting, setRequesting] = useState(false);
  const [docPath, setDocPath] = useState('');

  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const onRequest = async () => {
    setRequesting(true);
    setResult('');
    setError('');

    try {
      const res = await firestore.doc(docPath).get();
      if (!res.exists) {
        setResult("Document doesn't exist");
      } else {
        setResult(JSON.stringify(res.data(), null, 2));
      }
    } catch (err) {
      setError(err.message);
    }

    setRequesting(false);
  };

  return (
    <div className="flex flex-col">
      <div className="px-3 py-2 mb-2 bg-white rounded-lg w-96">
        <h4>firestore rules</h4>
        <input
          className="w-full px-3 py-2 my-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
          id="doc-path"
          value={docPath}
          onChange={(e) => setDocPath(e.target.value)}
        />
        <DButton className="w-full" titile="Request" onClick={onRequest} loading={requesting} />
      </div>
      <div className="px-3 py-2 mb-2 bg-white rounded-lg w-96">
        <p>RESULT</p>
        <JSONPretty id="json-pretty" data={result} />
      </div>
      <div className="px-3 py-2 mb-2 bg-white rounded-lg w-96">
        <p>ERROR</p>
        <p className="text-red-400">{error}</p>
      </div>
    </div>
  );
}

export default DevFirestoreRules;
