import React from 'react';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function TableLoadingIndicator() {
  return (
    <div className="flex items-center w-full h-full bg-white rounded-lg">
      <div className="flex items-center h-20 max-w-sm p-4 mx-auto rounded-md">
        <LoadingSpiner className="mr-2" />
        <p className="pl-2 font-bold">Loading ...</p>
      </div>
    </div>
  );
}

export default TableLoadingIndicator;
