import { firestore } from '../../firebase';

async function updateExtendedStudyDays(sid, days) {
  try {
    const ref = firestore.collection('study_id').doc(sid);
    if (!typeof days === 'number' && !days == null) throw new Error('days must be a number');
    await ref.set(
      {
        extended_study_days: days,
      },
      { merge: true }
    );
  } catch (e) {
    console.log(e);
  }
}

export { updateExtendedStudyDays };
