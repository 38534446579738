import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { firestore } from '../../firebase';
import { updateAggregateDataAvail } from '../../Actions';

const getSumByKey = (arr, key) => arr.reduce((acc, cur) => acc + cur[key], 0);

function AggregateDataAvailSubscription() {
  const projectAccess = useSelector((state) => state.auth.projectAccess);
  const dispatch = useDispatch();

  useEffect(() => {
    const sub = firestore
      .collection('study_group_agg_data_avail')
      .where('projectCode', 'in', projectAccess)
      .onSnapshot((docs) => {
        const data = docs.docs.map((doc) => doc.data());

        if (data.length > 0) {
          const numberSubjects = getSumByKey(data, 'sid_count').toFixed(0);
          const aggWatchAvail = (
            (getSumByKey(data, 'agg_bb_count') * 100) /
            getSumByKey(data, 'agg_full_bb_count')
          ).toFixed(2);
          const aggTempAvail = (
            (getSumByKey(data, 'agg_temp_count') * 100) /
            getSumByKey(data, 'agg_full_temp_count')
          ).toFixed(2);

          dispatch(
            updateAggregateDataAvail({
              numberOfGroups: data.length.toFixed(0),
              numberSubjects,
              aggWatchAvail,
              aggTempAvail,
            })
          );
        }
      });
    return sub;
  }, [dispatch, projectAccess]);
  return null;
}

export default AggregateDataAvailSubscription;
