import { firestore, auth } from '../../firebase';

async function updateEmail(email) {
  try {
    const { uid } = auth.currentUser;
    await firestore.collection('dashboard_id_settings').doc(uid).set({ email }, { merge: true });
  } catch (e) {
    console.log(e);
  }
}

async function updateGroup(groupsArr) {
  try {
    const { uid } = auth.currentUser;
    await firestore.collection('dashboard_id_settings').doc(uid).set({ subGroups: groupsArr }, { merge: true });
  } catch (e) {
    console.log(e);
  }
}

async function toggleSubToDailyEmail(isSub) {
  try {
    const { uid } = auth.currentUser;
    await firestore.collection('dashboard_id_settings').doc(uid).set({ dailyEmailSub: isSub }, { merge: true });
  } catch (e) {
    console.log(e);
  }
}

export { updateEmail, updateGroup, toggleSubToDailyEmail };
