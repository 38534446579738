import React, { useEffect, useState, useCallback, useContext } from 'react';
import fetchProjectCodesList from '../../Api/Fetch/FetchProjectCodes';
import ProjectCodeCard from './ProjectCodeCard';
import { DashboardIdContext } from '../../Api/Context/DashboardIdProvider';

function DevProjectCodes() {
  const { dashboardIds, uidList } = useContext(DashboardIdContext);
  const [projectCodes, setProjectCodes] = useState([]);
  const [showCreateCard, setShowCreateCard] = useState(false);

  const fetchProjectCodes = useCallback(async () => {
    const resFetchProjectCodes = await fetchProjectCodesList();
    if (resFetchProjectCodes[0]) {
      setProjectCodes(resFetchProjectCodes[0]);
    } else {
      console.log(resFetchProjectCodes[1]);
    }
  }, []);

  useEffect(() => {
    fetchProjectCodes();
  }, [fetchProjectCodes]);

  // const onChangeSaved = useCallback(async () => {
  //   await fetchProjectCodes();
  // }, [fetchProjectCodes]);

  const onChangeSaved = async () => {
    await fetchProjectCodes();
  };

  return (
    <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-3 gap-2">
      {projectCodes.map((e) => (
        <ProjectCodeCard
          key={e.projectCode}
          projectCode={e.projectCode}
          dashboardIdAccess={e.dashboardIdAccess}
          desc={e.desc}
          allDashboardId={uidList}
          allDashboardIdObjects={dashboardIds}
          onSaved={onChangeSaved}
          createMode={false}
        />
      ))}
      {showCreateCard ? (
        <ProjectCodeCard
          createMode
          allDashboardId={uidList}
          allDashboardIdObjects={dashboardIds}
          onSaved={onChangeSaved}
          onCloseCreateMode={() => setShowCreateCard(false)}
        />
      ) : (
        <div className="flex items-center justify-center col-span-1">
          <button
            className="w-20 h-20 bg-indigo-500 rounded-lg"
            type="button"
            onClick={() => {
              setShowCreateCard(true);
            }}
          >
            ADD
          </button>
        </div>
      )}
    </div>
  );
}

export default DevProjectCodes;
