import { functions } from '../../firebase';

async function requestDashboardIDInfo() {
  try {
    const result = await functions.httpsCallable('requestDashboardIDInfo')({});
    return [result.data, null];
  } catch (e) {
    return [null, e];
  }
}

export default requestDashboardIDInfo;
