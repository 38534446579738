import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { updateEmail, updateGroup, toggleSubToDailyEmail } from '../../Api/Set/AccountSettingsSets';
import GroupLabel from '../UI/GroupLabel';
// import GroupInfoDiv from './GroupInfoDiv';

function DailyEmailCard() {
  const [localEmail, setLocalEmail] = useState('');
  const [emailChnaged, setEmailChanged] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]);

  const [emailUpdating, setEmailUpdating] = useState(false);
  const [subGroupUpdating, setSubGroupUpdating] = useState(false);

  const groupInfoObj = useSelector((state) => state.groupInfo.groupInfoObjects);
  const subStatus = useSelector((state) => state.accountSettings.dailyEmailSub);
  const remoteEmail = useSelector((state) => state.accountSettings.email);
  const remoteSubGroups = useSelector((state) => state.accountSettings.subGroups);

  const onGroupCheckBoxClick = async (event, groupVal) => {
    const selected = [...selectedGroup];
    if (event.target.checked) selected.push(groupVal);
    else selected.splice(selected.indexOf(groupVal), 1);
    setSelectedGroup(selected);

    setSubGroupUpdating(true);
    await updateGroup(selected);
    setSubGroupUpdating(false);
  };

  useEffect(() => {
    setLocalEmail(remoteEmail);
  }, [remoteEmail]);

  useEffect(() => {
    setSelectedGroup(remoteSubGroups);
  }, [remoteSubGroups]);

  const onLocalEmailChange = (e) => {
    if (!emailChnaged) setEmailChanged(true);
    setLocalEmail(e.target.value);
  };

  const onSubmit = async () => {
    if (emailChnaged) setEmailChanged(false);
    setEmailUpdating(true);
    await updateEmail(localEmail);
    setEmailUpdating(false);
  };

  const onSubscriptionBoxClick = () => {
    toggleSubToDailyEmail(!subStatus);
  };

  return (
    <div className="max-w-md px-3 py-2 pb-4 mb-2 bg-white rounded-lg">
      <h6 className="pb-2">Daily update email</h6>
      <div className="form-control">
        <div className="flex flex-row w-full gap-1">
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="input input-sm basis-2/3 disabled:bg-white input-bordered"
            placeholder="Email address"
            value={localEmail}
            onChange={onLocalEmailChange}
            disabled={emailUpdating}
          />
          <button
            type="button"
            className="text-xs btn btn-sm basis-1/3 btn-primary"
            titile="update"
            disabled={!emailChnaged}
            // loading={emailUpdating}
            onClick={onSubmit}
          >
            Update
          </button>
        </div>
      </div>

      <div className="flex items-center justify-end w-full mt-1">
        <button
          type="button"
          className={classNames('flex flex-col btn btn-sm basis-1/3', { 'btn-success': subStatus })}
        >
          <label className="swap">
            <input type="checkbox" checked={subStatus} onChange={onSubscriptionBoxClick} />
            <div className="flex items-center gap-1 swap-on">
              <CheckCircleIcon className="w-5 h-5 fill-current" />
              <p>Subscribed</p>
            </div>
            <div className="flex items-center justify-center swap-off">
              <p>Subscribe</p>
            </div>
          </label>
        </button>
      </div>
      <div className="p-1">
        <span className="text-sm font-medium text-gray-400 label-text">Select subscribed study group</span>
      </div>
      <div className="grid grid-cols-2">
        {groupInfoObj.map((e) => (
          <div className="flex items-center my-1" key={e.value}>
            <input
              id="subscrition-checkbox"
              name="subscrition-checkbox"
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded text-brand-600 focus:ring-brand-500"
              disabled={subGroupUpdating}
              checked={selectedGroup.indexOf(e.value) !== -1}
              onChange={(event) => {
                onGroupCheckBoxClick(event, e.value);
              }}
            />
            <label htmlFor={e.value} className="block ml-2 text-sm text-gray-900">
              {e.value ? <GroupLabel groupValue={e.value} small /> : null}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DailyEmailCard;
