import produce from 'immer';
import {
  FETCH_COMPLETED_SID_START,
  FETCH_COMPLETED_SID_SUCCESS,
  FETCH_COMPLETED_SID_ERROR,
  FETCH_SELECTED_SID_DATA_START,
  FETCH_SELECTED_SID_DATA_SUCCESS,
  FETCH_SELECTED_SID_DATA_ERROR,
} from '../Actions';

export const INITIAL_STATE = {
  fetching: false,
  completedSidData: [],
  error: null,
  selectedFetching: false,
  selectedSidDetailData: [],
  selectedSidError: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_COMPLETED_SID_START:
        draft.fetching = true;
        draft.completedSidData = [];
        break;
      case FETCH_COMPLETED_SID_SUCCESS:
        draft.fetching = false;
        draft.completedSidData = action.data;
        break;
      case FETCH_COMPLETED_SID_ERROR:
        draft.fetching = false;
        draft.error = action.error;
        break;
      case FETCH_SELECTED_SID_DATA_START:
        draft.selectedFetching = true;
        draft.selectedSidError = null;
        draft.selectedSidDetailData = [];
        break;
      case FETCH_SELECTED_SID_DATA_SUCCESS:
        draft.selectedFetching = false;
        draft.selectedSidDetailData = action.data;
        break;
      case FETCH_SELECTED_SID_DATA_ERROR:
        draft.selectedFetching = false;
        draft.selectedSidError = action.error;
        break;
      default:
        break;
    }
  });
