import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchTableData } from '../../Actions';
import { swapWatch } from '../../Api/HttpCalls/WatchIdHttp';
import { addNoteToStudyId } from '../../Api/Set/StudyIdNotesSets';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function SwapWatchIdDialog({ sid, currentWatchId }) {
  const dispatch = useDispatch();
  const [newWatchId, setNewWatchId] = React.useState('');
  const [swapEnabled, setSwapEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (e) => {
    if (e.target.value.length >= 4) {
      setSwapEnabled(true);
    } else {
      setSwapEnabled(false);
    }
    setNewWatchId(e.target.value);
  };

  const showSuccess = () => {
    toast.success(`New watch id swap was successfully`);
  };

  const showError = (errorMsg) => {
    toast.error(errorMsg);
  };

  const onClickYes = async () => {
    if (loading) return;
    if (!newWatchId) return;

    setLoading(true);
    const res = await swapWatch(sid, newWatchId);
    setLoading(false);
    if (res[1]) {
      showError(res[1]);
      document.getElementById('swap-watch-modal').checked = false;
    } else {
      showSuccess();
      document.getElementById('swap-watch-modal').checked = false;
      if (currentWatchId) {
        addNoteToStudyId(sid, `AUTO : swapped watch id from ${currentWatchId} to ${res[0]}`);
      } else {
        addNoteToStudyId(sid, `AUTO : watch id ${res[0]} activated`);
      }

      setTimeout(() => {
        dispatch(fetchTableData());
      }, 2000);
    }
  };

  return (
    <>
      <input type="checkbox" id="swap-watch-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Swap watch</h3>
          <p className="py-4">Enter the new replacement watch ID</p>
          <input
            type="text"
            placeholder="Type here"
            className="w-full max-w-xs input input-bordered"
            value={newWatchId}
            onChange={handleInputChange}
          />
          <div className="modal-action">
            <button
              type="button"
              className="btn disabled:text-gray-500 btn-primary"
              disabled={!swapEnabled}
              onClick={onClickYes}
            >
              {loading ? <LoadingSpiner className="mr-2 text-white" /> : null}
              Confirm swap
            </button>
            <label htmlFor="swap-watch-modal" className="btn">
              dismiss
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

SwapWatchIdDialog.propTypes = {
  sid: PropTypes.string.isRequired,
  currentWatchId: PropTypes.string,
};

SwapWatchIdDialog.defaultProps = {
  currentWatchId: '',
};

export default SwapWatchIdDialog;
