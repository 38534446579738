import React from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import DevFirestoreRules from './DevFirestoreRules';
import DevProjectCodes from './DevProjectCodes';
import DevManageDashboardAccount from './DevManageDashboardAccount';
import { DashboardIdProvider } from '../../Api/Context/DashboardIdProvider';

function DevScreen() {
  const options = ['project codes', 'dashboard accounts', 'firestore rules'];
  const isOwner = useSelector((state) => state.auth.roles.owner);

  return (
    <div className="w-full sm:px-0">
      {isOwner ? (
        <DashboardIdProvider>
          <Tab.Group>
            <Tab.List className="flex gap-2 p-1 space-x-1 bg-blue-900/20 rounded-xl">
              {options.map((option) => (
                <Tab
                  key={option}
                  className={({ selected }) =>
                    classNames(
                      'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                      'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                      selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                  {option}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="pt-4">
              <Tab.Panel>
                <DevProjectCodes />
              </Tab.Panel>
              <Tab.Panel>
                <DevManageDashboardAccount />
              </Tab.Panel>
              <Tab.Panel>
                <DevFirestoreRules />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </DashboardIdProvider>
      ) : null}
    </div>
  );
}

export default DevScreen;
