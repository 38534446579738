import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function DButton({ onClick, titile, className, leftIcon, disabled, loading, type }) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={loading ? null : onClick}
      className={`flex justify-center btn normal-case gap-2 ${className} border-0`}
    >
      {leftIcon && !loading ? <span>{leftIcon}</span> : null}
      {loading ? <LoadingSpiner className="w-5 h-5 text-[#fff]" /> : null}
      <p className="self-center">{titile}</p>
    </button>
  );
}

DButton.propTypes = {
  titile: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

DButton.defaultProps = {
  onClick: () => {},
  titile: 'Title',
  className: '',
  leftIcon: null,
  disabled: false,
  loading: false,
  type: 'button',
};

export default DButton;
