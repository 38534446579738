import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firestore } from '../../firebase';

const useBasestation = () => {
  const [basestations, setBasestations] = useState([]);
  const [loading, setLoading] = useState(true);

  const idProjectAccess = useSelector((state) => state.auth.projectAccess);
  const isAdmin = useSelector((state) => state.auth.roles.admin);

  useEffect(() => {
    let ref;

    if (isAdmin) {
      ref = firestore.collection('basestation').orderBy('basestation_id');
    } else {
      ref = firestore.collection('basestation').where('projectCode', 'in', idProjectAccess).orderBy('basestation_id');
    }

    const sub = ref.onSnapshot(
      (docs) => {
        if (!docs.empty) {
          const watchIdData = [];
          docs.forEach((e) => {
            const d = e.data();
            const obj = {
              basestation_id: d.basestation_id,
              imei: d.imei,
              owner_sid: d?.owner_sid ?? null,
              data_plan: d?.hologram?.plan ?? null,
              projectCode: d?.projectCode ?? null,
              basestation_status: d?.basestation_status ?? 'unkown',
            };
            watchIdData.push(obj);
          });
          setBasestations(watchIdData);
        }
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return sub;
  }, [idProjectAccess, isAdmin]);

  return { basestations, loading, isAdmin, idProjectAccess };
};

export default useBasestation;
