import React, { useState } from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import LoginForm from './LoginForm';

function EmailAndPassword() {
  const [displayForm, setDisplayForm] = useState('login');

  if (displayForm === 'reset-password') {
    return <ResetPasswordForm changeDisplayForm={setDisplayForm} />;
  }

  return <LoginForm changeDisplayForm={setDisplayForm} />;
}

export default EmailAndPassword;
