import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function isHashColor(str) {
  return /^#[0-9A-F]{6}$/i.test(str);
}

function useGroupLabelStyle(groupValue) {
  const [textColor, setTextColor] = useState('#333333');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [labelText, setLabelText] = useState('NONE');
  const groupInfoObjects = useSelector((state) => state.groupInfo.groupInfoObjects);

  useEffect(() => {
    const obj = groupInfoObjects.find((o) => o.value === groupValue);

    if (isHashColor(obj?.colorText)) {
      setTextColor(obj?.colorText);
    } else {
      setTextColor('#333333');
    }

    if (isHashColor(obj?.colorBackground)) {
      setBgColor(obj?.colorBackground);
    } else {
      setBgColor('#ffffff');
    }

    if (obj?.label) {
      setLabelText(obj?.label);
    } else {
      setLabelText('none');
    }
  }, [groupInfoObjects, groupValue]);

  return { textColor, bgColor, labelText };
}

export default useGroupLabelStyle;
