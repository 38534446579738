import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useDashboardNotes from '../../Api/Hooks/useDashboardNotes';
import BaseTable from '../UI/BaseTable';

function PersonNotesTable(props) {
  const { sid } = props;
  const { notes } = useDashboardNotes(sid);

  const columns = useMemo(
    () => [
      {
        Header: 'Note',
        accessor: 'note',
      },
      {
        Header: 'created at',
        accessor: 'tsDisplay',
      },
      {
        Header: 'created by',
        accessor: 'created_by',
      },
    ],
    []
  );

  return <BaseTable data={notes} columns={columns} title="Notes" />;
}

PersonNotesTable.propTypes = {
  sid: PropTypes.string.isRequired,
};

export default PersonNotesTable;
