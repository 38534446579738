const getFillArr = (ele, isTop) => {
  const dow = new Date(ele.ts).getDay();
  const lenToFill = isTop ? dow : 6 - dow;
  const arr = [];
  for (let i = 0; i < lenToFill; i += 1) {
    const dayDiff = isTop ? i + 1 : (i + 1) * -1;
    const newTsDateObj = new Date(new Date(ele.ts).getTime() - dayDiff * 8.64e7);
    arr.push({
      daily_count: '-1',
      ts: newTsDateObj.toUTCString(),
    });
  }
  return arr;
};

export default function processSidDataCompleted(raw) {
  if (raw.length === 0) return [];

  const first = raw[0];
  const last = raw[raw.length - 1];
  const topFillArr = getFillArr(first, true);
  const bottomFillArr = getFillArr(last, false);

  const combArr = [...topFillArr, ...raw, ...bottomFillArr];
  const sorted = combArr.sort((a, b) => new Date(a.ts) - new Date(b.ts));
  return sorted.map((each) => {
    const obj = {
      ts: each.ts,
      date: new Date(each.ts).toISOString().split('T')[0],
      dayOfWeek: each.ts.slice(0, 3),
      count: Number(each.daily_count),
    };
    return obj;
  });
}
