import { firestore } from '../../firebase';

async function updateSidEarlyCompletion(sid, isEarlyCompletion) {
  try {
    await firestore
      .collection('completed_study_id')
      .doc(sid)
      .set({ early_completion: isEarlyCompletion }, { merge: true });
  } catch (e) {
    console.log(e);
  }
}

export default updateSidEarlyCompletion;
