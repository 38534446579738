import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import BoxChart from './BoxChart';
import BarChart from './BarChart';
import SummaryCard from './SummaryCard';
import PersonNotesTable from './PersonNotesTable';
import DButton from '../UI/DButton';
import SingleSidDocSubscription from '../../Api/Hooks/SingleSidDocSubscription';

function PersonDashboard({ onBackButtonClicked }) {
  const { id } = useParams();

  return (
    <div className="flex flex-col w-full">
      <SingleSidDocSubscription sid={id} />
      <div className="w-full mb-2">
        <DButton
          onClick={onBackButtonClicked}
          titile="Back to table"
          className="w-full btn-primary"
          leftIcon={<ChevronLeftIcon className="w-5 h-5 text-white" aria-hidden="true" />}
        />
      </div>
      <div className="w-full px-5 py-2 bg-white rounded-lg">
        <SummaryCard sid={id} />
      </div>
      <div className="px-3 py-2 mt-2 bg-white rounded-lg">
        <h6 className="pb-2 pl-3">Watch Wear Over Last 3 Days</h6>
        <BoxChart />
      </div>
      <div className="px-3 py-2 mt-2 bg-white rounded-lg">
        <h6 className="pb-2 pl-3">Daily Watch Hours (Last 30 Days)</h6>
        <BarChart />
      </div>
      <div className="mt-2">
        <PersonNotesTable sid={id} />
      </div>
    </div>
  );
}

PersonDashboard.propTypes = {
  onBackButtonClicked: PropTypes.func.isRequired,
};

export default PersonDashboard;
