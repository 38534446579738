import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingWithCancel, AccessDenied, EnrollMFA } from '../Components/main/MainScreenComponent';
import HomeSim from './HomeSim';

function Main() {
  const tokenRequesting = useSelector((state) => state.auth.token_requesting);
  const idInfoRequesting = useSelector((state) => state.auth.dashboard_id_info_requesting);
  const isAdmin = useSelector((state) => state.auth.roles.admin);
  const isCoordinator = useSelector((state) => state.auth.roles.coordinator);
  const mfaEnrollmentRequired = useSelector((state) => state.auth.mfaEnrollmentRequired);

  if (idInfoRequesting || tokenRequesting) {
    return <LoadingWithCancel />;
  }

  if (!isAdmin && !isCoordinator) {
    return <AccessDenied />;
  }

  if (mfaEnrollmentRequired) {
    return <EnrollMFA />;
  }

  return <HomeSim />;
}

export default Main;
