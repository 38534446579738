import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import useEachWatchId from '../../Api/Hooks/useEachWatchId';
import RowItem from '../UI/RowItem';
import TableCellDropdown from '../UI/TableCellDropdown';
import StatusTag from '../UI/StatusTag';
import {
  setWatchIdProjectCode,
  setWatchIdStatus,
  removeWatchLog,
  convertLogToWatchLog,
} from '../../Api/Set/WatchIdSets';
import BaseTable from '../UI/BaseTable';
import { deactivateWatch } from '../../Api/HttpCalls/WatchIdHttp';

function EachWatchId() {
  const { watch_id } = useParams();
  const { watchIdDoc, watchLogs, ownershipLogs, requesting } = useEachWatchId(watch_id);
  const isAdmin = useSelector((state) => state.auth.roles.admin);
  const idProjectAccess = useSelector((state) => state.auth.projectAccess);

  const onConvert = async () => {
    await convertLogToWatchLog(watch_id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        id: 'type',
        accessor: 'type',
      },
      {
        Header: 'Date',
        id: 'ts',
        accessor: (d) => {
          if (d.ts) {
            return moment(d.ts.toDate()).format('ll');
          }
          return '';
        },
      },
      { Header: 'Desc', id: 'desc', accessor: 'desc' },
      {
        Header: '',
        id: 'delete',
        accessor: null,
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <button
            className="btn btn-xs btn-square btn-ghost"
            type="button"
            onClick={() => {
              removeWatchLog(watch_id, original);
            }}
          >
            <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path
                fillRule="evenodd"
                d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ),
      },
    ],
    [watch_id]
  );

  const onConfirmDeactivateWatch = async () => {
    const res = await deactivateWatch(watch_id);
    if (res[0]) {
      toast.success(res[0]);
    } else {
      toast.error(res[1]);
    }
  };

  return (
    <div>
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/watches">Watches</Link>
          </li>
          <li>{watch_id}</li>
        </ul>
      </div>
      <>
        <input type="checkbox" id="deactivate-watch-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box">
            <h3 className="text-lg font-bold">Deactivate watch</h3>
            <p className="py-4">
              Deactivate watch <b>{watch_id}</b> for
              <b> {watchIdDoc.owner_sid}</b>
            </p>
            <div className="modal-action">
              <label htmlFor="deactivate-watch-modal" className="btn">
                Dismiss
              </label>
              <button className="btn btn-warning" type="button" onClick={onConfirmDeactivateWatch}>
                <label htmlFor="deactivate-watch-modal">Confirm</label>
              </button>
            </div>
          </div>
        </div>
      </>
      <div className="z-10 flex w-full gap-4">
        <div className="flex flex-col w-full max-w-lg gap-1 px-2 py-2 bg-white rounded">
          <RowItem title="Watch ID" value={watchIdDoc.watch_id} odd />
          <RowItem
            title="Owner"
            value={watchIdDoc.owner_sid}
            actionElement={() =>
              watchIdDoc.owner_sid && isAdmin ? (
                <label htmlFor="deactivate-watch-modal" className="rounded-md btn btn-xs btn-warning no-animation">
                  Deactive
                </label>
              ) : null
            }
          />
          <RowItem title="Activated Date" value={watchIdDoc.activation_date} odd />
          <RowItem
            title="Project"
            value={watchIdDoc.projectCode}
            rightElement={() => (
              <TableCellDropdown
                editable={isAdmin}
                value={watchIdDoc.projectCode}
                options={idProjectAccess}
                onChange={(e) => setWatchIdProjectCode(watchIdDoc.watch_id, e, watchIdDoc.projectCode)}
              />
            )}
          />
          <RowItem
            title="Status"
            value={watchIdDoc.watch_status}
            odd
            rightElement={() => (
              <TableCellDropdown
                value={watchIdDoc.watch_status}
                options={['ready', 'inactive', 'broken']}
                editable={isAdmin}
                customItem={StatusTag}
                onChange={(e) => setWatchIdStatus(watchIdDoc.watch_id, e, watchIdDoc.watch_status)}
              />
            )}
          />
          {isAdmin ? (
            <RowItem
              title="Ownership logs"
              value={ownershipLogs.length ?? 0}
              actionElement={() =>
                ownershipLogs.length > 0 ? (
                  <button type="button" className="rounded-md btn btn-xs btn-warning no-animation" onClick={onConvert}>
                    convert log
                  </button>
                ) : null
              }
            />
          ) : null}
        </div>
      </div>
      <div className="z-0 pt-3">
        <BaseTable columns={columns} data={watchLogs} loading={requesting} />
      </div>
    </div>
  );
}

export default EachWatchId;
