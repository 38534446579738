import React, { useState } from 'react';
import 'firebase/compat/auth';
import { useDispatch } from 'react-redux';
import { MinusCircleIcon, BadgeCheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';
import DButton from '../UI/DButton';
import { logout, requestDashboardIdInfo } from '../../Actions';
import useFirebaseAuthInfo from '../../Api/Hooks/useFirebaseAuthInfo';

export const Loading = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="flex items-center h-20 max-w-sm p-4 pb-20 mx-auto rounded-md">
      <LoadingSpiner className="mr-2" />
      <p className="pl-2 font-bold">loading ...</p>
    </div>
  </div>
);

export const LoadingWithCancel = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-row justify-center h-full dark">
      <div className="pt-12 basis-11/12 md:pt-32 md:basis-96">
        <div className="grid gap-3 px-4 py-3 mt-2 bg-white rounded-xl">
          <div>
            <div className="flex flex-row items-center py-2">
              <div className="flex items-center justify-center w-8 h-8 text-white bg-indigo-500 rounded-full">
                <LoadingSpiner className="w-5 h-5 text-[#fff]" />
              </div>
              <h4 className="pl-4">Loading account info...</h4>
            </div>
            <p className="text-gray-400">Request account information from server please wait.</p>
          </div>
          <div>
            <DButton
              className="w-full mt-6 btn-primary"
              titile="Cancel"
              onClick={() => {
                dispatch(logout());
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const AccessDenied = () => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-row justify-center h-full dark">
      <div className="pt-12 basis-11/12 md:pt-32 md:basis-96">
        <div className="grid gap-3 px-4 py-3 mt-2 bg-white rounded-xl">
          <div>
            <div className="flex flex-row items-center py-2">
              <div className="flex items-center justify-center w-8 h-8 text-white bg-red-500 rounded-full">
                <MinusCircleIcon className="w-5 h-5" />
              </div>
              <h4 className="pl-4">Access denied</h4>
            </div>
            <p className="text-gray-400">
              Current account does not have sufficient permission to access dashboard, please contact Sepsis Scout.
            </p>
          </div>
          <div>
            <DButton
              className="w-full mt-6"
              titile="Go back"
              onClick={() => {
                dispatch(logout());
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const EnrollMFA = () => {
  const dispatch = useDispatch();

  const fetchAndUpdateIdInfo = () => {
    dispatch(requestDashboardIdInfo());
  };

  const {
    email,
    enrolledMFANumber,
    requestSMSCode,
    vCodeSent,
    verifyCode,
    onCancel,
    emailVerified,
    sendVerifyEmailToUser,
  } = useFirebaseAuthInfo(fetchAndUpdateIdInfo);

  const [phoneVal, setPhoneVal] = useState('');
  const [codeVal, setCodeVal] = useState('');

  const cancelMFASetup = () => {
    onCancel();
    setPhoneVal('');
    setCodeVal('');
  };

  return (
    <div className="flex flex-row justify-center h-full dark">
      <div className="pt-12 basis-11/12 md:pt-32 md:basis-96">
        <div className="max-w-md px-3 py-2 pb-4 mb-2 bg-white rounded-lg">
          <h6 className="font-bold">MFA configure</h6>
          <p className="py-1 text-gray-500">
            Mutifactor authentication required by organization, please add phone number to your account.
          </p>
          <div className="pt-2 form-control">
            <label className="p-1" htmlFor="form-control">
              <span className="text-sm font-medium text-gray-400">Account email</span>
            </label>
            <div className="flex flex-row w-full">
              <div type="text" className="bg-white input input-sm basis-2/3">
                {email}
              </div>
              <div className="flex items-center justify-center basis-1/3 group">
                <div className="flex items-center gap-1 group-hover:hidden">
                  <BadgeCheckIcon
                    className={classNames('w-5 h-5 text-gray-300', { 'text-green-500': emailVerified })}
                  />
                  <p className="font-semibold text-gray-400">{emailVerified ? 'Verified' : 'Unverified'}</p>
                </div>
                <button
                  type="button"
                  disabled={emailVerified}
                  className={classNames('hidden text-xs btn btn-sm btn-primary group-hover:flex')}
                  onClick={sendVerifyEmailToUser}
                >
                  {emailVerified ? 'verified' : 'Verify Email'}
                </button>
              </div>
            </div>
          </div>
          <div className="pt-2 form-control">
            <label className="p-1" htmlFor="form-control">
              <span className="text-sm font-medium text-gray-400">Mutifactor authentication</span>
            </label>
            <div className="flex flex-row w-full">
              <input
                type="text"
                value={phoneVal}
                onChange={(e) => {
                  setPhoneVal(e.target.value);
                }}
                onFocus={() => {
                  if (phoneVal.length === 0) setPhoneVal('+1');
                }}
                className="input input-sm basis-2/3 disabled:bg-white input-bordered"
                placeholder="Enter phone number"
                disabled={enrolledMFANumber}
              />
              <div className="flex items-center justify-center basis-1/3 group">
                <button
                  type="button"
                  className="text-xs btn btn-sm btn-primary"
                  disabled={phoneVal.length < 10}
                  onClick={() => requestSMSCode(phoneVal)}
                >
                  Add Phone
                </button>
              </div>
            </div>
            {enrolledMFANumber === null ? (
              <label className="p-1" htmlFor="form-control">
                <span className="text-sm text-gray-400 label-text">i.e +14150000000</span>
              </label>
            ) : null}
            <div id="recaptcha-container-2" className="flex justify-center mt-2 rounded-lg" />
            {vCodeSent ? (
              <div className="flex flex-col w-full pt-2">
                <input
                  type="text"
                  value={codeVal}
                  onChange={(e) => {
                    setCodeVal(e.target.value);
                  }}
                  placeholder="Enter verification code we just sent you"
                  className="input input-bordered input-sm disabled:bg-white"
                />
                <div className="flex flex-row gap-1 pt-2">
                  <button type="button" className="text-xs btn btn-sm basis-1/3" onClick={cancelMFASetup}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="flex-grow text-xs btn btn-sm btn-primary"
                    onClick={() => verifyCode(codeVal)}
                  >
                    Verify CODE
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="w-full pt-8">
            <button
              className="w-1/3 btn btn-md"
              type="button"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
