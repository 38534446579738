import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { firestore } from '../../firebase';

export const DashboardIdContext = React.createContext();

export const DashboardIdProvider = ({ children }) => {
  const [dashboardIds, setDashboardIds] = useState([]);
  const [uidList, setUidList] = useState([]);
  const [error, setError] = useState(null);
  const [newlyCreatedUid, setNewlyCreatedUid] = useState(null);

  useEffect(() => {
    const sub = firestore.collection('dashboard_id').onSnapshot(
      (docs) => {
        const list = [];
        docs.forEach((doc) => {
          list.push({
            uid: doc.id,
            email: doc.data()?.email ?? null,
            displayName: doc.data()?.displayName ?? null,
            desc: doc.data()?.desc ?? null,
            roles: doc.data()?.roles,
            projectAccess: doc.data()?.projectAccess,
            requireMFA: doc.data()?.requireMFA ?? false,
          });
        });
        list.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
        setUidList(list.map((e) => e.uid));
        setDashboardIds(list);
      },
      (e) => {
        console.log(e);
        setError(e.message);
      }
    );
    return sub;
  }, []);

  return (
    <DashboardIdContext.Provider
      value={{
        dashboardIds,
        uidList,
        error,
        newlyCreatedUid,
        setNewlyCreatedUid,
      }}
    >
      {children}
    </DashboardIdContext.Provider>
  );
};

DashboardIdProvider.propTypes = {
  children: PropTypes.element,
};

DashboardIdProvider.defaultProps = {
  children: null,
};
