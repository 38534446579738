import { all } from 'redux-saga/effects';
import { tableDataSagas } from './TableDataSagas';
import { setupSaga } from './SetupSagas';
import { personDataSagas } from './PersonDataSagas';
import { authSaga } from './AuthSagas';
import { fetchSelectedSidDataSaga } from './CompletedStudyIdSagas';
import { EmailAuthSagas } from './EmailAuthSagas';

export default function* root() {
  yield all([
    tableDataSagas(),
    setupSaga(),
    authSaga(),
    personDataSagas(),
    fetchSelectedSidDataSaga(),
    EmailAuthSagas(),
  ]);
}
