import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { LockClosedIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DButton from '../UI/DButton';
import { authRestPasswordLink } from '../../Api/Authentication/AuthenticationWithEmail';
import { resetPasswordError } from '../../Actions';

function ResetPasswordForm({ changeDisplayForm }) {
  const dispatch = useDispatch();
  const [linkRequsting, setLinkRequesting] = useState(false);
  const [linkSend, setLinkSend] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data) => {
    setLinkRequesting(true);
    const res = await authRestPasswordLink(data.email);
    if (res[0] === 'success') {
      setLinkSend(true);
    } else {
      dispatch(resetPasswordError(res[1]));
    }
    setLinkRequesting(false);
  };

  return (
    <div className="grid gap-3 px-4 py-3 mt-2 bg-white divide-y rounded-xl">
      {!linkSend ? (
        <div>
          <div className="flex flex-row items-center py-2">
            <div className="flex items-center justify-center w-8 h-8 text-white bg-red-300 rounded-full">
              <LockClosedIcon className="w-5 h-5" />
            </div>
            <h4 className="pl-4">Forgot your password?</h4>
          </div>
          <p className="text-gray-400">Enter your email below to receive password reset instruction.</p>
        </div>
      ) : (
        <div>
          <div className="flex flex-row items-center py-2">
            <div className="flex items-center justify-center w-8 h-8 text-white bg-green-300 rounded-full">
              <PaperAirplaneIcon className="w-5 h-5" />
            </div>
            <h4 className="pl-4">Email has been sent!</h4>
          </div>
          <p className="text-gray-400">Please check your inbox and click in the received link to reset a password.</p>
        </div>
      )}

      {!linkSend ? (
        <form onSubmit={handleSubmit(onSubmit)} className="grid py-2">
          <div className="mt-4 mb-4">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-800 ">
              Email
            </label>
            <input
              type="text"
              id="email"
              className={classNames(
                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full p-2.5',
                {
                  'bg-red-100': errors.email,
                  'border-red-300': errors.email,
                }
              )}
              {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
            />
          </div>
          <div className="flex justify-center w-full py-1">
            <button
              type="button"
              className="text-sm font-semibold text-gray-500 hover:underline"
              onClick={() => changeDisplayForm('login')}
            >
              Back to login
            </button>
          </div>
          <DButton className="mt-8 btn-primary" type="submit" titile="Request link" loading={linkRequsting} />
        </form>
      ) : (
        <div className="grid py-2">
          <div className="flex items-center justify-center h-64">
            <svg width="206" height="98" viewBox="0 0 206 98" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.5861 92.9323L27 67.5L205.5 19L37.1842 95.861C34.8096 96.9453 32.0494 95.5013 31.5861 92.9323Z"
                fill="#89A2CF"
              />
              <path d="M17.5 20.5L20.5 39L206 18.5L17.5 20.5Z" fill="#A3BEE1" />
              <path
                d="M205.5 18.5L17.5 20.5L14.6604 3.86834C14.3277 1.91947 15.9382 0.187243 17.9061 0.377355L205.5 18.5Z"
                fill="#EAF3FF"
              />
              <path
                d="M206 18.5L18 36L2.24127 76.7101C1.33528 79.0505 3.53235 81.4026 5.92903 80.658L206 18.5Z"
                fill="#EAF3FF"
              />
            </svg>
          </div>
          <DButton className="mt-8" type="button" titile="Back to login" onClick={() => changeDisplayForm('login')} />
        </div>
      )}
    </div>
  );
}

ResetPasswordForm.propTypes = {
  changeDisplayForm: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
