import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';

const useEachCompletedStudyId = (sid) => {
  const [loading, setLoading] = useState(true);
  const [completedSidDoc, setCompletedSidDoc] = useState(null);

  useEffect(() => {
    const sub = firestore
      .collection('completed_study_id')
      .doc(sid)
      .onSnapshot(
        setLoading(false),
        (doc) => {
          if (doc.exists) {
            setCompletedSidDoc(doc.data());
          }
        },
        (error) => {
          console.log('error fetching completed study id : ', error);
        }
      );
    return sub;
  }, [sid]);

  return { loading, completedSidDoc };
};

export default useEachCompletedStudyId;
