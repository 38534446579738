import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { functions, firestore } from '../../firebase';
import DButton from '../UI/DButton';

function CreateStudyId() {
  const isAdmin = useSelector((state) => state.auth.roles.admin);
  const [newStudyId, setNewStudyId] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [requestingBunch, setRequestingBunch] = useState(false);

  const showError = (errorMsg) => {
    toast.error(errorMsg);
  };

  const showSuccess = () => {
    toast.success('New study Id created successfully!');
  };

  const createNewStudyId = async () => {
    setRequesting(true);
    const res = await functions.httpsCallable('createStudyId')({
      sid: newStudyId.toUpperCase(),
    });
    setRequesting(false);
    if (res.data.error) {
      showError(res.data.error);
    } else {
      setNewStudyId('');
      showSuccess();
    }
  };

  function arrOfSid(len) {
    const arr = Array.from(new Array(len), (val, index) => {
      const from = 31;
      const final = from + index;
      const str = String(final);
      return `SID0${str}`;
    });
    return arr;
  }

  const createBunchStudyId = async () => {
    const sidArr = arrOfSid(40);
    setRequestingBunch(true);
    const res = await Promise.all(sidArr.map((each) => functions.httpsCallable('createStudyId')({ sid: each })));
    console.log(res);
    setRequestingBunch(false);
  };

  const updateSidGroup = (sid, group) => firestore.collection('study_id').doc(sid).update({ group });

  const updateBunchSidGroup = async () => {
    const sidArr = arrOfSid(40);
    const g = '';
    const res = await Promise.all(sidArr.map((each) => updateSidGroup(each, g)));
    console.log(res);
  };

  return (
    <div className="flex gap-2">
      <input
        className="w-42 input input-sm input-bordered"
        placeholder="new study id"
        value={newStudyId}
        onChange={(e) => {
          if (e.target.value.length > 6) return;
          setNewStudyId(e.target.value.toUpperCase());
        }}
      />

      <DButton
        disabled={newStudyId.length < 1 || !isAdmin}
        onClick={createNewStudyId}
        loading={requesting}
        className="btn-sm btn-neutral"
        titile="Create"
        leftIcon={
          <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
              clipRule="evenodd"
            />
          </svg>
        }
      />
    </div>
  );
}

export default CreateStudyId;
