import React from 'react';

function Footer() {
  return (
    <div className="layout-footer">
      <p>Sepsis Scout, Inc. 2023</p>
    </div>
  );
}

export default Footer;
