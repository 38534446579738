import React from 'react';
import { toast } from 'react-toastify';
import deleteStudyId from '../../Api/HttpCalls/DeleteUser';

function DeleteDialog({ sid }) {
  const showSuccess = (id) => {
    toast.success(`${id} deleted successfully.`);
  };

  const onClickYes = async () => {
    const res = await deleteStudyId(sid);
    if (res[0]) {
      showSuccess(sid);
    }
  };

  return (
    <>
      <input type="checkbox" id="del-sid-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Remove Study ID permanently</h3>
          <p className="py-4">
            Are you sure to delete <span className="font-bold text-error">{sid}</span>? This action cannot be undone.
          </p>
          <div className="modal-action">
            <label htmlFor="del-sid-modal" className="btn">
              Dismis
            </label>
            <label htmlFor="del-sid-modal" className="btn btn-warning" type="button" onClick={onClickYes}>
              Delete
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteDialog;
