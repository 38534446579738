import React from 'react';
import PropTypes from 'prop-types';
import './Styles/CProgressbar.scss';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function CProgressbar({ progress, displayText, displayText2nd, loading }) {
  let scale = '';
  if (progress >= 75) {
    scale = 'hig';
  } else if (progress >= 25 && progress < 75) {
    scale = 'mid';
  } else {
    scale = 'low';
  }

  return (
    <div className={`cpb-container ${scale}`}>
      {loading ? (
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <LoadingSpiner className="w-5 h-5 text-[#333333] " />
        </span>
      ) : (
        <span className="font-bold cpb-text">{displayText}</span>
      )}
      <span className="cpb-text-secondary">{displayText2nd}</span>
      <div className={`cpb-filler ${scale}`} style={{ width: `${progress}%` }} />
    </div>
  );
}

CProgressbar.propTypes = {
  progress: PropTypes.number.isRequired,
  displayText: PropTypes.string,
  displayText2nd: PropTypes.string,
  loading: PropTypes.bool,
};

CProgressbar.defaultProps = {
  displayText: '---',
  displayText2nd: null,
  loading: false,
};

export default CProgressbar;
