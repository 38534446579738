export const FETCH_PERSON_DATA_START = 'FETCH_PERSON_DATA_START';
export const FETCH_PERSON_DATA_SUCCESS = 'FETCH_PERSON_DATA_SUCCESS';
export const FETCH_PERSON_DATA_FAIL = 'FETCH_PERSON_DATA_FAIL';
export const CLEAR_PERSON_DATA = 'CLEAR_PERSON_DATA';
export const UPDATE_SID_DOC_DATA = 'UPDATE_SID_DOC_DATA';

export function fetchPersonDataStart(sid) {
  return {
    type: FETCH_PERSON_DATA_START,
    sid,
  };
}

export function fetchPersonDataSuccess(sid, boxByHour, lineByDay, bbAvail, mTempAvail) {
  return {
    type: FETCH_PERSON_DATA_SUCCESS,
    sid,
    boxByHour,
    lineByDay,
    bbAvail,
    mTempAvail,
  };
}

export function fetchPersonDataFail(error) {
  return {
    type: FETCH_PERSON_DATA_FAIL,
    error,
  };
}

export function clearPersonData() {
  return {
    type: CLEAR_PERSON_DATA,
  };
}

export function updateSidDocData(data) {
  return {
    type: UPDATE_SID_DOC_DATA,
    data,
  };
}
