import { useEffect, useState } from 'react';
import moment from 'moment';
import { firestore } from '../../firebase';

const useDashboardNotes = (sid) => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sub = firestore
      .collection('dashboard_notes')
      .where('sid', '==', sid)
      .onSnapshot(
        (doc) => {
          if (!doc.empty) {
            const dataArr = [];
            doc.forEach((e) => {
              const d = e.data();
              dataArr.push({
                ...d,
                ts: d.created_at.toDate(),
                tsDisplay: moment(d.created_at.toDate()).format('ll'),
                id: e.id,
              });
            });
            dataArr.sort((a, b) => b.ts - a.ts);
            setNotes(dataArr);
          }
        },
        (errorObj) => {
          setError(errorObj);
        }
      );
    return sub;
  }, [sid]);

  return { notes, error };
};

export default useDashboardNotes;
