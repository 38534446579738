import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import TableCellDropdown from '../UI/TableCellDropdown';
import useWatchId from '../../Api/Hooks/useWatchId';
import { setWatchIdProjectCode, setWatchIdStatus } from '../../Api/Set/WatchIdSets';
import StatusTag from '../UI/StatusTag';
import { TextColumnFilter, SelectColumnFilter } from '../UI/TableParts';
import DButton from '../UI/DButton';
import { createWatch } from '../../Api/HttpCalls/WatchIdHttp';
import BaseTableV2 from '../UI/BaseTableV2';

function WatchesTable() {
  const { watchIds, loading, isAdmin, idProjectAccess } = useWatchId();
  const [newWatchId, setNewWatchId] = useState('');
  const [requesting, setRequesting] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'count',
        disableSortBy: true,
        accessor: 'watch_id',
        ccn: 'w-6 border-b-0',
        Cell: ({ row }) => <span className="pl-2 text-xs text-center text-gray-400">{row.index + 1}</span>,
        smHidden: true,
      },
      {
        Header: 'Watch ID',
        id: 'watch_id',
        accessor: 'watch_id',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Owner',
        id: 'owner_sid',
        accessor: 'owner_sid',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Activation Date',
        id: 'activation_date',
        accessor: (d) => {
          if (d.activation_date) {
            return moment(d.activation_date).format('ll');
          }
          return '';
        },
      },
      {
        Header: 'Project',
        id: 'project_code',
        accessor: 'projectCode',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ row: { original } }) => (
          <TableCellDropdown
            value={original.projectCode}
            options={idProjectAccess}
            onChange={(e) => {
              setWatchIdProjectCode(original.watch_id, e, original.projectCode);
            }}
            editable={isAdmin}
          />
        ),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'watch_status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ row: { original } }) => (
          <TableCellDropdown
            value={original.watch_status}
            options={['ready', 'inactive', 'broken']}
            onChange={(e) => {
              setWatchIdStatus(original.watch_id, e, original.watch_status);
            }}
            customItem={StatusTag}
            editable={isAdmin}
          />
        ),
      },
      {
        Header: '',
        accessor: 'watch_id',
        id: 'to_nested',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Link to={`/watches/${original.watch_id}`} className="flex-grow">
            <button className="w-full btn sm:btn-ghost btn-outline btn-sm sm:w-min" type="button">
              <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </Link>
        ),
      },
    ],
    [idProjectAccess, isAdmin]
  );

  const createNewDeviceID = async () => {
    setRequesting(true);
    const res = await createWatch(newWatchId);
    setRequesting(false);
    if (res[1]) {
      toast.error(res[1]);
    } else {
      toast.success('New watch successfully added.');
    }
    setNewWatchId('');
  };

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <BaseTableV2
          loading={loading}
          tableData={watchIds}
          columns={columns}
          headerElement={
            isAdmin ? (
              <div className="flex gap-2">
                <input
                  className="w-36 input input-sm input-bordered"
                  value={newWatchId}
                  onChange={(e) => {
                    setNewWatchId(e.target.value);
                  }}
                />
                <DButton
                  className="btn-sm"
                  titile="Add New watch"
                  disabled={newWatchId.length < 4}
                  onClick={createNewDeviceID}
                  loading={requesting}
                  leftIcon={
                    <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                />
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
}

export default WatchesTable;
