import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon, XIcon, TrashIcon, MailIcon } from '@heroicons/react/solid';
import DButton from '../UI/DButton';
import {
  createDashboardAccountByEmail,
  deleteDashboardAccountByEmail,
  sendPasswordResetEmail,
} from '../../Api/HttpCalls/dashboardAccount';
import { DashboardIdContext } from '../../Api/Context/DashboardIdProvider';
import { updateDashboardId } from '../../Api/Set/DashboardIdSets';

function DashboardAccountCard({
  accountObject,
  createMode,
  onSuccessMsg,
  onErrorMsg,
  onCreateCancel,
  onCreateSuccess,
}) {
  const [editMode, setEditMode] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [mailSending, setMailSending] = useState(false);
  const [mailSent, setMailSent] = useState(false);

  const { newlyCreatedUid, setNewlyCreatedUid } = useContext(DashboardIdContext);

  const [displayUid, setDisplayUid] = useState(accountObject.uid);
  const [displayEmail, setDisplayEmail] = useState(accountObject.email);
  const [displayDisplayName, setDisplayDisplayName] = useState(accountObject.displayName);
  const [displayDesc, setDisplayDesc] = useState(accountObject.desc);
  const [displayRequireMFA, setDisplayRequireMFA] = useState(accountObject.requireMFA ?? false);

  const [creating, setCreating] = useState(false);

  const createAccount = useCallback(async () => {
    setCreating(true);
    const res = await createDashboardAccountByEmail(displayEmail, displayDisplayName, displayDesc, displayRequireMFA);
    setCreating(false);
    if (res[0]) {
      setNewlyCreatedUid(res[0].uid);
      onCreateSuccess('Account created successfully!');
      onCreateSuccess();
    } else {
      onErrorMsg(res[1]);
    }
  }, [
    displayEmail,
    displayDisplayName,
    displayDesc,
    displayRequireMFA,
    setNewlyCreatedUid,
    onCreateSuccess,
    onErrorMsg,
  ]);

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const deleteAccount = useCallback(async () => {
    setDeleting(true);
    const res = await deleteDashboardAccountByEmail(accountObject.email);
    setDeleting(false);
    if (res[1]) {
      onErrorMsg(res[1]);
    } else {
      onSuccessMsg('Account deleted.');
    }
  }, [accountObject.email, onErrorMsg, onSuccessMsg]);

  const comfirmDeleteAccount = () => {
    setShowDeleteDialog(false);
    deleteAccount();
  };

  const onSave = async () => {
    setSaving(true);
    const newObject = {
      desc: displayDesc,
      requireMFA: displayRequireMFA,
    };
    await updateDashboardId(displayUid, newObject);
    setEditMode(false);
    setSaving(false);
  };

  const sendSetPasswordEmail = async () => {
    setMailSending(true);
    const res = await sendPasswordResetEmail(accountObject.email);
    setMailSending(false);
    if (res[1]) {
      onErrorMsg(res[1]);
    } else {
      setMailSent(true);
      onSuccessMsg('Password set email sent.');
    }
  };

  const onMFASelectChange = (e) => {
    if (e.target.value === 'true') {
      setDisplayRequireMFA(true);
    } else {
      setDisplayRequireMFA(false);
    }
  };

  return (
    <div
      className={classNames('w-full col-span-1 px-2 py-2 rounded-md bg-gray-50', {
        'ring-2': createMode,
        'drop-shadow-md': createMode,
      })}
    >
      <Transition
        show={showDeleteDialog}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog className="fixed inset-0 z-10 overflow-y-auto" open={showDeleteDialog} onClose={() => {}}>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
            <div className="relative p-4 mx-auto bg-white rounded-xl w-80">
              <Dialog.Title as="h6">Delete dashboard account</Dialog.Title>
              <Dialog.Description className="w-5/6 py-2 text-gray-500">
                Are you sure you want to delete dashboard account associated with email {accountObject.email}
              </Dialog.Description>
              <div className="flex justify-end pt-2">
                <DButton
                  className="mr-2 bg-yellow-300 hover:bg-yellow-300 focus:ring-yellow-200 basis-1/3"
                  titile="Confirm"
                  onClick={comfirmDeleteAccount}
                />
                <DButton className="basis-2/3" titile="Dismiss" onClick={closeDeleteDialog} />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="flex flex-row pb-1 col-1">
        <div className="w-full">
          <p className="pb-1 pl-1 text-xs text-gray-300">Display name</p>
          <input
            className="w-full px-2 py-1 rounded-md"
            value={displayDisplayName ?? ''}
            onChange={(e) => {
              setDisplayDisplayName(e.target.value);
            }}
            disabled={!createMode}
          />
        </div>
        {newlyCreatedUid === accountObject.uid ? (
          <div className={classNames('flex items-center justify-center', { hidden: createMode })}>
            <div className="flex items-center justify-center px-1">
              <p className="px-1.5 text-[10px] font-medium text-white bg-green-400 rounded-full -rotate-45">new</p>
            </div>
            <button
              type="button"
              className={classNames('flex bg-green-400 rounded hover:bg-green-500', {
                'bg-blue-200': mailSent,
                'hover:bg-blue-400': mailSent,
              })}
              onClick={() => {
                sendSetPasswordEmail();
              }}
            >
              <MailIcon className={classNames('w-6 h-6 p-1', { 'animate-spin': mailSending })} />
            </button>
          </div>
        ) : null}
        <div className={classNames('px-1 flex items-center justify-center', { hidden: createMode })}>
          <button
            type="button"
            className={classNames('flex rounded bg-blue-200 hover:bg-blue-400', {
              'bg-red-200': editMode,
              'hover:bg-red-400': editMode,
            })}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? <XIcon className="w-6 p-1" /> : <PencilIcon className="w-6 p-1" />}
          </button>
        </div>
      </div>
      <div className="pb-1 col-1">
        <p className="pb-1 pl-1 text-xs text-gray-300">Email address</p>
        <input
          className="w-full px-2 py-1 rounded-md"
          value={displayEmail ?? ''}
          onChange={(e) => {
            setDisplayEmail(e.target.value);
          }}
          disabled={!createMode}
        />
      </div>
      <div className="pb-1 col-1">
        <p className="pb-1 pl-1 text-xs text-gray-300">MFA Required</p>
        <select
          className="w-full max-w-xs border-0 select select-sm disabled:bg-gray-100"
          disabled={!editMode && !createMode}
          value={displayRequireMFA}
          onChange={onMFASelectChange}
        >
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
      </div>
      <div className="pb-1 col-1">
        <p className="pb-1 pl-1 text-xs text-gray-300">Description</p>
        <input
          className="w-full px-2 py-1 rounded-md"
          value={displayDesc ?? ''}
          onChange={(e) => {
            setDisplayDesc(e.target.value);
          }}
          disabled={!editMode && !createMode}
        />
      </div>
      {!createMode ? (
        <div className="pb-1 col-1">
          <p className="pb-1 pl-1 text-xs text-gray-300">UID</p>
          <p className="pl-1 text-xs font-medium text-gray-400">{displayUid}</p>
        </div>
      ) : null}
      <div className={classNames('flex mt-4 gap-2 col-1', { hidden: !createMode })}>
        <DButton className="flex-grow" titile="Cancel" onClick={onCreateCancel} />
        <DButton className="basis-2/3 btn-primary" titile="Create" loading={creating} onClick={createAccount} />
      </div>
      <div className={classNames('flex mt-4 gap-2 col-1', { hidden: !editMode })}>
        <DButton
          leftIcon={<TrashIcon className="w-5 h-5" />}
          className="btn-error basis-1/2"
          titile="Delete"
          loading={deleting}
          onClick={openDeleteDialog}
        />
        <DButton className="flex-grow btn-primary" titile="Save" loading={saving} onClick={onSave} />
      </div>
    </div>
  );
}

DashboardAccountCard.propTypes = {
  accountObject: PropTypes.object,
  createMode: PropTypes.bool,
  onSuccessMsg: PropTypes.func,
  onErrorMsg: PropTypes.func,
  onCreateCancel: PropTypes.func,
  onCreateSuccess: PropTypes.func,
};

DashboardAccountCard.defaultProps = {
  accountObject: {
    uid: null,
    displayName: null,
    email: null,
    desc: null,
  },
  createMode: false,
  onSuccessMsg: () => {},
  onErrorMsg: () => {},
  onCreateCancel: () => {},
  onCreateSuccess: () => {},
};

export default DashboardAccountCard;
