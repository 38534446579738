import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

function TableCellDropdown({ value, options, onChange, editable, customItem: CustomItem }) {
  if (!editable) {
    return (
      <div className="flex items-center font-normal text-[#333333] group">
        {CustomItem ? <CustomItem status={value} /> : <p className="text-sm">{value}</p>}
      </div>
    );
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button className="flex items-center gap-1 font-normal text-[#333333] group p-2 z-40">
            {CustomItem ? <CustomItem status={value} /> : <p className="text-sm">{value}</p>}
            <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-gray-300 group-hover:text-gray-400">
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </Menu.Button>
          <Menu.Items className="absolute z-50 p-2 shadow menu bg-base-100 rounded-box">
            <div className="flex flex-col p-1">
              {options.map((option) => (
                <Menu.Item key={option} static>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => {
                        onChange(option);
                      }}
                      className={classNames('p-2 rounded-lg text-sm font-semiblod', {
                        'bg-primary-500': active,
                        'bg-gray-200': option === value && !active,
                        'text-gray-600': !active,
                        'text-white': active,
                      })}
                    >
                      {CustomItem ? <CustomItem status={option} /> : <p className="text-sm">{option}</p>}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

export default TableCellDropdown;

TableCellDropdown.prototype = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  customItem: PropTypes.element || null,
  editable: PropTypes.bool,
};

TableCellDropdown.defaultProps = {
  value: '0',
  options: ['1', '2'],
  onChange: () => {
    console.log('please attach onchange method');
  },
  customItem: null,
  editable: false,
};
