export const FETCH_COMPLETED_SID_START = 'FETCH_COMPLETED_SID_START';
export const FETCH_COMPLETED_SID_SUCCESS = 'FETCH_COMPLETED_SID_SUCCESS';
export const FETCH_COMPLETED_SID_ERROR = 'FETCH_COMPLETED_SID_ERROR';
export const FETCH_SELECTED_SID_DATA_START = 'FETCH_SELECTED_SID_DATA_START';
export const FETCH_SELECTED_SID_DATA_SUCCESS =
  'FETCH_SELECTED_SID_DATA_SUCCESS';
export const FETCH_SELECTED_SID_DATA_ERROR = 'FETCH_SELECTED_SID_DATA_ERROR';

export function fetchCompletedSidStart() {
  return { type: FETCH_COMPLETED_SID_START };
}
export function fetchCompletedSidSuccess(data) {
  return { type: FETCH_COMPLETED_SID_SUCCESS, data };
}
export function fetchCompletedSidError(error) {
  return { type: FETCH_COMPLETED_SID_ERROR, error };
}

export function fetchSelectedSidDataStart(sid) {
  return { type: FETCH_SELECTED_SID_DATA_START, sid };
}
export function fetchSelectedSidDataSuccess(data) {
  return { type: FETCH_SELECTED_SID_DATA_SUCCESS, data };
}
export function fetchSelectedSidDataError(error) {
  return { type: FETCH_SELECTED_SID_DATA_ERROR, error };
}
