import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BaseTableV2 from '../UI/BaseTableV2';
import CompletedStudyIdSummary from './CompletedStudyIdSummary';
import DataAvailBar from '../UI/DataAvailBar';
import GroupLabel from '../UI/GroupLabel';
import { DropDownGropSelect } from '../UI/TableParts';

function CompletedStudyIdTable() {
  const data = useSelector((state) => state.completedStudyId.completedSidData);
  const columns = useMemo(
    () => [
      { Header: 'Study ID', accessor: 'sid', disableSortBy: true },
      {
        Header: 'Group',
        accessor: 'group',
        disableSortBy: true,
        Cell: ({ row: { original } }) => <GroupLabel groupValue={original.group} />,
        Filter: DropDownGropSelect,
        filter: 'equals',
      },
      { Header: 'duration (days)', accessor: 'bb_study_duration_days', disableSortBy: true },
      {
        Header: 'Enrollment',
        accessor: (d) => (d?.activation_date ? moment(d.activation_date.toDate()).format('ll') : ''),
      },
      {
        Header: 'Watch Avail.',
        accessor: 'bb_window_covered_per',
        Cell: ({ row: { original } }) => <DataAvailBar percent={original.bb_window_covered_per} />,
      },
      {
        Header: 'Temp Avail.',
        accessor: 'mtemp_covered_per',
        Cell: ({ row: { original } }) => <DataAvailBar percent={original.mtemp_covered_per} />,
      },
      {
        Header: 'Drop out',
        accessor: 'early_completion',
        Cell: ({ row: { original } }) => (original.early_completion ? 'YES' : 'NO'),
      },
      {
        Header: '',
        id: 'moreInfo',
        accessor: null,
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Link to={`/completedstudyid/${original.sid}`} className="flex-grow">
            <button className="w-full btn sm:btn-ghost btn-outline btn-sm sm:w-min" type="button">
              <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </Link>
        ),
      },
    ],
    []
  );
  return (
    <div className="w-full">
      <CompletedStudyIdSummary />
      <BaseTableV2 tableData={data} columns={columns} />
    </div>
  );
}

export default CompletedStudyIdTable;
