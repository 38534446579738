import React from 'react';
import PropTypes from 'prop-types';

const dotColors = {
  ready: { out: 'border-green-200', in: 'bg-green-400' },
  inactive: { out: 'border-gray-200', in: 'bg-gray-300' },
  broken: { out: 'border-red-200', in: 'bg-red-400' },
  unknown: { out: 'border-white', in: 'bg-white' },
};

function StatusTag({ status }) {
  let statusVal;

  if (status !== 'ready' && status !== 'inactive' && status !== 'broken') {
    statusVal = 'unknown';
  } else {
    statusVal = status;
  }

  const outColor = dotColors[statusVal]?.out ?? 'bg-white';
  const inColor = dotColors[statusVal]?.in ?? 'bg-white';

  return (
    <div className="flex items-center justify-center gap-1">
      <div className={`w-2 h-2 rounded-full ${inColor} ${outColor} border-[1px]`} />
      <p className="text-sm">{statusVal.toUpperCase()}</p>
    </div>
  );
}

StatusTag.propTypes = {
  status: PropTypes.string,
};

StatusTag.defaultProps = {
  status: 'unknown',
};

export default StatusTag;
