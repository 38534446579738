import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import tableData from './TableDataReducer';
import setup from './SetupReducer';
import auth from './AuthReducer';
import sidwatchid from './SidWatchidReducer';
import personData from './PersonDataReducer';
import groupInfo from './GroupInfoReducer';
import accountSettings from './AccountSettingsReducer';
import completedStudyId from './CompletedStudyIdReducer';
import aggregateDataAvail from './AggregateDataAvailReducer';
import tableFilterSortConfig from './TableFilterSortConfigReducer';

export const reducers = combineReducers({
  tableData,
  setup,
  auth,
  sidwatchid,
  personData,
  groupInfo,
  accountSettings,
  completedStudyId,
  aggregateDataAvail,
  tableFilterSortConfig,
});

export default () => {
  const finalReducers = reducers;
  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('.').reducers;
      store.replaceReducer(nextRootReducer);
      const newYieldedSagas = require('../Sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }

  return { store };
};
