import React from 'react';
import PropTypes from 'prop-types';

function DataAvailBar({ percent }) {
  const BARCOLORPAIR = {
    LOW: ['#ff5252', '#ffe2e2'],
    MEDIUM: ['#ffb142', '#fff1da'],
    HIGH: ['#78e08f', '#ddffe5'],
    DEFAULT: ['#dfe6e9', '#dfe6e9'],
  };

  let barColor = BARCOLORPAIR.DEFAULT;

  if (percent >= 75) {
    barColor = BARCOLORPAIR.HIGH;
  } else if (percent >= 25 && percent < 75) {
    barColor = BARCOLORPAIR.MEDIUM;
  } else if (percent >= 0 && percent < 25) {
    barColor = BARCOLORPAIR.LOW;
  } else {
    barColor = BARCOLORPAIR.DEFAULT;
  }

  return (
    <div className="relative flex justify-start w-32 h-7">
      <div
        className="relative items-center w-full h-full overflow-hidden rounded-full"
        style={{ background: barColor[1] }}
      >
        <div className="absolute left-0 h-9 display-block" style={{ width: `${percent}%`, background: barColor[0] }} />
        <span className="absolute font-semibold transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-[#333333]">
          {String(percent)}%
        </span>
      </div>
    </div>
  );
}

DataAvailBar.propTypes = {
  percent: PropTypes.number,
};

DataAvailBar.defaultProps = {
  percent: '--',
};

export default DataAvailBar;
