import { functions } from '../../firebase';

async function updateRewardProjectCodeByGroup(group, setDefault) {
  try {
    const result = await functions.httpsCallable('updateRewardProjectCodeByGroup')({ group, setDefault });
    return [result.data.updated, null];
  } catch (e) {
    return [null, e.message];
  }
}

export default updateRewardProjectCodeByGroup;
