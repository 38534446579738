import { all, put, takeLatest, race, delay } from 'redux-saga/effects';
import {
  REQUEST_TOKEN,
  REQUEST_TOKEN_SUCCESS,
  REQUEST_TOKEN_ERROR,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGIN_WITH_TOKEN_ERROR,
} from '../Actions';
import { auth, functions } from '../firebase';

export function* _requestToken(action) {
  try {
    const { id, code } = action;

    const { token_request, timeout } = yield race({
      token_request: yield functions.httpsCallable('requestDbToken')({
        id,
        code,
      }),
      timeout: delay(12000),
    });

    if (token_request) {
      if (token_request.data.error) {
        throw token_request.data.error;
      } else {
        yield put({
          type: REQUEST_TOKEN_SUCCESS,
          id,
          token: token_request.data.token,
          roles: token_request.data.roles,
          projectAccess: token_request.data?.projectAccess ?? [],
          studyGroupAccess: token_request.data?.studyGroupAccess ?? [],
        });
      }
    } else if (timeout) {
      throw new Error({ code: 'TIMEOUT', msg: 'Request timed out.' });
    }
  } catch (e) {
    yield put({
      type: REQUEST_TOKEN_ERROR,
      error: e,
    });
  }
}

export function* requestToken() {
  yield takeLatest(REQUEST_TOKEN, _requestToken);
}

export function* _tokenLogin(action) {
  const { id, token, roles, projectAccess, studyGroupAccess } = action;
  try {
    const { res, timeout } = yield race({
      res: auth.signInWithCustomToken(token),
      timeout: delay(7000),
    });
    if (res) {
      yield put({
        type: LOGIN_WITH_TOKEN_SUCCESS,
        id,
        roles,
        projectAccess,
        studyGroupAccess,
      });
    } else if (timeout) {
      throw new Error({ code: 'TIMEOUT', message: 'Login request timed out' });
    }
  } catch (e) {
    yield put({ type: LOGIN_WITH_TOKEN_ERROR, error: e });
  }
}

export function* tokenLogin() {
  yield takeLatest(REQUEST_TOKEN_SUCCESS, _tokenLogin);
}

export function* authSaga() {
  yield all([requestToken(), tokenLogin()]);
}
