import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

const initChartData = [
  { name: 'Sun', data: new Array(1).fill(-1) },
  { name: 'Sat', data: new Array(1).fill(-1) },
  { name: 'Fri', data: new Array(1).fill(-1) },
  { name: 'Thu', data: new Array(1).fill(-1) },
  { name: 'Wed', data: new Array(1).fill(-1) },
  { name: 'Tue', data: new Array(1).fill(-1) },
  { name: 'Mon', data: new Array(1).fill(-1) },
];

const initXLabel = new Array(1).fill(new Date());

function EachCompletedSidHeatMap() {
  const fetching = useSelector(
    (state) => state.completedStudyId.selectedFetching
  );
  const data = useSelector(
    (state) => state.completedStudyId.selectedSidDetailData
  );
  const [chartData, setChartData] = useImmer(initChartData);
  const [chartXLabel, setChartXLabel] = useImmer(initXLabel);
  const chartOpt = {
    tooltip: {
      enabled: true,
      y: {
        formatter(val) {
          return `${val} hours`;
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        distributed: true,
        colorScale: {
          ranges: [
            {
              from: -1,
              to: -1,
              name: '-',
              color: '#fff',
            },
            {
              from: 0,
              to: 0,
              name: '0',
              color: '#dfe6e9',
            },
            { from: 1, to: 5, name: '1-5', color: '#ff5252' },
            {
              from: 6,
              to: 15,
              name: '6-15',
              color: '#ffb142',
            },
            {
              from: 16,
              to: 24,
              name: '>16',
              color: '#78e08f',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartXLabel,
      floating: false,
      labels: {
        formatter(value) {
          let dStr = '-';
          dStr = value?.toISOString().split('T')[0].slice(5, 10) ?? '-';
          return dStr;
        },
      },
      tooltip: {
        formatter(val, opts) {
          const { seriesIndex } = opts;
          const dateOfSelected = new Date(val - seriesIndex * 8.64e7)
            .toISOString()
            .split('T')[0];
          return dateOfSelected;
        },
      },
    },
  };

  useEffect(() => {
    if (fetching) {
      setChartData(initChartData);
      setChartXLabel(initXLabel);
    }
  }, [fetching, setChartData, setChartXLabel]);

  const makeXLables = (d) => {
    const sundaysArr = d
      .filter((each) => each.dayOfWeek === 'Sun')
      .sort((a, b) => new Date(a.ts) - new Date(b.ts))
      .map((each) => new Date(each.ts));
    return sundaysArr;
  };

  useEffect(() => {
    if (data.length >= 7) {
      const series = [
        {
          name: 'Sun',
          data: data
            .filter((each) => each.dayOfWeek === 'Sun')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Sat',
          data: data
            .filter((each) => each.dayOfWeek === 'Sat')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Fri',
          data: data
            .filter((each) => each.dayOfWeek === 'Fri')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Thu',
          data: data
            .filter((each) => each.dayOfWeek === 'Thu')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Wed',
          data: data
            .filter((each) => each.dayOfWeek === 'Wed')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Tue',
          data: data
            .filter((each) => each.dayOfWeek === 'Tue')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
        {
          name: 'Mon',
          data: data
            .filter((each) => each.dayOfWeek === 'Mon')
            .sort((a, b) => new Date(a.ts) - new Date(b.ts))
            .map((each) => each.count),
        },
      ];
      setChartData(series);
      setChartXLabel(makeXLables(data));
    }
  }, [data, setChartData, setChartXLabel]);

  return (
    <Chart type="heatmap" options={chartOpt} series={chartData} height={300} />
  );
}

export default EachCompletedSidHeatMap;
