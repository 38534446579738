import produce from 'immer';
import { FilterMatchMode } from 'primereact/api';
import { SET_TABLE_FILTER, SET_TABLE_SORT, SET_TABLE_GLOBAL_FILTER } from '../Actions';

export const initialState = {
  filters: {
    tableHome: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sid: { value: null, matchMode: FilterMatchMode.CONTAINS },
      group: { value: null, matchMode: FilterMatchMode.CONTAINS },
      watch_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      bb_72h_size_avail: { value: null, matchMode: FilterMatchMode.BETWEEN },
    },
    tableWatch: {
      watch_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      owner_sid: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    tableCompletedSid: {
      sid: { value: null, matchMode: FilterMatchMode.CONTAINS },
      group: { value: null, matchMode: FilterMatchMode.CONTAINS },
      early_completion: { value: null, matchMode: FilterMatchMode.EQUALS },
    },
  },
  sorts: {
    tableWatch: {
      sortField: null,
      sortOrder: null,
    },
  },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_TABLE_FILTER:
        draft.filters[action.tableName] = action.filterObjects;
        break;
      case SET_TABLE_SORT:
        draft.sorts[action.tableName] = action.sortObject;
        break;
      case SET_TABLE_GLOBAL_FILTER:
        draft.filters[action.tableName].global.value = action.value;
        break;
      default:
        break;
    }
  });
