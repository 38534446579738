const processSection = (sliceStart, sliceEnd, data, nowIndex) => {
  const section = data.slice(sliceStart, sliceEnd);
  const hour = new Date(section[5]?.ts).getHours();
  const AMPM = hour >= 12 ? ' PM' : ' AM';
  const name = section[5].ts.slice(0, 3) + AMPM;

  const arr = section.map((e) => e.val);
  if (nowIndex) {
    arr[nowIndex] = 3;
  }

  return { name, data: arr };
};

function deriveForMobile(boxHourlyData) {
  const nowInHour = new Date().getHours();
  const nowIndex = nowInHour >= 12 ? nowInHour - 12 : nowInHour;
  const newChartData = [
    processSection(60, 72, boxHourlyData, nowInHour >= 12 ? nowIndex : false),
    processSection(48, 60, boxHourlyData, nowInHour < 12 ? nowIndex : false),
    processSection(36, 48, boxHourlyData),
    processSection(24, 36, boxHourlyData),
    processSection(12, 24, boxHourlyData),
    processSection(0, 12, boxHourlyData),
  ];

  return newChartData;
}

const processSectionD = (sliceStart, sliceEnd, data, nowIndex) => {
  const section = data.slice(sliceStart, sliceEnd);
  const name = section[12].ts.slice(0, 3);
  const arr = section.map((e) => e.val);
  if (nowIndex) {
    arr[nowIndex] = 3;
  }

  return { name, data: arr };
};

function deriveForDesktop(boxHourlyData) {
  const nowIndex = new Date().getHours();
  const newChartData = [
    processSectionD(48, 72, boxHourlyData, nowIndex),
    processSectionD(24, 48, boxHourlyData),
    processSectionD(0, 24, boxHourlyData),
  ];
  return newChartData;
}

function deriveData(isMobile, boxHourlyData) {
  if (isMobile) {
    return deriveForMobile(boxHourlyData);
  }
  return deriveForDesktop(boxHourlyData);
}

function getxAxis(mobile) {
  if (mobile) {
    return [
      '0:00',
      '1:00',
      '2:00',
      '3:00',
      '4:00',
      '5:00',
      '6:00',
      '7:00',
      '8:00',
      '9:00',
      '10:00',
      '11:00',
    ];
  }
  return [
    '0:00',
    '1:00',
    '2:00',
    '3:00',
    '4:00',
    '5:00',
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];
}

export { getxAxis, deriveData };
