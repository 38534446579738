import moment from 'moment';
import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';

function useEachBasestation(basestation_imei) {
  const [basestationDoc, setBasestationDoc] = useState({
    basestation_id: '',
    imei: '',
    owner_sid: '',
    esper_id: '',
    data_plan: '',
    last_connect_time: '',
    projectCode: '',
    basestation_status: 'unkown',
  });
  const [basestationLogs, setBasestationLogs] = useState([]);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    const ref = firestore.collection('basestation').doc(basestation_imei);
    setRequesting(true);
    const sub = ref.onSnapshot(
      (doc) => {
        if (doc.exists) {
          const d = doc.data();
          const obj = {
            basestation_id: d.basestation_id,
            imei: d.imei,
            owner_sid: d.owner_sid ?? '',
            esper_id: d.esper_id ?? '',
            projectCode: d.projectCode ?? '',
            basestation_status: d.basestation_status ?? 'unkown',
            serial_number: d.serial_no ?? '',
            hologram: d.hologram ?? {},
          };
          setBasestationLogs(d.basestation_log ?? []);
          setBasestationDoc(obj);
          setRequesting(false);
        }
      },
      (error) => {
        console.log(error);
        setRequesting(false);
      }
    );
    return sub;
  }, [basestation_imei]);

  return { basestationDoc, basestationLogs, requesting };
}

export default useEachBasestation;
