import React from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortColumnIndicator, LoadingRows, NoRows } from './TableParts';

function BaseTable({ data, columns, loading, title, responsive, className }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className={`table w-full ${className}`}>
        {title ? (
          <thead>
            <tr>
              <th colSpan={columns.length} className="text-base font-extrabold text-gray-500">
                {title}
              </th>
            </tr>
          </thead>
        ) : null}
        <thead className={classNames({ 'hidden sm:table-header-group': responsive })}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classNames({ 'sm:table-cell hidden': column?.smHidden })}
                >
                  <div className="flex">
                    {column.render('Header')}
                    <SortColumnIndicator column={column} />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <LoadingRows numRows={4} numCells={columns.length} />
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={classNames({
                    'not-last:border-b-[5px] sm:not-last:border-b-[1px] border-gray-100 bg-white':
                      responsive && !row.column?.smHidden,
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={classNames({
                        'sm:table-cell flex space-between border-b-0': responsive && !cell.column?.smHidden,
                        'sm:table-cell hidden': cell.column?.smHidden,
                      })}
                      {...cell.getCellProps()}
                    >
                      <p
                        className={classNames('font-medium text-gray-400', {
                          'flex sm:hidden': responsive,
                          hidden: !responsive,
                        })}
                      >
                        {cell.column.Header}
                      </p>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
          {rows.length === 0 && !loading ? <NoRows numCells={columns.length} /> : null}
        </tbody>
      </table>
    </div>
  );
}

BaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  responsive: PropTypes.bool,
};
BaseTable.defaultProps = {
  data: [],
  columns: [],
  loading: false,
  title: null,
  responsive: false,
};

export default BaseTable;
