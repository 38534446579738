import produce from 'immer';
import {
  START_CREATE_IDS,
  CREATE_IDS_FAIL,
  CREATE_IDS_SUCCESS,
} from '../Actions';

const STATUS_NONE = 'STATUS_NONE';
const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS';
const STATUS_ERROR = 'STATUS_ERROR';
const STATUS_SUCCESS = 'STATUS_SUCCESS';

export const initialState = {
  status: STATUS_NONE,
  error: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case START_CREATE_IDS:
        draft.status = STATUS_IN_PROGRESS;
        break;
      case CREATE_IDS_SUCCESS:
        draft.status = STATUS_SUCCESS;
        break;
      case CREATE_IDS_FAIL:
        draft.status = STATUS_ERROR;
        draft.error = action.error;
        break;
      default:
        break;
    }
  });
