import React, { useState, useImperativeHandle, forwardRef, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { addNoteToStudyId, batchAddNotes } from '../../Api/Set/StudyIdNotesSets';
import BaseTable from '../UI/BaseTable';

const AddNoteModal = forwardRef((props, ref) => {
  const [toSid, setToSid] = useState('');
  const [text, setText] = useState('');

  useImperativeHandle(ref, () => ({
    updateSid: (sid) => {
      setToSid(sid);
    },
  }));

  const onDismiss = () => {
    setText('');
  };

  const onSumbit = async () => {
    const res = await addNoteToStudyId(toSid, text);
    if (res[0]) {
      toast.success(`Note added to ${toSid}`);
      setText('');
    } else {
      toast.error('Error adding note');
    }
  };

  return (
    <>
      <input type="checkbox" id="add-note-modal" className="modal-toggle" />
      <div className="modal">
        <div className="bg-gray-50 modal-box">
          <h4 className="text-lg font-bold">Note to {toSid}</h4>
          <textarea
            className="w-full h-40 my-4 textarea textarea-bordered"
            placeholder=""
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          <div className="modal-action">
            <label htmlFor="add-note-modal" className="btn" onClick={onDismiss}>
              Dismiss
            </label>
            <label htmlFor="add-note-modal" className="btn btn-primary" disabled={text === ''} onClick={onSumbit}>
              Submit
            </label>
          </div>
        </div>
      </div>
    </>
  );
});

function AddBatchNotesModal() {
  const [plainText, setPlainText] = useState('');
  const [notesObj, setNotesObj] = useState([]);

  useEffect(() => {
    const regex = /[A-Z0-9]{6}/g;
    const matches = plainText.match(regex);
    const splits = plainText.split(regex);

    if (matches !== null && splits.length > 0) {
      const processedNote = splits?.map((item) => item.trim()).filter((item) => item !== '') ?? [];
      const objects = matches.map((item, index) => ({ sid: item, note: processedNote[index] ?? '' }));
      setNotesObj(objects);
    } else {
      setNotesObj([]);
    }
  }, [plainText]);

  const columns = useMemo(
    () => [
      {
        Header: 'Study ID',
        id: 'sid',
        accessor: 'sid',
      },
      {
        Header: 'Note',
        id: 'note',
        accessor: 'note',
      },
    ],
    []
  );

  const onDismiss = () => {};

  const onSumbit = async () => {
    const res = await batchAddNotes(notesObj);

    if (res[0]) {
      toast.success(`Notes added to ${notesObj.length} study IDs`);
      setPlainText('');
    } else {
      toast.error('Error adding notes');
    }
  };

  return (
    <>
      <input type="checkbox" id="add-batch-notes-modal" className="modal-toggle" />
      <div className="modal">
        <div className="bg-gray-50 modal-box">
          <h4 className="text-lg font-bold">Create notes by batch</h4>
          <textarea
            className="w-full h-32 my-4 textarea textarea-bordered"
            placeholder=""
            value={plainText}
            onChange={(e) => {
              setPlainText(e.target.value);
            }}
          />
          <BaseTable data={notesObj} columns={columns} className="table-compact" />
          <div className="modal-action">
            <label htmlFor="add-batch-notes-modal" className="btn" onClick={onDismiss}>
              Dismiss
            </label>
            <label
              htmlFor="add-batch-notes-modal"
              className="btn btn-primary"
              disabled={notesObj.length === 0}
              onClick={onSumbit}
            >
              Submit
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export { AddNoteModal, AddBatchNotesModal };
