import React from 'react';
import LoadingSpiner from '../../Asset/svg/LoadingSpiner';

function LoadingPlaceHolder() {
  return (
    <div className="flex items-center w-full">
      <div className="flex items-center h-20 max-w-sm p-4 mx-auto rounded-md">
        <LoadingSpiner className="mr-2" />
        <p className="pl-2 font-bold">loading ...</p>
      </div>
    </div>
  );
}

export default LoadingPlaceHolder;
