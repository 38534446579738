import produce from 'immer';
import { UPDATE_AGGREGATE_DATA_AVAIL } from '../Actions';

export const INITIAL_STATE = {
  numberOfGroups: '-',
  numberTotalSubjects: '-',
  aggregateWatchDataAvail: '-',
  aggregateMTempAvail: '-',
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_AGGREGATE_DATA_AVAIL:
        draft.numberOfGroups = action.obj.numberOfGroups;
        draft.numberTotalSubjects = action.obj.numberSubjects;
        draft.aggregateWatchDataAvail = action.obj.aggWatchAvail;
        draft.aggregateMTempAvail = action.obj.aggTempAvail;
        break;
      default:
        break;
    }
  });
