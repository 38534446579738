import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { firestore } from '../../firebase';
import { updateSidDocData, fetchPersonDataStart } from '../../Actions';

function SingleSidDocSubscription({ sid }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const sub = firestore
      .collection('study_id')
      .doc(sid)
      .onSnapshot((doc) => {
        const res = {
          usrPlatform: doc.data()?.usr_platform ?? '-',
          usrTimezone: doc.data()?.usr_timezone_str ?? '-',
          usrAppVersion: doc.data()?.usr_app_version ?? '-',
          usrLastActive: doc.data()?.usr_last_active_ts?.toDate().toString().slice(0, 21) ?? '-',
          extended_study_days: doc.data()?.extended_study_days ?? null,
          basestation_id: doc.data()?.basestation_id ?? '-',
        };
        dispatch(updateSidDocData(res));
      });

    return sub;
  }, [dispatch, sid]);

  useEffect(() => {
    if (sid) {
      dispatch(fetchPersonDataStart(sid));
    }
  }, [dispatch, sid]);

  return null;
}

export default SingleSidDocSubscription;
