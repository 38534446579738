import { functions } from '../../firebase';

export async function completeStudyId(sid) {
  try {
    const { data } = await functions.httpsCallable('completeStudyId')({ sid, early_completion: false });

    if (data.success) return { success: true, error: null };

    return { success: false, error: data.error?.msg ?? 'unkonw error' };
  } catch (e) {
    return { success: false, error: e };
  }
}

export async function earlyCompleteStudyId(sid) {
  try {
    const { data } = await functions.httpsCallable('completeStudyId')({ sid, early_completion: true });

    if (data.success) return { success: true, error: null };

    return { success: false, error: data.error?.msg ?? 'unkonw error' };
  } catch (e) {
    return { success: false, error: e.message };
  }
}
