import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { TextColumnFilter, SelectColumnFilter } from '../UI/TableParts';
import StatusTag from '../UI/StatusTag';
import TableCellDropdown from '../UI/TableCellDropdown';
import useBasestation from '../../Api/Hooks/useBasestation';
import { setBasestationProjectCode, setBasestationStatus } from '../../Api/Set/BasestationSets';
import { syncBasestationInfoHologram } from '../../Api/HttpCalls/BasestationInfoHologram';
import BaseTableV2 from '../UI/BaseTableV2';

function BasestationTable() {
  const { basestations, loading, isAdmin, idProjectAccess } = useBasestation();
  const [isSyncing, setIsSyncing] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'count',
        disableSortBy: true,
        accessor: null,
        ccn: 'w-6 border-b-0',
        Cell: ({ row }) => <span className="pl-2 text-xs text-center text-gray-400">{row.index + 1}</span>,
        smHidden: true,
      },
      {
        Header: 'basestation ID',
        id: 'basestation_id',
        disableSortBy: true,
        accessor: 'basestation_id',
        filter: 'fuzzyText',
        Filter: TextColumnFilter,
      },
      {
        Header: 'IMEI',
        id: 'imei',
        disableSortBy: true,
        accessor: 'imei',
        filter: 'fuzzyText',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Owner',
        id: 'owner_sid',
        accessor: 'owner_sid',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Data Plan',
        id: 'data_plan',
        accessor: 'data_plan',
      },
      {
        Header: 'Project',
        id: 'project_code',
        accessor: 'projectCode',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ row: { original } }) => (
          <TableCellDropdown
            value={original.projectCode}
            options={idProjectAccess}
            onChange={(e) => {
              setBasestationProjectCode(original.imei, e, original.projectCode);
            }}
            editable={isAdmin}
          />
        ),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'basestation_status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({ row: { original } }) => (
          <TableCellDropdown
            value={original.basestation_status}
            options={['ready', 'inactive', 'broken']}
            onChange={(e) => {
              setBasestationStatus(original.imei, e, original.basestation_status);
            }}
            customItem={StatusTag}
            editable={isAdmin}
          />
        ),
      },
      {
        Header: '',
        accessor: null,
        id: 'to_nested',
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Link to={`/basestation/${original.imei}`} className="flex-grow">
            <button className="w-full btn sm:btn-ghost btn-outline btn-sm sm:w-min" type="button">
              <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </Link>
        ),
      },
    ],
    [idProjectAccess, isAdmin]
  );

  const onSyncPress = async () => {
    setIsSyncing(true);
    const res = await syncBasestationInfoHologram();
    if (res[0]) {
      toast.success('Basestation info synced with Hologram.');
    } else {
      toast.error('Unable to sync with Hologram.');
    }

    setIsSyncing(false);
  };

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <BaseTableV2
          loading={loading}
          tableData={basestations}
          columns={columns}
          headerElement={
            isAdmin ? (
              <div className="flex gap-2">
                <button type="button" className="flex gap-1 btn btn-sm" onClick={onSyncPress}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={classNames('w-5 h-5', { 'animate-spin': isSyncing })}
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                      clipRule="evenodd"
                    />
                  </svg>
                  sync hologram
                </button>
              </div>
            ) : null
          }
        />
      </div>
    </div>
  );
}

export default BasestationTable;
