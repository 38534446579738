import { functions } from '../../firebase';

export async function createDashboardAccountByEmail(email, displayName, desc, requireMFA) {
  try {
    const res = await functions.httpsCallable('createDashboardAccountByEmail')({
      email,
      displayName,
      desc,
      requireMFA,
    });
    return [res.data, null];
  } catch (e) {
    return [null, e.message ?? 'unknown error'];
  }
}

export async function deleteDashboardAccountByEmail(email) {
  try {
    const res = await functions.httpsCallable('deleteDashboardAccountByEmail')({ email });
    return [res.data, null];
  } catch (e) {
    return [null, e.message ?? 'unknown error'];
  }
}

export async function sendPasswordResetEmail(email) {
  try {
    const res = await functions.httpsCallable('sendPasswordResetEmail')({ email });
    return [res.data, null];
  } catch (e) {
    return [null, e.message ?? 'unknown error'];
  }
}
