import React from 'react';
import AppSubmenu from './AppSubmenu';

class Menu extends React.PureComponent {
  render() {
    const { model, onMenuItemClick } = this.props;
    return (
      <div className="layout-menu-container">
        <AppSubmenu
          items={model}
          className="layout-menu"
          onMenuItemClick={onMenuItemClick}
          root
        />
      </div>
    );
  }
}

export default Menu;
