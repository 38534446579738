import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

async function fetchPersonChartsData(sid) {
  try {
    const { data } = await firebase.functions().httpsCallable('fetchSidDataDashboard')({ sid });
    if (!data.success) {
      throw data.error;
    } else {
      return {
        success: true,
        box: data.box,
        boxHourly: data.box_hourly,
        bar: data.bar,
      };
    }
  } catch (e) {
    return { success: false, error: e };
  }
}

async function fetchPersonChartsDataV2(sid) {
  const { data } = await firebase.functions().httpsCallable('fetchSidDataDashboardV2')({ sid });
  return data;
}

export { fetchPersonChartsData, fetchPersonChartsDataV2 };
