import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { UserAddIcon, UserRemoveIcon } from '@heroicons/react/solid';
import DButton from '../UI/DButton';
import { updateProjectCode, createProjectCode } from '../../Api/Set/ProjectCodesSets';

function ProjectCodeCard({
  projectCode,
  dashboardIdAccess,
  desc,
  allDashboardId,
  allDashboardIdObjects,
  onSaved,
  createMode,
  onCloseCreateMode,
}) {
  const [editMode, setEditMode] = useState(false);

  const [projectCodeValue, setProjectCodeValue] = useState(projectCode);
  const [dashboardIdAccessValue, setDashboardIdAccessValue] = useState(dashboardIdAccess);
  const [descValue, setDescValue] = useState(desc);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (createMode) {
      setEditMode(true);
    }
  }, [createMode]);

  const onStartEdit = () => {
    setEditMode(true);
  };

  const onCancelEdit = () => {
    setEditMode(false);
    setDashboardIdAccessValue(dashboardIdAccess);
    setDescValue(desc);

    if (createMode) {
      onCloseCreateMode();
    }
  };

  const onSaveEdit = async () => {
    const obj = {
      projectCode: projectCodeValue,
      dashboardIdAccess: dashboardIdAccessValue,
      desc: descValue,
    };

    setSaving(true);

    if (createMode) {
      if (projectCodeValue === '') {
        onCloseCreateMode();
      } else {
        await createProjectCode(obj);
        setSaving(false);
        onSaved();
        onCloseCreateMode();
      }
    } else {
      await updateProjectCode(obj);
      setEditMode(false);
      setSaving(false);
      onSaved();
    }
  };

  const addDashboardIdToAccess = (id) => {
    const newDashboardIdAccess = [...dashboardIdAccessValue, id];
    setDashboardIdAccessValue(newDashboardIdAccess);
  };

  const removeDashboardIdFromAccess = (id) => {
    const newDashboardIdAccess = dashboardIdAccessValue.filter((e) => e !== id);
    setDashboardIdAccessValue(newDashboardIdAccess);
  };

  const dashboardAcountDisplay = (uid) => {
    const object = allDashboardIdObjects.find((e) => e.uid === uid);
    return object?.displayName ?? object?.email ?? object?.uid ?? 'Unknown';
  };

  const renderDashboardIdAccessItems = () =>
    dashboardIdAccessValue.map((e) => (
      <div key={e} className="flex flex-row px-2 py-0.5 mb-1 bg-indigo-50 rounded-md">
        <p className="w-4/5">{dashboardAcountDisplay(e)}</p>
        <button
          disabled={!editMode}
          type="button"
          className="flex items-center justify-center w-1/5 rounded-md hover:bg-gray-100 disabled:text-indigo-50 text-stone-600 "
          onClick={() => {
            removeDashboardIdFromAccess(e);
          }}
        >
          <UserRemoveIcon className="w-4 h-4" />
        </button>
      </div>
    ));

  const renderDashboardIdItems = () => {
    const excludeSelected = allDashboardId.filter((e) => !dashboardIdAccessValue.includes(e));
    return excludeSelected.map((e) => (
      <Menu.Item key={e}>
        <button
          type="button"
          className="flex items-center w-full px-2 py-2 text-sm text-gray-600 rounded-md hover:bg-slate-200"
          onClick={() => {
            addDashboardIdToAccess(e);
          }}
        >
          {dashboardAcountDisplay(e)}
        </button>
      </Menu.Item>
    ));
  };

  return (
    <div className="grid w-full col-span-1 gap-3 px-2 py-2 rounded-md bg-gray-50">
      <div className="col-1">
        <p>code</p>
        <input
          className="w-full px-2 py-1 rounded-md"
          disabled={!createMode}
          value={projectCodeValue}
          onChange={(e) => {
            setProjectCodeValue(e.target.value);
          }}
        />
      </div>
      <div className="col-1" style={{ zIndex: 1 }}>
        <p>access</p>
        {renderDashboardIdAccessItems()}
        {editMode ? (
          <Menu as="div" className="relative inline-block w-full">
            <Menu.Button className="w-full px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white">
              <UserAddIcon className="w-5 h-5 text-white" aria-hidden="true" />
            </Menu.Button>
            <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">{renderDashboardIdItems()}</div>
            </Menu.Items>
          </Menu>
        ) : null}
      </div>
      <div className="col-1">
        <p>Description</p>
        <textarea
          className="w-full px-2 py-1 rounded-md"
          disabled={!editMode}
          value={descValue}
          onChange={(e) => {
            setDescValue(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-row gap-2">
        <DButton
          loading={saving}
          className="w-1/2 btn-primary"
          titile={createMode ? 'Create' : 'Update'}
          disabled={!editMode}
          onClick={() => {
            onSaveEdit();
          }}
        />
        {editMode ? (
          <DButton className="w-1/2" titile="Cancel" onClick={onCancelEdit} />
        ) : (
          <DButton className="flex-grow btn-primary" titile="Edit" onClick={onStartEdit} />
        )}
      </div>
    </div>
  );
}

ProjectCodeCard.propTypes = {
  projectCode: PropTypes.string,
  dashboardIdAccess: PropTypes.arrayOf(PropTypes.string),
  desc: PropTypes.string,
  allDashboardId: PropTypes.arrayOf(PropTypes.string),
  allDashboardIdObjects: PropTypes.arrayOf(PropTypes.object),
  onSaved: PropTypes.func,
  createMode: PropTypes.bool,
  onCloseCreateMode: PropTypes.func,
};

ProjectCodeCard.defaultProps = {
  projectCode: '',
  dashboardIdAccess: [],
  desc: '',
  allDashboardId: [],
  allDashboardIdObjects: [],
  onSaved: () => {
    console.log('please provide a onSaved function');
  },
  createMode: false,
  onCloseCreateMode: () => {
    console.log('please provide a onCreateModeCancel function');
  },
};

export default ProjectCodeCard;
