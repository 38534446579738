import moment from 'moment';
import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';

function useEachWatchId(watch_id) {
  const [watchIdDoc, setWatchIdDoc] = useState({
    watch_id: '',
    owner_sid: '',
    activation_date: '',
    projectCode: '',
    watch_status: 'unkown',
  });
  const [watchLogs, setWatchLogs] = useState([]);
  const [ownershipLogs, setOwnershipLogs] = useState([]);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    const ref = firestore.collection('watch_id').doc(watch_id);
    setRequesting(true);
    const sub = ref.onSnapshot(
      (doc) => {
        if (doc.exists) {
          const d = doc.data();
          const obj = {
            watch_id: d.watch_id,
            owner_sid: d.owner_sid,
            activation_date: d.activation_date?.toDate() ? moment(d.activation_date.toDate()).format('LLL') : '',
            projectCode: d.projectCode ?? '',
            watch_status: d.watch_status ?? 'unkown',
          };
          setWatchIdDoc(obj);
          setWatchLogs(d.watch_log ?? []);
          setOwnershipLogs(d.log ?? []);
          setRequesting(false);
        }
      },
      (error) => {
        console.log(error);
        setRequesting(false);
      }
    );
    return sub;
  }, [watch_id]);

  return { watchIdDoc, watchLogs, ownershipLogs, requesting };
}

export default useEachWatchId;
