import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './App';
import ScrollToTop from './ScrollToTop';
import createStore from './Reducers';
import json from '../package.json';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
// import 'react-app-polyfill/ie11';
// import { PersistGate } from "redux-persist/integration/react";

const { store } = createStore();

global.appVersion = json.version;

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <Router>
      <ScrollToTop>
        <ToastContainer />
        <App />
      </ScrollToTop>
    </Router>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);
