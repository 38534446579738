import { functions } from '../../firebase';

export async function syncBasestationInfoHologram() {
  try {
    const res = await functions.httpsCallable('syncBasestationInfoHologram')();
    return [res.data, null];
  } catch (e) {
    return [null, e.message ?? 'unknown error'];
  }
}

export async function updateDeviceDataPlanHologram() {
  console.log('updateDeviceDataPlanHologram');
}
