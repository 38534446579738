import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Route, useRouteMatch, Switch } from 'react-router-dom';
import CompletedStudyIdSubscription from '../../Api/Hooks/CompletedStudyIdSubscription';
import CompletedStudyIdTable from './CompletedStudyIdTable';
import TableLoadingIndicator from '../UI/TableLoadingIndicator';
import EachCompletedStudyId from './EachCompletedStudyId';
import './CompletedStudyId.scss';

function CompletedStudyId() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const fetching = useSelector((state) => state.completedStudyId.fetching);

  const handleReturnToDashboard = () => {
    history.goBack();
  };

  return (
    <div className="w-full">
      <CompletedStudyIdSubscription />
      {fetching ? (
        <TableLoadingIndicator />
      ) : (
        <Switch>
          <Route exact path={path}>
            <CompletedStudyIdTable />
          </Route>
          <Route path={`${path}/:id`}>
            <EachCompletedStudyId onBackButtonClicked={handleReturnToDashboard} />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default CompletedStudyId;
